<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <div class="navbars-body">
          <form @submit.prevent="submitData" class="section-edit-profile">
            <div class="header">
              <img @click="previousPage" src="/assets/images/icon_back.png" alt="Back Icon" />
              <p>Edit Profil</p>
              <p></p>
              <!--input type="hidden"  src="/assets/images/icon_save.png" alt="Save" /-->
            </div>

            <img class="profile-picture" :src="imageUrl" alt="Profile" />

            <!-- <div class="picture-selector">
              <label htmlFor='image'><u>Ganti Foto</u></label>
              <!- <input type="file" id="image" name="image" accept="image/*" @change="previewImage" /> ->
              <input type="file" id="image" name="image" accept="image/*" ref="fileInput" @click="handleFileChange" />
              <button @click="uploadFile">Unggah File</button>
              <div v-if="file">File yang Dipilih: {{ file.name }}</div>

            </div> -->

            <div class="picture-selector">
              <label for="image"><u>Ganti Foto</u></label>
              <input type="file" id="image" name="image" accept="image/*" ref="fileInput" @change="handleFileChange" />
              <!-- <button @click="uploadFile">Unggah File</button>
                <div v-if="file">File yang Dipilih: {{ file.name }}</div> -->
            </div>

            <div class="overlay-container">
              <label>Nama</label>
              <input type="text" name='name' v-model="forms.name" />
            </div>

            <div class="overlay-container">
              <label>No. Handphone</label>
              <input type="tel" name='phone' v-model="forms.phone" />
            </div>

            <div class="overlay-container">
              <label>Alamat Lengkap</label>
              <textarea type="text" name='address' v-model="forms.address" />
            </div>

            <div class="overlay-container">
              <label>Asal Group Whatsapp</label>
              <select name='wagroup' v-model="modelWagroup">
                <option value="">Select an option</option>
                <option v-for="item in wagroup" :value="item.name" :key="item">{{ item.name }}</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Email</label>
              <input type="text" name='name' v-model="forms.email" />
            </div>

            <div class="overlay-container">
              <label>Ubah Kata Sandi</label>
              <input type="password" name='name' v-model="modelPassword" />
            </div>

            <div class="overlay-container">
              <label>Community</label>
              <select disabled name='community' v-model="modelCommunity">
                <option value="">Select an option</option>
                <option v-for="item in community" :value="item.id" :key="item">{{ item.community }}</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Jenis Kelamin</label>
              <select name='jenis_kelamin' v-model="forms.jenis_kelamin">
                <option value="">Select an option</option>
                <option value="Laki-laki">Laki-laki</option>
                <option value="Wanita">Wanita</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Profile Sosial Media</label>
              <select name='profile' v-model="forms.hijab">
                <option value="">Select an option</option>
                <option value="Hijab">Hijab</option>
                <option value="Non Hijab">Non Hijab</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Skin Type</label>
              <select name='skintype' v-model="forms.skintype">
                <option value="">Select an option</option>
                <option value="Normal Skin">Normal Skin</option>
                <option value="Dry Skin">Dry Skin</option>
                <option value="Oily Skin">Oily Skin</option>
                <option value="Combination Skin">Combination Skin</option>
                <option value="Acne Prone Skin">Acne Prone Skin</option>
                <option value="Sensitive Skin">Sensitive Skin</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Tanggal Lahir</label>
              <input required type='date' name='tanggal_lahir' v-model="forms.tanggal_lahir" />
            </div>

            <div class="overlay-container">
              <label>Tanggal Lahir Anak Pertama</label>
              <input type='date' name='tanggal_lahir_anak_pertama' v-model="forms.tanggal_lahir_anak_pertama" />
            </div>

            <div class="overlay-container">
              <label>Tanggal Lahir Anak Kedua</label>
              <input type='date' name='tanggal_lahir_anak_kedua' v-model="forms.tanggal_lahir_anak_kedua" />
            </div>

            <div class="overlay-container">
              <label>Tanggal Lahir Anak Kedua</label>
              <input type='date' name='tanggal_lahir_anak_kedua' v-model="forms.tanggal_lahir_anak_kedua" />
            </div>

            <div class="overlay-container">
              <label>Tanggal Lahir Anak Ketiga</label>
              <input type='date' name='tanggal_lahir_anak_ketiga' v-model="forms.tanggal_lahir_anak_ketiga" />
            </div>

            <div class="overlay-container">
              <label>Tanggal Lahir Anak Keempat</label>
              <input type='date' name='tanggal_lahir_anak_keempat' v-model="forms.tanggal_lahir_anak_keempat" />
            </div>

            <div class="overlay-container">
              <label>Area Domisili</label>
              <select name='profile' v-model="forms.area_domisili">
                <option value="">Select an option</option>
                <option value="Jabodetabek">Jabodetabek</option>
                <option value="Luar Jabodetabek">Luar Jabodetabek</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Provinsi</label> 
              <!-- provinsi -->
             
              <select name='provinsi' v-model="modelProvinsi" @change="loadKota(modelProvinsi.kode)">
                <option value="">Select an option</option>
                <option v-for="item in provinsi" :value="item" :key="item">
                  {{ item.nama }}

                </option>
              </select>
            </div>

            <div class="overlay-container">
              
              <label>Kota</label> 
              <!-- kota -->
              <select name='kota' v-model="modelKota"  @change="loadKecamatan(modelKota.kode)">
                <option value="">Select an option</option>
                <option v-for="item in kota" :value="item" :key="item">{{ item.nama }}</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Kecamatan</label> 
              <!-- kota -->
              <select name='kecamatan' v-model="modelKecamatan">
                <option value="">Select an option</option>
                <option v-for="item in kecamatan" :value="item.nama" :key="item">{{ item.nama }}</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Kode POS</label>
              <input type='text' name='kode_pos' v-model="forms.kode_pos" />
            </div>
            
            <div class="overlay-container">
              <label>Personal KOL</label> 
              <!-- personal -->
              <select name='personal' v-model="modelPersonal" multiple @change="validateSelection" style="height: 200px;">
                <option value="">Select multiple option. Max 5</option>
                <option v-for="item in personal" :value="item.personal" :key="item">{{ item.personal }}</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Username Instagram</label>
              <input type="text" name='username_instagram' v-model="forms.username_instagram" />
            </div>

            <div class="overlay-container">
              <label>Link Profile Instagram</label>
              <input type="text" name='profile_instagram' v-model="forms.profile_instagram" />
            </div>

            <div class="overlay-container">
              <label>Followers Instagram</label>
              <input type="number" name='followers_instagram' v-model="forms.followers_instagram" />
            </div>

            <div class="overlay-container">
              <label>Engagement Instagram</label>
              <input type="number" step="0.01" name='engagement_rate_instagram' v-model="forms.engagement_rate_instagram" />
            </div>

            <div class="overlay-container">
              <label>Username Tiktok</label>
              <input type="text" name='username_tiktok' v-model="forms.username_tiktok" />
            </div>

            <div class="overlay-container">
              <label>Link Profile Tiktok</label>
              <input type="text" name='profile_tiktok' v-model="forms.profile_tiktok" />
            </div>

            <div class="overlay-container">
              <label>Followers Tiktok</label>
              <input type="number" name='followers_tiktok' v-model="forms.followers_tiktok" />
            </div>

            <div class="overlay-container">
              <label>Engagement Tiktok</label>
              <input type="number" step="0.01" name='engagement_rate_tiktok' v-model="forms.engagement_rate_tiktok" />
            </div>

            <div class="overlay-container">
              <label>Apakah pernah melakukan live shoping?</label>
              <select name='live_shoping' v-model="forms.live_shoping">
                <option value="">Select an option</option>
                <option value="Ya">Ya</option>
                <option value="Tidak">Tidak</option>
              </select>
            </div>

            <div class="overlay-container">
              <label>Silahkan Upload portofolio/roleplay saat menjelaskan produk! Dalam bentuk video 30 detik - 1 menit</label>
              <!-- input disabled type="text" /-->
              <input style="visibility:visible;margin-top:40px;" type="file" id="video" name="video" accept="video/*" ref="fileInputPortofolio" @change="handleFileChangePortofolio" />
            </div>
            
            <div class="overlay-container">
              <label>Device yang digunakan saat live shopping? *Contoh : iphone 11</label>
              <input type="text" name='device' v-model="forms.device" />
            </div>

            <div class="overlay-container">
              <label>Tulislah histori kerjasama yang pernah dijalankan untuk live shopping dengan brand lain</label>
              <input type="text" name='histori_kerjasama' style="margin-top:40px;padding: 0px 14px;" v-model="forms.histori_kerjasama" />
              <span class="additional">Deskripsi:Sebutkan nama brandnya & update setiap 3 bulan sekali</span>
            </div>
            

            <div class="overlay-container" style="margin-top:10px">
              <label>Tuliskan GMV 1 bulan terakhir</label>
              <input type="number" name='gmv' v-model="forms.gmv" />
            </div>

            <div class="overlay-container">
              <button style="width:100%" @click="submitData" type="button" class="btn btn-outline-success btn-lock">Save</button>
            </div>
            

            <!-- Apakah pernah melakukan live shoping (jawaban Ya/Tidak)
"Silahkan Upload portofolio/roleplay saat menjelaskan produk! 
Deskripsi: Dalam bentuk video 30 detik - 1 menit "
"Device yang digunakan saat live shopping?
*Tulis secara detail merk beserta tipe, contoh : iphone 11"
"Jika ada, tuliskan histori kerjasama yang pernah dijalankan untuk live shopping dengan brand lain
Deksripsi:Sebutkan nama brandnya & update setiap 3 bulan sekali"
Tuliskan GMV 1 bulan terakhir -->

            <!-- { errorMessage && (<div class="error">{errorMessage}</div>) } -->

          </form>

          <!-- <div class="section-edit-private">
            <p>Akun</p>

            <Link to='/profile/edit/email'>
            <div class="overlay-container">
              <label>Alamat Email</label>
              <div class="box top" type="text" name='name'>
                <div>{user.email}</div>
                <img src={Arrow} alt="Arrow" />
              </div>
            </div>
            </Link>

            <Link to='/profile/edit/password'>
            <div class="overlay-container">
              <label>Kata Sandi</label>
              <div class="box bottom" type="text" name='name'>
                <div>********</div>
                <img src={Arrow} alt="Arrow" />
              </div>
            </div>
            </Link>
          </div> -->

        </div>
        <navbar-component page="profile" />

      </div>

    </div>
  </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";

export default {
  name: "ProfileUser",
  components: {
    "navbar-component": navbarComponent,
  },
  data() {
    return {
      filePortofolio: null,
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      detailUser: [],
      errors: [],
      forms: {
      },
      file: null,
      imageUrl: "/assets/images/maternity.png",
      community: [],
      modelCommunity: 0,
      wagroup: [],
      provinsi: [],
      kota: [],
      kecamatan: [],
      personal: [],
      modelWagroup: "",
      modelPassword: null,
      modelProvinsi: {},
      modelKota: {},
      modelKecamatan: "",
      modelPersonal: [],
    };
  },
  watch: {},
  methods: {
    validateSelection() {
      if (this.modelPersonal.length > 5) {
        this.modelPersonal = this.modelPersonal.slice(0, 5);
      }
    },
    
    resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },
    submitData() {
      this.isLoading = true
      let formData = new FormData();
      // formData.append("name", this.forms.name);
      // formData.append("phone", this.forms.phone);
      // formData.append("address", this.forms.address);
      // formData.append("email", this.forms.email);          

      Object.keys(this.forms).forEach((key) => {
        formData.append(key, this.forms[key]);
      });

      formData.append("password", this.modelPassword);
      formData.append("wa_group", this.modelWagroup);
      formData.append("community_id", this.modelCommunity);

      formData.append("provinsi", this.modelProvinsi.nama);
      formData.append("kota", this.modelKota.nama);
      formData.append("kecamatan", this.modelKecamatan);
      formData.append("personal", this.modelPersonal.join(','));

      formData.append("image", this.file);
      formData.append("portofolio", this.filePortofolio);

      const baseURI = this.$settings.endPoint + "pengguna/update/" + this.forms.user_id;
      this.$http
        .post(baseURI, formData, { timeout: 100000 })
        .then((response) => {
          this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            // var params = this.$onRandom(response.data.datas.id);
            // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
            this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }

          }


        });
    },
    loadCommunity() {
      const baseURI = this.$settings.endPoint + "get-community";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          console.log(response)
          this.community = response.data.datas;
          this.modelCommunity = this.detailUser.community_id;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    loadWagroup() {
      const baseURI = this.$settings.endPoint + "master-wa-group/get-wa-group";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.wagroup = response.data.datas;
          this.modelWagroup = this.detailUser.wa_group;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    /*
    loadProvinsi() {
      const baseURI = this.$settings.endPoint + "master-kota/province";
      this.isLoading = true;   
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.provinsi = response.data.datas;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },*/

    loadProvinsi() {
      const baseURI = this.$settings.endPoint + "daerah/select-provinsi";
      this.isLoading = true;   
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,            
          }
        )
        .then((response) => {
          this.isLoading = false;
          console.log(response.data.datas)
          this.provinsi = response.data.datas;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    loadPersonal() {
      const baseURI = this.$settings.endPoint + "master-personal";
      this.isLoading = true;   
      return this.$http
        .get(
          baseURI,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.personal = response.data.datas;
          // this.modelPersonal = this.detailUser.personal;
          this.modelPersonal = this.detailUser.personal.split(',');
          
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    /*loadKota() {      
      const baseURI = this.$settings.endPoint + "master-kota/city/"+ this.modelProvinsi;
      
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.kota = response.data.datas;
          // this.modelKota = this.detailUser.kota;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },*/

    loadKota(kode_provinsi) {      
      const baseURI = this.$settings.endPoint + "daerah/select-kota/"+ kode_provinsi;
      
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,
          }
        )
        .then((response) => {
          this.kota = response.data.datas;
          // this.modelKota = this.detailUser.kota;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    /*loadKecamatan() {      
      const baseURI = this.$settings.endPoint + "master-kota/kecamatan/"+ this.modelKota;
      
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.kecamatan = response.data.datas;
          // this.modelKecamatan = this.detailUser.kecamatan;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },*/

    loadKecamatan(kode_kota) {      
      const baseURI = this.$settings.endPoint + "daerah/select-kecamatan/"+ kode_kota;
      
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,            
          }
        )
        .then((response) => {
          this.kecamatan = response.data.datas;
          // this.modelKecamatan = this.detailUser.kecamatan;
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    previousPage() {
      this.$router.go(-1);
      //window.history.back();
    },
    handleFileChangePortofolio(event) {
            this.filePortofolio = event.target.files[0];
        },
    handleFileChange(event) {
      this.file = event.target.files[0];
      this.previewImage();
    },
    previewImage() {
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };

        reader.readAsDataURL(this.file);
      }
    },
    uploadFile() {
      // Lakukan proses unggah file di sini
      console.log("File berhasil diunggah:", this.file);

      // Reset file setelah diunggah jika diperlukan
      this.file = null;

      // Jika ingin mengakses file input secara programatik, dapat menggunakan ref
      this.$refs.fileInput.value = null;
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;

      const baseURI = this.$settings.endPoint + "get-user/detail/"+ this.detailUser.user_id;
      
      return this.$http
        .get(
          baseURI,
          {
            timeout: 100000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.forms = response.data.datas;
          this.imageUrl = this.forms.image?this.forms.image:this.imageUrl;

          if(this.forms.provinsi !== null){
            this.loadProvinsi();
            // this.modelProvinsi.push({"nama" : this.forms.provinsi });
            this.modelProvinsi.nama = this.forms.provinsi;
            this.modelProvinsi.kode = 32;
          }

          if(this.forms.kota !== null){
            this.loadKota();
            this.modelKota.nama = this.forms.kota;
          }

          if(this.forms.kecamatan !== null){
            this.loadKecamatan();
            this.modelKecamatan.nama = this.forms.kecamatan;
          }
          
          
          
        }).catch(error => {

          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
      
      // let rolePermision = [];
      // userDatas.sub.permision_role.forEach(function(permision){
      //   if(permision.permission.controller == 'EventController'){
      //     rolePermision = permision;
      //   }
      // });

      // if(!rolePermision.method_read){
      //   this.$router.push("/authorized-error");
      // }
      // this.permision_role = rolePermision;


    },
    handleChange(code) {
      this.code = code;
    },
    refreshCaptchaCode() {
      this.$refs.captcha.refreshCaptcha();
    },
    makeOffer() {

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loading();
    this.fetchIt();
    this.loadCommunity();
    this.loadWagroup();
    // this.loadProvinsi();
    this.loadPersonal();
    
  },
};
</script>
<style scoped>
@import '../_partials/partial.css';
</style>