<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <div class="navbars-body">
          <div class="section-profile">

            <div class="icon-edit">
              <a href='/user/profile/edit'>
                <img src="/assets/images/icon_edit.png" />
              </a>
            </div>

            <img v-if="!detailUser.image" class="profile-picture" src="/assets/images/maternity.png" alt="Profile" />
            <img v-else class="profile-picture" :src="detailUser.image" alt="Profile" />

            <p>{{ detailUser.name }}</p>

            <div class="profile-detail">
              <div class="detail-item">
                <img src="/assets/images/icon_phone.png" alt="Phone" />
                <p>
                  Nomor Telepon<br />
                  <b>{{ detailUser.phone }}</b>
                </p>
              </div>
              <div class="divider" />
              <div class="detail-item">
                <img src="/assets/images/icon_address.png" alt="Address" />
                <p>
                  Alamat Rumah<br />
                  <b>{{ detailUser.address || '-' }}</b>
                </p>
              </div>
              <div class="divider" />
              <div class="detail-item">
                <img src="/assets/images/icon_wa.png" alt="Whatsapp" />
                <p>
                  Asal Whatsapp Group<br />
                  <b>{{ detailUser.wa_group || '-' }}</b>
                </p>
              </div>
              <div class="divider" />
              <div class="detail-item">
                <img src="/assets/images/icon_address.png" alt="Address" />
                <p>
                  Community<br />
                  <b>{{ detailUser.community.community || '-' }}</b>
                </p>
              </div>
            </div>

            <button @click="logout()">Keluar</button>
          </div>

        </div>
        <navbar-component page="profile" />

      </div>


    </div>
  </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";

export default {
  name: "ProfileUser",
  components: {
    "navbar-component": navbarComponent,
  },
  data() {
    return {
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      detailUser: [],
      errors: [],
    };
  },
  watch: {},
  methods: {
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    logout() {
      //console.log(localStorage.getItem(''));
      const baseURI = this.$settings.endPoint + "user/logout";
      this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
        console.log(response);
      });
      this.$logoutUser();
      window.location.href = '/';
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // let rolePermision = [];
      // userDatas.sub.permision_role.forEach(function(permision){
      //   if(permision.permission.controller == 'EventController'){
      //     rolePermision = permision;
      //   }
      // });

      // if(!rolePermision.method_read){
      //   this.$router.push("/authorized-error");
      // }
      // this.permision_role = rolePermision;


    },
    handleChange(code) {
      this.code = code;
    },
    refreshCaptchaCode() {
      this.$refs.captcha.refreshCaptcha();
    },
    makeOffer() {

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loading();
    this.fetchIt();
  },
};
</script>
<style scoped>
@import '../_partials/partial.css';
</style>