import Event from "@/components/master/event/Event.vue";
import EventAdd from "@/components/master/event/EventAdd.vue";
import EventDetail from "@/components/master/event/EventDetail.vue";
import EventEdit from "@/components/master/event/EventEdit.vue";
import EventUpload from "@/components/master/event/EventUpload.vue";

let routesEvent= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Event",
        component: Event
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "EventAdd",
        component: EventAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "EventEdit",
        component: EventEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "EventDetail",
        component: EventDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "EventUpload",
        component: EventUpload
      },
    
]

export default routesEvent
  