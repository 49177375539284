<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div :class="{ 'blur-content': this.isLoading }">
      <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">

        <div class="col-lg-12 col-xl-12 stretch-card">
          <div class="card">
            <div class="card-header">
              <!-- h4 style="margin-right: 5px" class="float-start">NEW</h4-->
              <!-- <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="createData()">
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button> -->
              <button class="btn btn-success float-end btn-xs" style="margin-right: 5px"
                @click.prevent="downloadData()">
                <i class="link-icon" data-feather="download"></i>
                {{ $t("downloadData") }} .xlsx
              </button>
            </div>
            <div class="card-body vgt-container">
              <vue-good-table title="list-user" mode="remote" :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionText: $t('data_selected'),
                clearSelectionText: $t('clear'),
              }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
                :totalRows="totalRecords" :line-numbers="true" :isLoading.sync="isLoading" :pagination-options="{
                  enabled: true,
                }" :rows="rows" :columns="columns">
                <div slot="selected-row-actions">
                  <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="validate"> <i
                      class="mdi mdi-send-outline"></i>Approve</button>

                  <button class="btn btn-danger btn-xs" @click="reject"><i class="mdi mdi-close"></i> Revisi</button>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                      detailData(props.index, props.row)
                      ">
                      <i class="mdi mdi-eye" style="font-size:16px"></i>

                    </a>
                    <a href="#" v-if="permision_role.method_update" title="Edit"
                      @click.prevent="editData(props.index, props.row)">
                      <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                    </a>

                    <a href="#" v-if="permision_role.method_delete" title="Delete" @click.prevent="
                      deleteData(props.index, props.row)
                      ">
                      <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                    </a>

                    <a href="#" v-if="permision_role.method_update" title="User" @click.prevent="
                      participant(props.index, props.row)
                      ">
                      <i class="mdi mdi-account-multiple-outline" style="font-size:16px"></i>
                    </a>
                  </span>
                  <span v-else-if="props.column.field == 'profile_instagram'">
                    <a :href="!props.row.profile_instagram.startsWith('https://')?'https://' + props.row.profile_instagram:props.row.profile_instagram" target="_blank">{{ props.row.profile_instagram }}</a>
                  </span>
                  <span v-else-if="props.column.field == 'profile_tiktok'">
                    <a :href="!props.row.profile_tiktok.startsWith('https://')?'https://' + props.row.profile_tiktok:props.row.profile_tiktok" target="_blank">{{ props.row.profile_tiktok }}</a>
                  </span>
                  <span v-else-if="props.column.field == 'bukti_tayang'">
                    <a v-if="props.row.bukti_tayang !== null && props.row.bukti_tayang !== undefined" href="#" @click.prevent="downloadFile(props.row.bukti_tayang)">
                      {{ props.row.bukti_tayang }}
                    </a>
                  </span>
                  <span v-else-if="props.column.field == 'link_konten'">
                    <a v-if="props.row.link_konten !== null && props.row.link_konten !== undefined" :href="props.row.link_konten" target="_blank">Go to Link</a>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataApproved: {},
    status: String,
  },
  name: "CampaignUserApproved",
  components: {
  },
  data() {
    return {
      campaign_id: null,
      showModal: false,
      detailCampaign: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      totalRecords: 100,
      // endPoint: '',
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 100,
      },
      columns: [

        {
          label: "Name",//this.$t("priority_txt"),
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",//this.$t("stok_txt"),
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", //+this.$t("stok_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [],  // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Link Konten",//this.$t("stok_txt"),
          field: "link_konten",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Link Konten", //+this.$t("stok_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [],  // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Bukti Tayang",//this.$t("stok_txt"),
          field: "bukti_tayang",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Bukti Tayang", //+this.$t("stok_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [],  // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Registration Date",//this.$t("priority_txt"),
          field: "reg",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Category", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Community",//this.$t("priority_txt"),
          field: "community",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Category", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "No Hp",//this.$t("priority_txt"),
          field: "phone",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Category", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },



        {
          label: "Jenis Kelamin",//this.$t("priority_txt"),
          field: "jenis_kelamin",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Laki-laki","Wanita"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profil Sosmed",//this.$t("priority_txt"),
          field: "hijab",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Non Hijab","Hijab"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Lahir",//this.$t("priority_txt"),
          field: "tanggal_lahir",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Lahir Anak 1",//this.$t("priority_txt"),
          field: "tanggal_lahir_anak_pertama",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        // tanggal_lahir_anak_kedua,
        // tanggal_lahir_anak_ketiga,
        // tanggal_lahir_anak_keempat,

        {
          label: "Area Domisili",//this.$t("priority_txt"),
          field: "area_domisili",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Luar Jabodetabek","Jabodetabek"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Provinsi",//this.$t("priority_txt"),
          field: "provinsi",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Kota",//this.$t("priority_txt"),
          field: "kota",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Alamat",//this.$t("priority_txt"),
          field: "alamat",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Personal",//this.$t("priority_txt"),
          field: "personal",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Skin Type",//this.$t("priority_txt"),
          field: "skintype",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Username Instagram",//this.$t("priority_txt"),
          field: "username_instagram",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profile Instagram",//this.$t("priority_txt"),
          field: "profile_instagram",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Followers Instagram",//this.$t("priority_txt"),
          field: "followers_instagram",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "No Min Fol 0 - 999",
              "Nano 1.000 - 10.000",
              "Micro 10.001 - 50.000",
              "Mid-Tier 50.001 - 500.000",
              "Macro 500.001 - 1.000.000",
              "Mega 1.000.001 - 10.000.000",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Engagement Rate Instagram",//this.$t("priority_txt"),
          field: "engagement_rate_instagram",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Username Tiktok",//this.$t("priority_txt"),
          field: "username_tiktok",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profile Tiktok",//this.$t("priority_txt"),
          field: "profile_tiktok",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Followers Tiktok",//this.$t("priority_txt"),
          field: "followers_tiktok",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "No Min Fol 0 - 999",
              "Nano 1.000 - 10.000",
              "Micro 10.001 - 50.000",
              "Mid-Tier 50.001 - 500.000",
              "Macro 500.001 - 1.000.000",
              "Mega 1.000.001 - 10.000.000",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Engagement Rate Tiktok",//this.$t("priority_txt"),
          field: "engagement_rate_tiktok",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Pernah Live shoping",//this.$t("priority_txt"),
          field: "live_shoping",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["Ya","Tidak"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Portofolio",//this.$t("priority_txt"),
          field: "portofolio",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Device HP",//this.$t("priority_txt"),
          field: "device",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Histori Kerjasama",//this.$t("priority_txt"),
          field: "histori_kerjasama",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "GMV",//this.$t("priority_txt"),
          field: "gmv",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        // {
        //   label: "Action",
        //   field: "actions",
        //   sortable: false,
        // },
      ],
      rows: [],
      ids: [],
      rowsSelection: [],
    };
  },
  watch: {},
  methods: {
    downloadFileOld(id) {
      const baseURI = this.$settings.endPoint + "campaign-user/download-file/" + id;
      this.isloading = true;
      return this.$http
        .get(baseURI, {
          responseType: "blob",
        })
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];

          // Mendapatkan nama file dari header 'content-disposition'
          const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
          const fileName = fileNameMatch ? fileNameMatch[1] : `download_${Date.now()}`;

          // Mendapatkan jenis konten (mime type) dari header 'content-type'
          const contentType = response.headers['content-type'];

          // Membuat objek Blob dengan tipe konten yang benar
          const blob = new Blob([response.data], { type: contentType });

          // Membuat URL objek Blob
          const fileURL = window.URL.createObjectURL(blob);

          // Membuat elemen anchor untuk men-download file
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);

          // Menambahkan elemen anchor ke body dokumen
          document.body.appendChild(fileLink);

          // Mengklik elemen anchor untuk memulai proses unduhan
          fileLink.click();

          // Membersihkan elemen anchor setelah selesai
          document.body.removeChild(fileLink);

          this.fade(false);
          this.isloading = false;
        })
        .catch((error) => {
          console.error("Error during file download:", error);
          // Handle error
        });
    },

    downloadFile(id) {
      const websiteUrl = id; 
      window.open(websiteUrl, '_blank');      
    },

    validate() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            campaign_user_ids: this.ids,
            status: 'APPROVED'
          }
          const baseURI =
            this.$settings.endPoint + "campaign-user/update-status";
          this.$http
            .post(baseURI, data, { timeout: 20000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },
    reject() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            campaign_user_ids: this.ids,
            status: 'REVISI'
          }
          const baseURI =
            this.$settings.endPoint + "campaign-user/update-status";
          this.$http
            .post(baseURI, data, { timeout: 20000 })
            .then((response) => {
              // this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.ids = [];
      this.rowsSelection = ev.selectedRows;

      this.btnClearSelection = false;
      this.rowsSelection.forEach((item) => {
        this.ids.push(item.campaign_user_id);
      });

    },
    showModalData(data) {
      this.showModal = true;
      this.detailCampaign = data;
    },
    closeMyModal() {
      this.showModal = false;
      this.loadItems();
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'CampaignController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },


    downloadData() {
      const baseURI = this.$settings.endPoint + "campaign-user";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}`+
          `&sort_field=${this.serverParams.sort.field}`+
          `&sort_type=${this.serverParams.sort.type}`+
          `&campaign_id=${this.serverParams.columnFilters.campaign_id}`+       

          `&name=${this.serverParams.columnFilters.name}`+
          `&status=${this.serverParams.columnFilters.status}`+
          `&reg=${this.serverParams.columnFilters.status}`+
          `&community=${this.serverParams.columnFilters.community}`+
          `&phone=${this.serverParams.columnFilters.phone}`+
          `&jenis_kelamin=${this.serverParams.columnFilters.jenis_kelamin}`+
          `&hijab=${this.serverParams.columnFilters.hijab}`+
          `&tanggal_lahir=${this.serverParams.columnFilters.tanggal_lahir}`+
          `&tanggal_lahir_anak_pertama=${this.serverParams.columnFilters.tanggal_lahir_anak_pertama}`+
          `&area_domisili=${this.serverParams.columnFilters.area_domisili}`+
          `&provinsi=${this.serverParams.columnFilters.provinsi}`+
          `&kota=${this.serverParams.columnFilters.kota}`+
          `&alamat=${this.serverParams.columnFilters.alamat}`+
          `&personal=${this.serverParams.columnFilters.personal}`+
          `&skintype=${this.serverParams.columnFilters.skintype}`+
          `&username_instagram=${this.serverParams.columnFilters.username_instagram}`+
          `&profile_instagram=${this.serverParams.columnFilters.profile_instagram}`+
          `&username_tiktok=${this.serverParams.columnFilters.username_tiktok}`+
          `&profile_tiktok=${this.serverParams.columnFilters.profile_tiktok}`+
          
          `&live_shoping=${this.serverParams.columnFilters.live_shoping}`+
          `&device=${this.serverParams.columnFilters.device}`+
          `&histori_kerjasama=${this.serverParams.columnFilters.histori_kerjasama}`+
          `&gmv=${this.serverParams.columnFilters.gmv}`+    

          `&status_source=bukti`+
          `&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    imageCampaign(row) {
      return this.$settings.endPoint + 'campaign/' + row.image;
    },

    editData(index, row) {
      var params = this.$onRandom(row.campaign_id);
      window.location.href = "/campaign/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.campaign_id);
      window.location.href = "/campaign/detail/" + params;
    },

    participant(index, row) {
      var params = this.$onRandom(row.campaign_id);
      window.location.href = "/campaign-user/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "campaign/delete/" + row.campaign_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "campaign-user";
      
      // this.serverParams.columnFilters.campaign_id = this.dataApproved; 
      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(

          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}`+
          `&sort_field=${this.serverParams.sort.field}`+
          `&sort_type=${this.serverParams.sort.type}`+
          `&campaign_id=${this.serverParams.columnFilters.campaign_id}`+       

          `&name=${this.serverParams.columnFilters.name}`+
          `&status=${this.serverParams.columnFilters.status}`+
          `&reg=${this.serverParams.columnFilters.status}`+
          `&community=${this.serverParams.columnFilters.community}`+
          `&phone=${this.serverParams.columnFilters.phone}`+
          `&jenis_kelamin=${this.serverParams.columnFilters.jenis_kelamin}`+
          `&hijab=${this.serverParams.columnFilters.hijab}`+
          `&tanggal_lahir=${this.serverParams.columnFilters.tanggal_lahir}`+
          `&tanggal_lahir_anak_pertama=${this.serverParams.columnFilters.tanggal_lahir_anak_pertama}`+
          `&area_domisili=${this.serverParams.columnFilters.area_domisili}`+
          `&provinsi=${this.serverParams.columnFilters.provinsi}`+
          `&kota=${this.serverParams.columnFilters.kota}`+
          `&alamat=${this.serverParams.columnFilters.alamat}`+
          `&personal=${this.serverParams.columnFilters.personal}`+
          `&skintype=${this.serverParams.columnFilters.skintype}`+
          `&username_instagram=${this.serverParams.columnFilters.username_instagram}`+
          `&profile_instagram=${this.serverParams.columnFilters.profile_instagram}`+
          `&username_tiktok=${this.serverParams.columnFilters.username_tiktok}`+
          `&profile_tiktok=${this.serverParams.columnFilters.profile_tiktok}`+
          
          `&live_shoping=${this.serverParams.columnFilters.live_shoping}`+
          `&device=${this.serverParams.columnFilters.device}`+
          `&histori_kerjasama=${this.serverParams.columnFilters.histori_kerjasama}`+
          `&gmv=${this.serverParams.columnFilters.gmv}`+   
          
          `&followers_instagram=${this.serverParams.columnFilters.followers_instagram}`+
          `&followers_tiktok=${this.serverParams.columnFilters.followers_tiktok}`+

          `&status_source=bukti`,
          { timeout: 50000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          // this.totalRecords = response.data.datas.total;
          let dinamis = this.dinamisKolom(this.rows)

          dinamis.forEach(item => {
            Object.keys(item).forEach(properti => {
              // Cek apakah kolom sudah ada di dalam columns
              if (!this.columns.find(column => column.field === properti)) {
                // Jika belum ada, tambahkan kolom baru
                this.columns.push({
                  label: properti.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
                  field: properti,
                  filterOptions: {
                    enabled: false,
                  },
                });
              }
            });
          });
        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    dinamisKolom(data) {
      return data.map(item => {
        const propertis = Object.keys(item).slice(54);
        const newObj = {};
        propertis.forEach(properti => {
          newObj[properti] = item[properti];
        });
        return newObj;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      setTimeout(() => {
        this.serverParams.columnFilters.campaign_id = this.dataApproved;
        this.loadItems();
        // this.loadItems(); // Tetapkan nilai yang diharapkan setelah mendapatkan data
      }, 1000);
      
    },

    onColumnFilter(params) {
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () {

  },
  mounted() {
    //this.fetchIt();
    setTimeout(() => {
      this.serverParams.columnFilters.campaign_id = this.dataApproved;
      // this.loadItems(); // Tetapkan nilai yang diharapkan setelah mendapatkan data
    }, 1000);

  },
};
</script>
<style scoped>
.vgt-container /deep/ .vgt-table th.sortable button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  position: relative !important;
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; */
  height: 100%;
  margin-left: 3px !important;
  padding-top: 10px !important;
}
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>