<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="CampaignIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/campaign">Campaign</a></li>
                  <li class="breadcrumb-item"><a href="#" @click.prevent="detailData()">Detail</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Participant</li>
                </ol>
              </nav>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">User Campaign</h4>
                  <!-- <button v-if="permision_role.method_create" class="btn btn-primary float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="createData()">
                    <i class="link-icon" data-feather="plus"></i>
                    {{ $t("createNew") }}
                  </button> -->
                  <!--button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button-->
                </div>
                <div class="card-body">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="new-tab" data-bs-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="true">New</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="approve-tab" data-bs-toggle="tab" href="#approve" role="tab" aria-controls="approve" aria-selected="false">Approve</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="storyline-tab" data-bs-toggle="tab" href="#storyline" role="tab" aria-controls="storyline" aria-selected="false">Storyline</a>
                    </li>                    
                    <li class="nav-item">
                      <a class="nav-link" id="draft-tab" data-bs-toggle="tab" href="#draft" role="tab" aria-controls="draft" aria-selected="false">Draft Submitted</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="konten-tab" data-bs-toggle="tab" href="#konten" role="tab" aria-controls="konten" aria-selected="false">Content Approved</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="buktitayang-tab" data-bs-toggle="tab" href="#buktitayang" role="tab" aria-controls="buktitayang" aria-selected="false">Bukti Tayang Submitted</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="finish-tab" data-bs-toggle="tab" href="#finish" role="tab" aria-controls="finish" aria-selected="false">Finish</a>
                    </li>
                  </ul>
                  <div class="tab-content border border-top-0 p-3" id="myTabContent">
                    <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="home-tab">
                      <campaign-new :dataNew="data"></campaign-new>
                    </div>
                    <div class="tab-pane fade" id="approve" role="tabpanel" aria-labelledby="approve-tab">
                      <campaign-approved :dataApproved="data"></campaign-approved>
                    </div>
                    <div class="tab-pane fade" id="storyline" role="tabpanel" aria-labelledby="storyline-tab">
                      <campaign-storyline :dataNew="data"></campaign-storyline>
                    </div>
                    <div class="tab-pane fade" id="draft" role="tabpanel" aria-labelledby="draft-tab">
                     <campaign-draft :dataApproved="data"></campaign-draft>       
                    </div>
                    <div class="tab-pane fade" id="konten" role="tabpanel" aria-labelledby="konten-tab">
                      <campaign-konten :dataApproved="data"></campaign-konten>
                    </div>  
                    <div class="tab-pane fade" id="buktitayang" role="tabpanel" aria-labelledby="buktitayang-tab">
                      <campaign-bukti-tayang :dataApproved="data"></campaign-bukti-tayang>
                    </div>  
                    <div class="tab-pane fade" id="finish" role="tabpanel" aria-labelledby="finish-tab">
                      <campaign-finish :dataApproved="data"></campaign-finish>
                    </div>                     
                  </div>

                  <!--vue-good-table title="list-user" mode="remote" :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionText: $t('data_selected'),
                    clearSelectionText: $t('clear'),
                  }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                    :isLoading.sync="isLoading" :pagination-options="{
                      enabled: true,
                    }" :rows="rows" :columns="columns">
                    <div slot="selected-row-actions">
                      <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="validate"> <i
                          class="mdi mdi-send-outline"></i>Approve</button>

                      <button class="btn btn-danger btn-xs" @click="reject"><i class="mdi mdi-close"></i> Reject</button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                          detailData(props.index, props.row)
                          ">
                          <i class="mdi mdi-eye" style="font-size:16px"></i>

                        </a>
                        <a href="#" v-if="permision_role.method_update" title="Edit"
                          @click.prevent="editData(props.index, props.row)">
                          <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                        </a>

                        <a href="#" v-if="permision_role.method_delete" title="Delete" @click.prevent="
                          deleteData(props.index, props.row)
                          ">
                          <i class="mdi mdi-trash-can-outline" style="font-size:16px"></i>
                        </a>

                        <a href="#" v-if="permision_role.method_update" title="User" @click.prevent="
                          participant(props.index, props.row)
                          ">
                          <i class="mdi mdi-account-multiple-outline" style="font-size:16px"></i>
                        </a>
                      </span>
                      <span v-else-if="props.column.field == 'link_upload'">
                        {{ slicedString(props.row.link_upload) }}...
                      </span>
                      <span v-else-if="props.column.field == 'image'">
                        <img :src="props.row.image" class="img-fluid img-thumbnail" style="max-height:100px;">
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <modal-validation :showModal="showModal" :data="detailCampaign" @closeModal="closeMyModal"></modal-validation> -->
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import campaignUserNew from "@/components/transaction/campaign-user/CampaignUserNew.vue";
import campaignUserApproved from "@/components/transaction/campaign-user/CampaignUserApproved.vue";
import campaignUserBuktiTayang from "@/components/transaction/campaign-user/CampaignUserBuktiTayang.vue";
import campaignUserKonten from "@/components/transaction/campaign-user/CampaignUserKonten.vue";
import campaignUserDraft from "@/components/transaction/campaign-user/CampaignUserDraft.vue";
import campaignUserFinish from "@/components/transaction/campaign-user/CampaignUserFinish.vue";
import campaignUserStoryline from "@/components/transaction/campaign-user/CampaignUserStoryline.vue";

export default {
  name: "CampaignIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "campaign-new": campaignUserNew,
    "campaign-approved": campaignUserApproved,
    "campaign-bukti-tayang": campaignUserBuktiTayang,
    "campaign-konten": campaignUserKonten,
    "campaign-draft": campaignUserDraft,
    "campaign-finish": campaignUserFinish,
    "campaign-storyline": campaignUserStoryline,
  },
  data() {
    return {
      data:0,
      campaign_id : null,
      showModal: false,
      detailCampaign: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      totalRecords: 0,
      // endPoint: '',
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [

        {
          label: "Name",//this.$t("priority_txt"),
          field: "user.name",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",//this.$t("stok_txt"),
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", //+this.$t("stok_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [],  // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Registration Date",//this.$t("priority_txt"),
          field: "reg",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Category", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },    

        {
          label: "Jenis Kelamin",//this.$t("priority_txt"),
          field: "user.jenis_kelamin",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profil Sosmed",//this.$t("priority_txt"),
          field: "user.hijab",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Lahir",//this.$t("priority_txt"),
          field: "user.tanggal_lahir",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Lahir Anak 1",//this.$t("priority_txt"),
          field: "user.tanggal_lahir_anak_pertama",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        
// tanggal_lahir_anak_kedua,
// tanggal_lahir_anak_ketiga,
// tanggal_lahir_anak_keempat,

        {
          label: "Area Domisili",//this.$t("priority_txt"),
          field: "user.area_domisili",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Provinsi",//this.$t("priority_txt"),
          field: "user.provinsi",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Kota",//this.$t("priority_txt"),
          field: "user.kota",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Alamat",//this.$t("priority_txt"),
          field: "user.alamat",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Personal",//this.$t("priority_txt"),
          field: "user.personal",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Skin Type",//this.$t("priority_txt"),
          field: "user.skintype",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Username Instagram",//this.$t("priority_txt"),
          field: "user.username_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profile Instagram",//this.$t("priority_txt"),
          field: "user.profile_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Followers Instagram",//this.$t("priority_txt"),
          field: "user.followers_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "No Min Fol 0 - 999",
              "Nano 1.000 - 10.000",
              "Micro 10.001 - 50.000",
              "Mid-Tier 50.001 - 500.000",
              "Macro 500.001 - 1.000.000",
              "Mega 1.000.001 - 10.000.000",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Engagement Rate Instagram",//this.$t("priority_txt"),
          field: "user.engagement_rate_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Username Tiktok",//this.$t("priority_txt"),
          field: "user.username_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profile Tiktok",//this.$t("priority_txt"),
          field: "user.profile_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Followers Tiktok",//this.$t("priority_txt"),
          field: "user.followers_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [
              "No Min Fol 0 - 999",
              "Nano 1.000 - 10.000",
              "Micro 10.001 - 50.000",
              "Mid-Tier 50.001 - 500.000",
              "Macro 500.001 - 1.000.000",
              "Mega 1.000.001 - 10.000.000",
            ], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Engagement Rate Tiktok",//this.$t("priority_txt"),
          field: "user.engagement_rate_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        

        // {
        //   label: "Action",
        //   field: "actions",
        //   sortable: false,
        // },
      ],
      rows: [],
      ids: [],
      rowsSelection: [],
    };
  },
  watch: {},
  methods: {
    validate() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            campaign_user_ids: this.ids,
            status: 'APPROVED'
          }
          const baseURI =
            this.$settings.endPoint + "campaign-user/update-status";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },
    reject() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            campaign_user_ids: this.ids,
            status: 'REJECT'
          }
          const baseURI =
            this.$settings.endPoint + "campaign-user/update-status";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              // this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.ids = [];
      this.rowsSelection = ev.selectedRows;

      this.btnClearSelection = false;
      this.rowsSelection.forEach((item) => {
        this.ids.push(item.campaign_user_id);
      });

    },
    showModalData(data) {
      this.showModal = true;
      this.detailCampaign = data;
    },
    closeMyModal() {
      this.showModal = false;
      this.loadItems();
    },
    loadCommunity() {
      const baseURI = this.$settings.endPoint + "community";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI, { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          var arrayCommunity = response.data.datas.data;
          this.columns[7].filterOptions.filterDropdownItems = arrayCommunity.map(item => item.community);


        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    slicedString(str) {
      return str.substring(0, 15);
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'CampaignController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    createData() {
      window.location.href = "/campaign/add";
    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "campaign";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      //var columnFilters = this.serverParams.columnFilters;


      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&name=${this.serverParams.columnFilters.name}&status=${this.serverParams.columnFilters.status}&category=${this.serverParams.columnFilters.category}&tipe_project=${this.serverParams.columnFilters.tipe_project}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    imageCampaign(row) {
      return this.$settings.endPoint + 'campaign/' + row.image;
    },

    editData(index, row) {
      var params = this.$onRandom(row.campaign_id);
      window.location.href = "/campaign/edit/" + params;
    },

    detailData() {
      var params = this.$onRandom(this.data);
      window.location.href = "/campaign/detail/" + params;
    },

    participant(index, row) {
      var params = this.$onRandom(row.campaign_id);
      window.location.href = "/campaign-user/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "campaign/delete/" + row.campaign_id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // loadItems() {
    //   const baseURI = this.$settings.endPoint + "campaign-user";

    //   //var columnFilters = this.serverParams.columnFilters;
    //   this.isLoading = true;
    //   return this.$http
    //     .get(

    //       baseURI +
    //       `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&campaign_id=${this.serverParams.columnFilters.campaign_id}&status=${this.serverParams.columnFilters.status}&reg=${this.serverParams.columnFilters.reg}`,
    //       { timeout: 10000 }
    //     )
    //     .then((response) => {
    //       this.isLoading = false;
    //       this.rows = response.data.datas.data;
    //       this.dataNew = response.data.datas.data;
    //       this.totalRecords = response.data.datas.total;
    //     }).catch(error => {

    //       if (error.code == "ECONNABORTED") {
    //         this.error(this.$t("check_internet_connection_txt"));

    //       } else {

    //         if (error.response) {
    //           if (error.response.status === 422) {
    //             this.errors = error.response.data.errors;
    //             this.resultError(error.response.data.errors);
    //           } else if (error.response.status === 500) {
    //             this.$router.push('/server-error');
    //           } else if (error.response.status === 401) {
    //             this.$router.push("/authorized-error");
    //           } else {
    //             this.$router.push('/page-not-found');
    //           }
    //         }

    //       }
    //     });
    // },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    var params = this.$onBehind(this.$route.params.id);
    // this.serverParams.columnFilters.campaign_id = params[0];  
    this.data = params[0];  
    
    // this.loadItems()
    // this.loadCommunity();

  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>