<template>
  <div class='navbars-container'>
    <div class='navbars'>
      <a href='/user/home' class='item' :class="isactiveHome">
        <img :src="homeImage" alt="Home"/>
        <p>Beranda</p>
      </a>

      <a href='/user/profile' class='item' :class="isactiveProfile">
        <img :src="profileImage" alt="Profile"/>
        <p>Profil</p>
      </a>

      <a href='/user/campaign' class='item' :class="isactiveProfile">
        <img :src="campaignImage" alt="Profile"/>
        <p>Campaign</p>
      </a>

      <a href="/user/article" class='item' :class="isactiveArticle">
        <img :src="articleImage" alt="Article"/>
        <p>Article</p>
      </a>

      <a href='/user/event' class='item' :class="isactiveEvent">
        <img :src="calendarImage" alt="Calendar"/>
        <p>Event</p>
      </a>
    </div>

  </div>
</template>


<script>

export default {
  name: 'articleUser',
  props: [ 'page'
  ],
  components: {
  },
  data() {
    return {
      rows: {},
      HomeActive : '/assets/images/home_active.png',
      HomeInactive : '/assets/images/home_inactive.png',
      ProfileActive : '/assets/images/profile_active.png',
      ProfileInactive : '/assets/images/profile_inactive.png',
      ArticleActive : '/assets/images/article_active.png',
      ArticleInactive : '/assets/images/article_inactive.png',
      CalendarActive : '/assets/images/calendar_active.png',
      CalendarInactive : '/assets/images/calendar_inactive.png',
      homeImage : '/assets/images/home_active.png',
      profileImage : '/assets/images/profile_inactive.png',
      articleImage : '/assets/images/article_inactive.png',
      calendarImage : '/assets/images/calendar_inactive.png',
      campaignImage : '/assets/images/campaign.png',
      isactiveHome: 'active',
      isactiveProfile: 'inactive',
      isactiveArticle: 'inactive',
      isactiveEvent: 'inactive',

    }
  },
  watch: {

  },
  methods: {
    error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    load_nav(){
      if(this.page == 'home'){
      // window.location.href = "/user/home";
      this.isactiveHome = 'active'
      this.isactiveProfile = 'inactive'
      this.isactiveArticle = 'inactive'
      this.isactiveEvent = 'inactive'
      this.homeImage = this.HomeActive;
      this.profileImage = this.ProfileInactive; 
      this.articleImage = this.ArticleInactive;
      this.calendarImage = this.CalendarInactive;
      } else if(this.page == 'article'){
      this.isactiveHome = 'inactive'
      this.isactiveProfile = 'inactive'
      this.isactiveArticle = 'active'
      this.isactiveEvent = 'inactive'
      this.homeImage = this.HomeInactive;
      this.profileImage = this.ProfileInactive; 
      this.articleImage = this.ArticleActive;
      this.calendarImage = this.CalendarInactive;
      }else if(this.page == 'event'){
      this.isactiveHome = 'inactive'
      this.isactiveProfile = 'inactive'
      this.isactiveArticle = 'inactive'
      this.isactiveEvent = 'active'
      this.homeImage = this.HomeInactive;
      this.profileImage = this.ProfileInactive; 
      this.articleImage = this.ArticleInactive;
      this.calendarImage = this.CalendarActive;
      }else if(this.page == 'profile'){
      this.isactiveHome = 'inactive'
      this.isactiveProfile = 'active'
      this.isactiveArticle = 'inactive'
      this.isactiveEvent = 'inactive'
      this.homeImage = this.HomeInactive;
      this.profileImage = this.ProfileActive; 
      this.articleImage = this.ArticleInactive;
      this.calendarImage = this.CalendarInactive;
      }
    },
    

  //   switch (props.active) {
  //   case 'home':
  //     images.home = HomeActive;
  //     styles.home = 'active';
  //     break;
  //   case 'profile':
  //     images.profile = ProfileActive;
  //     styles.profile = 'active';
  //     break;
  //   case 'article':
  //     images.article = ArticleActive;
  //     styles.article = 'active';
  //     break;
  //   case 'calendar':
  //     images.calendar = CalendarActive;
  //     styles.calendar = 'active';
  //     break;
  //   default:
  //     break;
  // }
    loadItems() {
      const baseURI = this.$settings.endPoint + "get-article";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=10&page=1&sort_field=&sort_type=&title=&link=&priority=`,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas;
        }).catch(error => {

        
    console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },


  },
  events: {

  },
  created: function () {

  },
  mounted() {
   //  this.loadItems();
    this.load_nav();

  }

}
</script>
<style scoped>@import 'partial.css';</style>