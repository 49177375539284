<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="my-component">

        <!-- </div> class="main-wrapper"> -->
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <div class='parent-login'>
          <div class='section-login'>
            <img src="/assets/images/logo.png" alt="Logo" />

            <form @submit.prevent="submitData" method="POST" autocomplete="off">
              <label>Reset Password</label>
              <input type="password" id='email' name="email" placeholder="Password Baru" v-model="forms.password" autofocus
                required />
              <input type="password" id='email' name="email" placeholder="Konfirmasi Password" v-model="forms.password_confirmation" autofocus
                required />
              <!-- { errorMessage && (<div class="error">{errorMessage}</div>) } -->
              <input type="submit" value="Submit" />
            </form>


          </div>
        </div>
        <div class='footer-helper'>
          <information-component classMenu="InformationIndex"></information-component>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
// import { setAuthToken, getUserInfo } from "@/middleware/auth";
import informationComponent from "@/components/_partials/information";
// import VueCaptcha from "vue-captcha-code";

export default {
  name: "UserLogin",
  components: {
    "information-component": informationComponent
    // VueCaptcha,
  },
  data() {
    return {
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      langs: ["id", "en"],
      forms: { password:'', password_confirmation:'',},
    };
  },
  watch: {},
  methods: {
    handleChange(code) {
      this.code = code;
    },
    submitData() {
      // this.$swal({
      //   title: this.$t('areYouSure'),
      //   text: this.$t('yourData'),
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes!'
      // }).then((result) => {
      //  if (result.value) {
          this.fade(true);
          let formData = new FormData();
          formData.append("email", this.$route.params.email);
          formData.append("token", this.$route.params.token);
          formData.append("password", this.forms.password);
          formData.append("password_confirmation", this.forms.password_confirmation);

          const baseURI = this.$settings.endPoint + "auth/change-password";

          this.$http.post(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.errors = [];
                this.success(response.data.datas.messages);
                window.location.href = '/';
              } else {
                this.forms.captchaCode = ""
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push('/server-error');
                } else {
                  this.$router.push('/page-not-found');
                }
              }
            });

       // }
      // })
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loading();
    // this.langChanged(this.$i18n.locale);
  },
};
</script>
<style scoped>
@import '_partials/partial.css';
</style>