<template>
  <div>
    <!-- <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div> -->
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="Dashboard"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="row card" style="padding:5px">
                                      
            </div>


          </div>


        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "./_partials/_sidebar";
import navbarComponent from "./_partials/_navbar";
import footerComponent from "./_partials/_footer";
import moment from "moment";


export default {
  name: "UserDashboard",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      dateRange: "",
      opens: "center",
      locale: {
        format: "yyyy-mm-dd",
      },

      pickupDates: {
        startDate: null,
        endDate: null,
      },
      
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      detailUser: {},
      langs: ["id", "en"],
      name: "",
      rows:[]
    };
  },
  computed: {
    myStyles() {
      return {
        height: "500px",
        position: "relative",
      };
    },
  },
  watch: {},
  methods: {
    formatDate(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      if(this.detailUser.role_id == 2){
          this.$router.push('/user/home');
      }
    },

    loadItems() {
      const baseURI = this.$settings.endPoint + "user";
      // this.isLoading = true;
      return this.$http
        .get(
           baseURI,
          //  +  `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&judul=${this.serverParams.columnFilters.judul}&sinopsis=${this.serverParams.columnFilters.sinopsis}&hal=${this.serverParams.columnFilters.hal}&pengarang=${this.serverParams.columnFilters.pengarang}&penerbit=${this.serverParams.columnFilters.penerbit}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
            
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
}
</style>