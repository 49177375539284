<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">

      <!--begin::sidebar-->
      <sidebar-component classMenu="UsersIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">Database KOL</h4>


                  <button v-if="this.permision_role.method_read" class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>

                <div class="card-body">
                  <vue-good-table title="list-user" mode="remote" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                    :isLoading.sync="isLoading" :pagination-options="{
                      enabled: true,
                    }" :rows="rows" :columns="columns">
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">

                        <a href="#" v-if="permision_role.method_read" title="Detail" @click.prevent="
                          detailData(props.index, props.row)
                          ">
                          <i class="mdi mdi-eye" style="font-size:16px"></i>

                        </a>
                        <a href="#" v-if="permision_role.method_update" title="Edit"
                          @click.prevent="editData(props.index, props.row)">
                          <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                        </a>



                        <a v-if="permision_role.method_update == true && props.row.status === 'deactived'" href="#"
                          title="Click for Activated User" @click.prevent="
                            deleteData(props.index, props.row, 'active')
                            ">
                          <i class="mdi mdi-account-off-outline" style="font-size:16px"></i>
                        </a>
                        <a href="#" v-if="permision_role.method_update == true && props.row.status === 'active'"
                          title="Click for Deactived User" @click.prevent="
                            deleteData(props.index, props.row, 'deactived')
                            ">
                          <i class="mdi mdi-account" style="font-size:16px"></i>
                        </a>

                        
                      </span>
                      <span v-else-if="props.column.field == 'campaign'">
                        <a href="#" title="User" @click.prevent="
                          participant(props.row)
                          ">
                          Check Campaigns
                        </a>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->
        <modal-campaign :showModal="showModal" :data="detailCampaign" @closeModal="closeMyModal"></modal-campaign>

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalCampaign from "@/components/modal/modalListCampaign.vue";

export default {
  name: "UsersIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-campaign": modalCampaign,
  },
  data() {
    return {
      showModal: false,
      detailCampaign: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      permision_role: [],
      errors: [],
      langs: ["id", "en"],
      detailUser: [],
      detailDivisi: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [
        // {
        //   label: "Username",
        //   field: "username",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By Username", // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Email", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "HP",
          field: "phone",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Email", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Community",
          field: "community",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Riwayat Campaign",//this.$t("priority_txt"),
          field: "campaign",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Campaign", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Jenis Kelamin",//this.$t("priority_txt"),
          field: "jenis_kelamin",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profil Sosmed",//this.$t("priority_txt"),
          field: "hijab",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Lahir",//this.$t("priority_txt"),
          field: "tanggal_lahir",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal Lahir Anak 1",//this.$t("priority_txt"),
          field: "tanggal_lahir_anak_pertama",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        // tanggal_lahir_anak_kedua,
        // tanggal_lahir_anak_ketiga,
        // tanggal_lahir_anak_keempat,

        {
          label: "Area Domisili",//this.$t("priority_txt"),
          field: "area_domisili",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Provinsi",//this.$t("priority_txt"),
          field: "provinsi",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Kota",//this.$t("priority_txt"),
          field: "kota",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Alamat",//this.$t("priority_txt"),
          field: "alamat",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Personal",//this.$t("priority_txt"),
          field: "personal",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Skin Type",//this.$t("priority_txt"),
          field: "skintype",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Username Instagram",//this.$t("priority_txt"),
          field: "username_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profile Instagram",//this.$t("priority_txt"),
          field: "profile_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Followers Instagram",//this.$t("priority_txt"),
          field: "followers_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Engagement Rate Instagram",//this.$t("priority_txt"),
          field: "engagement_rate_instagram",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Username Tiktok",//this.$t("priority_txt"),
          field: "username_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Profile Tiktok",//this.$t("priority_txt"),
          field: "profile_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Followers Tiktok",//this.$t("priority_txt"),
          field: "followers_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Engagement Rate Tiktok",//this.$t("priority_txt"),
          field: "engagement_rate_tiktok",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Name", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

      ],
      rows: [],
    };
  },
  watch: {},
  methods: {
    participant(data) {
      this.showModal = true;
      this.detailCampaign = data;
    },
    closeMyModal() {
      this.showModal = false;
      this.loadItems();
    },
    resetPoint() {
      let formData = new FormData();
      formData.append("community", this.serverParams.columnFilters.community);
      this.$swal({
        title: this.$t("areYouSure"),
        text: status,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          const baseURI =
            this.$settings.endPoint + "pengguna/reset-point";
          this.$http
            .post(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "pengguna";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      var columnFilters = this.serverParams.columnFilters;

      var role = columnFilters["role.name"];

      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&name=${this.serverParams.columnFilters.name}&email=${this.serverParams.columnFilters.email}&role=${role}&community=${this.serverParams.columnFilters.community}&point=${this.serverParams.columnFilters.point}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    createData() {
      window.location.href = "/users/add";
    },

    editData(index, row) {
      var params = this.$onRandom(row.user_id);
      window.location.href = "/users/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.user_id);
      window.location.href = "/users/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: status,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "pengguna/update-status/" + row.user_id;
          this.$http
            .patch(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    loadCommunity() {
      const baseURI = this.$settings.endPoint + "community";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI, { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          var arrayCommunity = response.data.datas.data;
          console.log(arrayCommunity)
          // this.columns[4].filterOptions.filterDropdownItems = arrayCommunity.map(item => item.community);
          // this.community = arrayCommunity

        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "pengguna/kol";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&name=${this.serverParams.columnFilters.name}&email=${this.serverParams.columnFilters.email}&community=${this.serverParams.columnFilters.community}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
          this.isLoading = false;
        }).catch(error => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'UserController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },


  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
    this.loadCommunity();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>