import Setting from "@/components/master/setting/Setting.vue";
import SettingAdd from "@/components/master/setting/SettingAdd.vue";
import SettingDetail from "@/components/master/setting/SettingDetail.vue";
import SettingEdit from "@/components/master/setting/SettingEdit.vue";
import SettingUpload from "@/components/master/setting/SettingUpload.vue";

let routesSetting= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Setting",
        component: Setting
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "SettingAdd",
        component: SettingAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "SettingEdit",
        component: SettingEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "SettingDetail",
        component: SettingDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "SettingUpload",
        component: SettingUpload
      },
    
]

export default routesSetting
  