import WaGroup from "@/components/master/wa-group/WaGroup.vue";
import WaGroupAdd from "@/components/master/wa-group/WaGroupAdd.vue";
import WaGroupDetail from "@/components/master/wa-group/WaGroupDetail.vue";
import WaGroupEdit from "@/components/master/wa-group/WaGroupEdit.vue";
import WaGroupUpload from "@/components/master/wa-group/WaGroupUpload.vue";

let routesWaGroup= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "WaGroup",
        component: WaGroup
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "WaGroupAdd",
        component: WaGroupAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "WaGroupEdit",
        component: WaGroupEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "WaGroupDetail",
        component: WaGroupDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "WaGroupUpload",
        component: WaGroupUpload
      },
    
]

export default routesWaGroup
  