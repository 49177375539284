<template>
    <div>
        <div v-if="isLoading">
            <!--begin::Loading Content-->
            <div class="main-wrapper">
                <div class="page-wrapper full-page">
                    <div class="container">
                        <div class="row">
                            <!--begin::Card-->
                            <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                                <div class="overlay-layer card-rounded bg-opacity-5">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Card-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Loading Content-->
        </div>
        <div v-else>
            <div class="App">
                <div class="navbars-body overlay-container">
                    <img class='header-add-reward' src="/assets/images/header_reward.png" alt='Header' />
                    <div class='section-add-reward'>
                        <div class='toolbar'>
                            <img src="/assets/images/arrow_white.png" alt='Arrow' @click="previousPage" />
                            <p>Tambah Poin</p>
                        </div>

                        <form @submit.prevent="submitData">
                            <div class='content'>
                                <label>Tanggal</label>
                                <input required type='date' name='date' v-model="forms.date" />

                                <label>Jenis Event</label>
                                <select name='type' v-model="modelEventType" @change="callEvent()">
                                    <option value="">Select an option</option>
                                    <option v-for="item in eventType" :value="item.id" :key="item">{{ item.type }}</option>
                                </select>

                                <label>Nama Event</label>
                                <select name='event' v-model="modelEvent">
                                    <option value="">Select an option</option>
                                    <option v-for="item in event" :value="item.id" :key="item">{{ item.name }}</option>
                                </select>

                                <label>Username</label>
                                <input required type='text' name='date' v-model="forms.username" />

                                <label v-if="detailUser.community.community == 'Bert Influencer'">Community</label>
                                <select v-if="detailUser.community.community == 'Bert Influencer'" name='community'
                                    v-model="forms.community">
                                    <option value="">Select an option</option>
                                    <option value="Geng Glowing">Geng Glowing</option>
                                    <option value="Ibu2Canggih">Ibu2Canggih</option>
                                    <option value="Ikan Galau">Ikan Galau</option>
                                </select>

                                <label>Link Postingan (Kosongkan Bila Upload)</label>
                                <input type='text' name='link-upload' v-model="forms.link_upload" />

                                <div v-if="!forms.link_upload">
                                    <label>Upload Bukti Partisipan</label>
                                    <div>
                                        <label class='upload' htmlFor='image'>
                                            <!-- <div>
                                            <img src="/assets/images/upload_icon.png" alt='Upload' />
                                            <p>Upload</p>
                                        </div>
                                        <label for="image"><u>Ganti Foto</u></label> -->
                                            <span v-if="file">File yang Dipilih: {{ file.name }}</span>
                                            <br v-if="file" />
                                            <div>

                                                <img src="/assets/images/upload_icon.png" alt='Upload' />
                                                <p>Upload</p>

                                            </div>
                                            <input type="file" id="image" name="image" accept="image/*" ref="fileInput"
                                                @change="handleFileChange" />


                                        </label>

                                        <input type='file' id='image' name='image' accept='image/*'
                                            onChange={onImagePicked} />
                                    </div>

                                </div>

                            </div>
                            <input type='submit' />
                        </form>
                    </div>

                </div>

                <navbar-component />

            </div>


        </div>
    </div>
</template>
  
  
<script>
import navbarComponent from "@/components/_partials/navbars";

export default {
    name: "HomeUser",
    components: {
        "navbar-component": navbarComponent,
    },
    data() {
        return {
            code: "",
            locale: "",
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            isLoading: false,
            detailUser: [],
            errors: [],
            forms: [],
            eventType: [],
            modelEventType: [],
            event: [],
            modelEvent: [],
            file: null,
        };
    },
    watch: {},
    methods: {
        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },
        submitData() {
            this.isLoading = true
            let formData = new FormData();
            formData.append("date", this.forms.date);
            if (this.detailUser.community.community) {
                formData.append("community", this.forms.community);
            }
            formData.append("username", this.forms.username);
            formData.append("link_upload", this.forms.link_upload);
            formData.append("id_event", this.modelEvent);
            formData.append("image", this.file);

            const baseURI = this.$settings.endPoint + "event-form/create";
            this.$http
                .post(baseURI, formData, { timeout: 10000 })
                .then((response) => {
                    this.isLoading = false
                    if (response.data.status === 200) {
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                        this.modelEventType = []
                        this.modelEvent = []
                        this.file = null
                        this.forms.date = null
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },
        handleFileChange(event) {
            this.file = event.target.files[0];
        },
        callEvent() {
            const baseURI = this.$settings.endPoint + "master-event" + "?id_type=" + this.modelEventType + "&source=user&date=" + this.forms.date;
            return this.$http
                .get(
                    baseURI, { timeout: 10000 }
                )
                .then((response) => {
                    this.event = response.data.datas.data;
                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        loadEventType() {
            const baseURI = this.$settings.endPoint + "event-type?source=user";
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI, { timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.eventType = response.data.datas.data;
                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        previousPage() {
            this.$router.go(-1);
            //window.history.back();
        },
        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            // let rolePermision = [];
            // userDatas.sub.permision_role.forEach(function(permision){
            //   if(permision.permission.controller == 'EventController'){
            //     rolePermision = permision;
            //   }
            // });

            // if(!rolePermision.method_read){
            //   this.$router.push("/authorized-error");
            // }
            // this.permision_role = rolePermision;


        },
        handleChange(code) {
            this.code = code;
        },
        refreshCaptchaCode() {
            this.$refs.captcha.refreshCaptcha();
        },
        makeOffer() {

        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.loading();
        this.fetchIt();
        this.loadEventType();
    },
};
</script>
<style scoped>
@import '../_partials/partial.css';
</style>