import { render, staticRenderFns } from "./RedeemProdukUpload.vue?vue&type=template&id=b542c732&scoped=true"
import script from "./RedeemProdukUpload.vue?vue&type=script&lang=js"
export * from "./RedeemProdukUpload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b542c732",
  null
  
)

export default component.exports