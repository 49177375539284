var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_vm._m(0)]):_c('div',[_c('div',{staticClass:"App"},[_c('banner-component',{attrs:{"classMenu":"BannerIndex"}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"section-article"},[_vm._m(5),_c('article-component',{attrs:{"classMenu":"ArticleIndex"}})],1),_c('information-component',{attrs:{"classMenu":"InformationIndex"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"page-wrapper full-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"text-center mb-3 mt-4 overlay overlay-block",staticStyle:{"background-color":"none"}},[_c('div',{staticClass:"overlay-layer card-rounded bg-opacity-5"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-why-ibu2canggih"},[_c('p',[_vm._v("Bert's"),_c('br'),_vm._v("Community")]),_c('div',{staticClass:"overlay-container"},[_c('div',{staticClass:"content top"},[_vm._v(" Bert's Community memiliki 3 Komunitas utama, yaitu Ibu2Canggih, Geng Glowing & IkanGalau. ")]),_c('div',{staticClass:"content bot"},[_vm._v(" Setiap bulannya, komunitas kita memiliki Program Reward "),_c('br'),_vm._v(" untuk mengapresiasi keaktifan setiap anggota dalam"),_c('br'),_vm._v(" kegiatan masing-masing Komunitas. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-partner"},[_c('p',[_vm._v("Our Previous Sponsor: ")]),_c('img',{staticClass:"image",attrs:{"src":"/assets/images/partner.png","alt":"Maspion"}}),_c('p',[_vm._v("and many more..")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-ibu2canggih-benefit"},[_c('p',[_vm._v("Manfaat tergabung dalam"),_c('br'),_vm._v("Bert's Community")]),_c('div',{staticClass:"items"},[_c('div',{staticClass:"item"},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"/assets/images/benefit_sharing.png","alt":"Benefit Sharing"}}),_c('div',[_vm._v("Sharing dengan para member komunitas")])]),_c('div',{staticClass:"item"},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"/assets/images/benefit_survey.png","alt":"Benefit Survey"}}),_c('div',[_vm._v("Mengikuti berbagai event Brand & Komunitas")])]),_c('div',{staticClass:"item"},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"/assets/images/benefit_product.png","alt":"Benefit Product"}}),_c('div',[_vm._v("Mendapatkan produk gratis berbagai brand")])]),_c('div',{staticClass:"item"},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"/assets/images/benefit_momfluencer.png","alt":"Benefit Momfluencer"}}),_c('div',[_vm._v("Berkesempatan menjadi Bert's Influencers")])]),_c('div',{staticClass:"item"},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":"/assets/images/benefit_reward.png","alt":"Benefit Reward"}}),_c('div',[_vm._v("Mengikuti Program Reward berhadiah ekslusif")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-auth"},[_c('p',[_vm._v("Ayo dapatkan berbagai manfaat & keuntungan dengan bergabung di Bert's Community!")]),_c('div',{staticClass:"buttons"},[_c('div',[_c('div',{staticClass:"info"},[_vm._v("Sudah punya akun?")]),_c('a',{attrs:{"href":"/login"}},[_c('button',{staticClass:"login"},[_vm._v("Masuk")])])]),_c('div',[_c('div',{staticClass:"info"},[_vm._v("Belum punya akun?")]),_c('a',{attrs:{"href":"/registrasi"}},[_c('button',{staticClass:"register"},[_vm._v("Registrasi")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-title"},[_c('p',[_vm._v("Artikel")]),_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }