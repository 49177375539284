var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('div',[_vm._m(0)]):_c('div',[_c('div',{staticClass:"App"},[_c('div',{staticClass:"navbars-body overlay-container"},[_c('img',{staticClass:"header-reward",attrs:{"src":"/assets/images/header_reward.png","alt":"Header"}}),_c('div',{staticClass:"section-reward"},[_c('div',{staticClass:"toolbar"},[_c('img',{attrs:{"src":"/assets/images/arrow_white.png","alt":"Arrow"},on:{"click":_vm.previousPage}}),_c('p',[_vm._v("Detail Poin")])]),_c('div',{staticClass:"profile"},[_c('div',[(!_vm.detailUser.image)?_c('img',{attrs:{"src":"/assets/images/maternity.png","alt":"Profile"}}):_c('img',{attrs:{"src":_vm.detailUser.image,"alt":"Profile"}}),_c('p',[_vm._v(" "+_vm._s(_vm.detailUser.community.community)),_c('br'),_c('b',[_vm._v(_vm._s(_vm.detailUser.name))])])]),_c('div',[_c('img',{attrs:{"src":"/assets/images/point_icon.png","alt":"Profile"}}),_c('p',[_vm._v(" Total Poin"),_c('br'),(!_vm.forms)?_c('b',[_vm._v("0")]):_c('b',[_vm._v(_vm._s(typeof _vm.forms[0].user.point !== 'undefined' ? _vm.forms[0].user.point : _vm.detailUser.point))])])])]),(!_vm.forms)?_c('div',{staticClass:"item"},[_vm._v(" No Data ")]):_vm._e(),_vm._l((_vm.forms),function(form,index){return _c('div',{key:form.id,staticClass:"item"},[_c('img',{attrs:{"src":"/assets/images/internet_icon.png","alt":"Event Icon"}}),_c('div',{staticClass:"detail"},[_c('div',{staticClass:"content"},[_c('div',[_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(form.event.type.type))]),_c('div',[_c('b',[_vm._v(_vm._s(form.event.name))])])]),_c('div',{staticClass:"status",class:form.status == 'valid' ? 'status-valid' : (form.status == 'invalid' ? 'status-invalid' : 'status-pending')},[_c('img',{attrs:{"src":form.status == 'valid' ? '/assets/images/done_icon.png' :
        form.status == 'invalid' ? '/assets/images/close_icon.png' :
            form.status == 'expired' ? '/assets/images/expired_icon.png' :
                '/assets/images/pending.png',"alt":"Status Icon"}}),_c('div',[_vm._v(_vm._s(form.status))])]),_c('span',{staticStyle:{"color":"darkgrey"}},[_vm._v("Expiry On ")]),_c('span',[_vm._v(_vm._s(_vm.getExpiryDate(form.date)))])]),(form.status !== 'invalid')?_c('div',{staticClass:"point",class:{
        'point-valid': form.status == 'valid',
        'point-pending': form.status == 'pending',
        'point-expired': form.status == 'expired'
    }},[_vm._v(" "+_vm._s(form.point))]):_vm._e()]),(index < (_vm.forms.length - 1))?_c('div',{staticClass:"divider"}):_vm._e()])])})],2)]),_c('navbar-component',{attrs:{"page":"home"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"page-wrapper full-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"text-center mb-3 mt-4 overlay overlay-block",staticStyle:{"background-color":"none"}},[_c('div',{staticClass:"overlay-layer card-rounded bg-opacity-5"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])])])])])])])
}]

export { render, staticRenderFns }