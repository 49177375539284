<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="linkAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            "
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Tambah Article
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Title</label
                        >
                      </div>
                      
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="title"
                          placeholder="title"
                          v-model="forms.title"
                          required
                        />
                        <div v-if="errors.title">
                          <div
                            v-for="error in errors.title"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Link</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="link"
                          placeholder="link"
                          v-model="forms.link"
                        />
                         
                        
                        <div v-if="errors.link">
                          <div
                            v-for="error in errors.link"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Priority</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <select class="form-control" name="priority" id="priority" v-model="forms.priority">
                          <option value=1>Show</option>
                          <option value=0>Hide</option>
                        </select>
                        <div v-if="errors.priority">
                          <div
                            v-for="error in errors.priority"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("images_txt") }}</label
                        > 
                      </div>
                      <div class="col-lg-8">
                        <div v-if="images_temporary" class="dropify-wrapper has-preview">
                          <div class="dropify-message"> 
                            <span class="file-icon" v-if="!images_temporary"> 
                              <p>Drag and drop a file here or click</p>
                            </span>
                            <p class="dropify-error">Ooops, something wrong appended.</p>
                          </div>
                          <div class="dropify-loader" style="display: none;"></div>
                          <div class="dropify-errors-container">
                            <ul></ul>
                          </div>
                          <input type="file" ref="images" accept="image/*" class="form-control" @change="handleFileUpload($event)">
                          <button type="button" class="dropify-clear" @click="removeImages">Remove</button>
                          <div  class="dropify-preview" style="display: block;">
                            <img :src="images_temporary" width="90%" height="80%"/>
                          <span class="dropify-render"></span>
                          <div class="dropify-infos">
                            <div class="dropify-infos-inner">
                              <p class="dropify-filename">
                              </p><p class="dropify-infos-message">Drag and drop or click to replace</p>
                            </div>
                          </div>
                          </div>
                          
                          </div>
                        <div v-else class="dropify-wrapper">
                          <div class="dropify-message"> 
                            <span class="file-icon"> 
                              <p>Drag and drop a file here or click</p>
                            </span>
                          </div>
                          <div class="dropify-loader" style="display: none;"></div>
                          <div class="dropify-errors-container">
                            <ul></ul>
                          </div>
                          <input type="file" ref="images" accept="image/*" class="form-control" @change="handleFileUpload($event)">
                          <button type="button" class="dropify-clear">Remove</button>
                          <div class="dropify-preview" style="display: none;">
                            <img :src="images_temporary" width="90%" height="80%"/>
                          <span class="dropify-render"></span>
                          <div class="dropify-infos">
                            <div class="dropify-infos-inner">
                              <p class="dropify-filename">
                              </p><p class="dropify-infos-message">Drag and drop or click to replace</p>
                            </div>
                          </div>
                          </div>
                          
                          </div>


                        <div v-if="errors.images">
                          <div
                            v-for="error in errors.images"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button
                        class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        "
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';


export default {
  name: "ArticleAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {      
      isLoading: false,      
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      forms: { aktif:1},
      images: '',
      images_temporary: null,
    };
  },
  watch: {},
  methods: {
    removeImages(){
      this.$refs.images.value="";
      this.images_temporary = null;
    },

    handleFileUpload(event) {
      this.images = event.target.files[0];
      this.images_temporary = URL.createObjectURL(this.images);
    },

    resetForm() {      
      this.forms.title = "";
      this.forms.link = "";
      this.forms.priority = "";
      this.removeImages();
    },

    backForm() {
      window.title.href = "/article";
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    submitData() {
      this.isLoading = true
      let formData = new FormData();
 
      formData.append("image", this.images);
      formData.append("title", this.forms.title);
      formData.append("link", this.forms.link);
      formData.append("priority", this.forms.priority);

      const baseURI = this.$settings.endPoint + "master-article/create";
      this.$http
        .post(baseURI, formData,{ timeout: 10000 })
        .then((response) => {
           this.isLoading = false
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            var params = this.$onRandom(response.data.datas.id);
            window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "ArticleController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
  
}
</style>