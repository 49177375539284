<template>
    <div>
        <div v-if="isLoading">
            <!--begin::Loading Content-->
            <div class="main-wrapper">
                <div class="page-wrapper full-page">
                    <div class="container">
                        <div class="row">
                            <!--begin::Card-->
                            <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                                <div class="overlay-layer card-rounded bg-opacity-5">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Card-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Loading Content-->
        </div>
        <div v-else>
            <div class="App">
                <div class="navbars-body section-campaign">
                    <div class="header">
                        <img @click="previousPage" src="/assets/images/icon_back.png" alt="Back Icon" />
                    </div>

                    <p class="title">{{ forms.name }}</p>
                   
                    <div v-if="check.status != '' && check.status != 'PENDING' && check.status != 'REJECTED' && check.status != 'BUKTI INSIGHT SUBMITTED'" class="card">
                        <div class="card-header" style="color:green;font-size:13pt;text-align:center !important;">
                            Selamat kamu lolos mengikuti campaign ini.
                        </div>
                    </div>
                    <div class="divider-box"></div>

                    <div v-if="check.status == 'APPROVED' || check.status == 'STORYLINE SUBMITTED'" class="card">
                        <div class="card-header" style="color:green;font-size:13pt;text-align:center !important;"><a :href="forms.whatsapp_group"><b>Klik disini untuk bergabung pada Whatsapp
                                Grup Campaign</b></a></div>
                    </div>
                    <div class="divider-box"><span></span></div>

                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table text-sm-start">
                                    <tbody>
                                        <tr v-if="check.status">
                                            <th class="bg-info">Status Project</th>
                                            <td>{{ check.status }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Tipe Project</th>
                                            <td>{{ forms.tipe_project }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Timeline</th>
                                            <td>{{ forms.start_reg }} {{ forms.end_reg }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Kuota</th>
                                            <td>{{ forms.kuota }} Partisipan</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Platform</th>
                                            <td>{{ forms.platform }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Category</th>
                                            <td>{{ forms.category }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="divider-box"></div>

                    <div v-if="check.status != '' && check.status != 'PENDING' && check.status != 'REJECTED'" class="card">
                        <div class="card-header text-center">Timeline Campaign</div>
                        <div class="card-body">
                            <div class="table-responsive">
                                
                                <table class="table text-sm-start">
                                    
                                    <tbody>
                                        <tr>
                                            <th class="bg-info">Submit Draft</th>
                                            <td>{{ forms.submit_draft }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Preview Draft</th>
                                            <td>{{ forms.preview_draft }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Upload Konten</th>
                                            <td>{{ forms.upload_konten }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Bukti Tayang</th>
                                            <td>{{ forms.bukti_tayang }}</td>
                                        </tr>
                                        <tr>
                                            <th class="bg-info">Bukti Insight</th>
                                            <td>{{ forms.bukti_insight }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="divider-box"></div>

                    

                    <div v-if="check.status == 'APPROVED' || check.status == 'STORYLINE SUBMITTED'"
                        class="header">
                        <div>Brief & Product Knowledge</div>
                        <a class="btn btn-info " @click.prevent="downloadKnowledge(forms.product_knowledge)">Download</a>
                    </div>
                    <div class="divider-box"><span></span></div>

                    <div class="card" style="white-space: pre-line;" v-if="!check.status || (check.status == '' && check.status == 'PENDING')">
                        <div class="card-header">Description</div>
                        <div class="card-body">
                            {{ forms.description }}
                        </div>
                    </div>
                    <div class="divider-box"><span></span></div>

                    <form v-if="check.status == 'APPROVED'" class="forms-register" @submit.prevent="submitStoryline"
                        method="POST">
                        <div class="card"> 
                            <!--  v-if="forms.is_storyline == 1"> <div class="card-header">
                                Silahkan tulis storyline yang akan digunakan
                            </div> -->
                            <div class="card-body">
                                Silahkan tulis storyline yang akan digunakan
                                <textarea class="form-control" v-model="storyline"></textarea>

                            </div>
                        </div>

                        <div class="divider-box"><span></span></div>

                        <div class="header">
                            <button :disabled="forms.product_knowledge === null || forms.product_knowledge === undefined" class="btn btn-success w-100" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>

                    <form v-if="check.status == 'STORYLINE SUBMITTED'" class="forms-register" @submit.prevent="submitCaption"
                        method="POST">
                        <div class="card">
                            <!-- <div class="card-header">
                                Silahkan tulis caption yang akan digunakan
                            </div> -->
                            <div class="card-body">
                                Silahkan tulis caption yang akan digunakan
                                <textarea class="form-control" v-model="caption"></textarea>

                            </div>
                            <div class="card-header">
                                <label>Upload draft konten</label>
                                <input type="file" @change="handleFileChangeKonten($event)" />

                            </div>

                        </div>

                        <div class="divider-box"><span></span></div>

                        <div class="header">
                            <button :disabled="forms.product_knowledge === null || forms.product_knowledge === undefined" class="btn btn-success w-100" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>

                    <div class="card" v-if="check.status == 'DRAFT PERLU REVISI'">
                        <!-- <div class="card-header">
                            
                        </div> -->
                        <div class="card-body">
                            Feedback untuk draft sebelumnya
                            <textarea class="form-control" v-model="check.feedback_draft"></textarea>

                        </div>
                    </div>

                    <form v-if="check.status == 'DRAFT PERLU REVISI'" class="forms-register" @submit.prevent="submitRevisi"
                        method="POST">
                        
                        <div class="divider-box"><span></span></div>
                        <div class="card">
                            <!-- <div class="card-header">
                                Silahkan tulis revisi caption 
                            </div> -->
                            <div class="card-body">
                                Silahkan tulis revisi caption 
                                <textarea class="form-control" v-model="caption"></textarea>

                            </div>
                            <div class="card-header">
                                <label>Upload revisi konten</label>
                                <input type="file" @change="handleFileChangeKonten($event)" />

                            </div>

                        </div>

                        <div class="divider-box"><span></span></div>

                        <div class="header">
                            <button class="btn btn-success w-100" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>

                    <form v-if="!check.status || (check.status == '' && check.status == 'PENDING')" class="forms-register"
                        @submit.prevent="submitData" method="POST">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-sm-6 d-grid gap-2">
                                        <button class="btn btn-success btn-block" type="button"
                                            @click.prevent="showDetail()">
                                            Detail
                                        </button>

                                    </div>
                                    <div class="col-sm-6 d-grid gap-2">
                                        <button class="btn btn-info btn-block" type="button" @click.prevent="showForm()">
                                            Form Data Diri
                                        </button>
                                    </div>

                                </div>


                            </div>


                            <div class="card-body">
                                <div v-if="detailShow">
                                    <label>Kriteria</label>
                                    <textarea disabled class="form-control" v-model="forms.kriteria"></textarea>
                                    <br />


                                    <div class="row">
                                        <div class="col-sm-6" v-if="forms.benefit_point !== 0">
                                            <label>Benefit Point</label>
                                            <input type="number" disabled class="form-control"
                                                v-model="forms.benefit_point">
                                        </div>
                                        <div class="col-sm-6" v-if="forms.benefit_fee !== 0">
                                            <label>Benefit Fee</label>
                                            <input type="number" disabled class="form-control" v-model="forms.benefit_fee">
                                        </div>
                                    </div>
                                    <br />

                                    <label>SOW</label>
                                    <textarea disabled class="form-control" v-model="forms.sow_campaign"></textarea>
                                    <br />

                                    <div class="header">
                                        <label>Timeline</label>
                                        {{ forms.start_reg }} - {{ forms.end_reg }}
                                    </div>
                                </div>

                                <div v-if="formShow">
                                    <div v-for="item in forms.question" :key="item">
                                        <label>{{ item.question }}</label>
                                        <div v-if="item.tipe === 'file'">
                                            <input type="file"
                                                @change="handleFileChange(item.campaign_question_id, $event)" />
                                        </div>

                                        <div v-else-if="item.tipe !== 'select' && item.tipe !== 'file'">
                                            <input class="form-control" :type="item.tipe" name='question'
                                                @change="updateDataForm(item.campaign_question_id, item.value)"
                                                v-model="item.value" />
                                        </div>
                                        <div v-else>
                                            <select name='select' @change="updateDataForm(item.campaign_question_id, item.value)"
                                                v-model="item.value" class="form-control">
                                                <option value="">Select an option</option>
                                                <option v-for="items in item.answer.split(',')" :value="items" :key="items">
                                                    {{
                                                        items }}
                                                </option>
                                            </select>
                                        </div>

                                        <br />

                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="divider-box"><span></span></div>

                        <div v-if="check.status == ''" class="header">
                            <label for="agreeCheckbox">
                                <input type="checkbox" id="agreeCheckbox" v-model="agreeTerms">
                                <span style="font-size:12px !important"> Saya menyetujui syarat & ketentuan pendaftaran
                                    campaign</span>
                            </label>

                            <button v-if="agreeTerms" class="btn btn-success" type="submit">
                                Daftar
                            </button>
                            <button v-else disabled class="btn btn-success" type="button">
                                Daftar
                            </button>
                        </div>
                    </form>

                    <div v-if="check.status == 'PENDING'" class="card">
                        <div class="card-header">
                            Profilmu sedang kami seleksi
                        </div>
                    </div>

                    <div v-if="check.status == 'REJECTED'" class="card">
                        <div class="card-header">
                            Mohon maaf kamu belum berhasil lolos pada campaign ini. Namun jangan sedih karena kamu masih
                            bisa mendaftar pada campaign lainnya.
                        </div>
                    </div>

                    <div v-if="check.status == 'DRAFT SUBMITTED' || check.status == 'REVISI SUBMITTED'" class="card">
                        <div class="card-header">
                            FIlE SEDANG DIREVIEW
                        </div>
                    </div>


                    <div v-if="check.status == 'CONTENT APPROVED'" class="card">
                        <div class="card-header">
                            Selamat draft kamu sudah Approved! Jangan lupa upload sesuai timeline yang sudah ditentukan!
                        </div>
                    </div>
                    <form v-if="check.status == 'CONTENT APPROVED'" class="forms-register" @submit.prevent="submitBuktiTayang"
                        method="POST">
                        
                        <div class="divider-box"><span></span></div>
                        <div class="card">
                            <div class="card-body">
                                Submit link konten disini
                                <textarea class="form-control" v-model="caption"></textarea>

                            </div>
                            <div class="card-header">
                                <label>Upload bukti tayang</label>
                                <input type="file" @change="handleFileChangeKonten($event)" />

                            </div>

                        </div>

                        <div class="divider-box"><span></span></div>

                        <div class="header">
                            <button class="btn btn-success w-100" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>

                    <form v-if="check.status == 'BUKTI TAYANG SUBMITTED'" class="forms-register" @submit.prevent="submitInsight"
                        method="POST">
                        
                        <div class="divider-box"><span></span></div>
                        <div class="card">
                            <div class="card-body">
                                <div v-for="item in forms.question" :key="item">
                                    <label>{{ item.question }}</label>
                                    <div v-if="item.tipe === 'file'">
                                        <input type="file"
                                            @change="handleFileChange(item.campaign_question_id, $event)" />
                                    </div>

                                    <div v-else-if="item.tipe !== 'select' && item.tipe !== 'file'">
                                        <input class="form-control" :type="item.tipe" name='question'
                                            @change="updateDataForm(item.campaign_question_id, item.value)"
                                            v-model="item.value" />
                                    </div>
                                    <div v-else>
                                        <select name='select' @change="updateDataForm(item.campaign_question_id,  item.value)"
                                            v-model="item.value" class="form-control">
                                            <option value="">Select an option</option>
                                            <option v-for="items in item.answer.split(',')" :value="items" :key="items">
                                                {{
                                                    items }}
                                            </option>
                                        </select>
                                    </div>

                                    <br />

                                </div>
                            </div>
                            <div class="card-body">

                                <label>Total Engagement</label>
                                <input required class="form-control" type="number" name='total_engagement' v-model="forms.total_engagement" />
                                <br />
                                <label>Total Impression</label>
                                <input required class="form-control" type="number" name='total_impression' v-model="forms.total_impression" />
                                <br />     
                                <label>Total Reach</label>
                                <input required class="form-control" type="number" name='reach' v-model="forms.reach" />
                                <br />                            
                                <label>Likes</label>
                                <input required class="form-control" type="number" name='likes' v-model="forms.likes" />
                                <br />
                                <label>Comment</label>
                                <input required class="form-control" type="number" name='comment' v-model="forms.comment" />
                                <br />
                                <label>Share</label>
                                <input required class="form-control" type="number" name='share' v-model="forms.share" />
                                <br />
                                <label>Save</label>
                                <input required class="form-control" type="number" name='save' v-model="forms.save" />
                                <br />
                                <label>Upload Insight</label>
                                <input required type="file" @change="handleFileChangeKontenMultiple($event)" multiple />

                            </div>

                        </div>

                        <div class="divider-box"><span></span></div>

                        <div class="header">
                            <button class="btn btn-success w-100" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>

                    <div v-if="check.status == 'BUKTI INSIGHT SUBMITTED'" class="card">
                        <div class="card-header" style="font-size:18px !important;color:green;text-align:center">
                            <b>Terima Kasih!</b><br/>
                            <b>Campaign Selesai</b>
                        </div>
                        <div class="card-body">
                            Campaign ini telah selesai, untuk mengikuti berbagai campaign lain. Silakan klik "Kembali ke Halaman Campaign"
                        </div>
                        <div class="card-footer">
                            <a class="btn btn-success w-100" href="/user/campaign">
                                Kembali ke Halaman Campaign 
                            </a>
                        </div>
                    </div>

                </div>

                <div class="divider-box"><span></span></div>
                <div class="divider-box"><span></span></div>
                <div class="divider-box"><span></span></div>


                <navbar-component page="campaign" />

            </div>


        </div>
    </div>
</template>
  
  
<script>
import navbarComponent from "@/components/_partials/navbars";
// import Slick from 'vue-slick';
// import 'slick-carousel/slick/slick.css';

export default {
    name: "CampaignDetail",
    components: {
        "navbar-component": navbarComponent,
        // Slick
    },
    data() {
        return {
            paramsid: 0,
            fileDraft: '',
            fileMultiple:[],
            caption: '',
            storyline: '',
            check: { 'status': '' },
            agreeTerms: false,
            formShow: false,
            detailShow: true,
            points: [],
            todayName: '',
            setting: [],
            code: "",
            locale: "",
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            isLoading: false,
            detailUser: [],
            errors: [],
            products: [],
            show: false,
            forms: [],
            detailForm: []
        };
    },
    watch: {},
    methods: {
        async downloadKnowledge(url) {
            if (url) {
                const response = await this.$http.get(url, { responseType: 'blob' });

                const contentType = response.headers['content-type'];
                const fileExtension = this.getFileExtension(contentType);

                const filename = `downloaded_file.${fileExtension || 'dat'}`; // Ekstensi default 'dat' jika tidak dapat diprediksi

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                link.setAttribute('download', filename);

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
            }

        },

        getFileExtension(contentType) {
            // Fungsi ini dapat diperluas untuk mendukung lebih banyak tipe file
            if (contentType.includes('pdf')) {
                return 'pdf';
            } else if (contentType.includes('image/jpeg')) {
                return 'jpg';
            } else if (contentType.includes('doc') || contentType.includes('word')) {
                return 'doc';
            }
            return null;
        },
        submitStoryline() {
            this.isLoading = true
            let formData = new FormData();
            formData.append("storyline", this.storyline);
            const baseURI = this.$settings.endPoint + "campaign-user/update-storyline/" + this.check.campaign_user_id;

            this.$http
                .post(baseURI, formData, { timeout: 200000 })
                .then((response) => {                   
                    
                    if (response.data.status === 200) {
                        this.loadData(this.paramsid);
                        this.isLoading = false
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },
        submitCaption() {
            this.isLoading = true
            let formData = new FormData();
            formData.append("caption", this.caption);
            formData.append("file_draft", this.fileDraft);
            const baseURI = this.$settings.endPoint + "campaign-user/update/" + this.check.campaign_user_id;

            this.$http
                .post(baseURI, formData, { timeout: 200000 })
                .then((response) => {                   
                    
                    if (response.data.status === 200) {
                        this.loadData(this.paramsid);
                        this.isLoading = false
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },

        submitRevisi() {
            this.isLoading = true
            let formData = new FormData();
            formData.append("revisi_caption", this.caption);
            formData.append("revisi_draft", this.fileDraft);

            const baseURI = this.$settings.endPoint + "campaign-user/update-revisi/" + this.check.campaign_user_id;

            this.$http
                .post(baseURI, formData, { timeout: 200000 })
                .then((response) => {                   
                    
                    if (response.data.status === 200) {
                        this.loadData(this.paramsid);
                        this.isLoading = false
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },

        submitBuktiTayang() {
            this.isLoading = true
            let formData = new FormData();
            formData.append("link_konten", this.caption);
            formData.append("bukti_tayang", this.fileDraft);
            const baseURI = this.$settings.endPoint + "campaign-user/update-bukti-tayang/" + this.check.campaign_user_id;

            this.$http
                .post(baseURI, formData, { timeout: 200000 })
                .then((response) => {                   
                    
                    if (response.data.status === 200) {
                        this.loadData(this.paramsid);
                        this.isLoading = false
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },

        submitInsight() {
            this.isLoading = true
            let formData = new FormData();

            formData.append("campaign_id", this.forms.campaign_id);           
            formData.append("total_engagement", this.forms.total_engagement);
            formData.append("total_impression", this.forms.total_impression);
            formData.append("likes", this.forms.likes);
            formData.append("reach", this.forms.reach);
            formData.append("comment", this.forms.comment);
            formData.append("share", this.forms.share);
            formData.append("save", this.forms.save);
            this.detailForm.forEach((item, index) => {
                formData.append(`answer[${index}][campaign_question_id]`, item.campaign_question_id);
                formData.append(`answer[${index}][answer]`, item.answer);
            });

            Array.from(this.fileMultiple).forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });

            const baseURI = this.$settings.endPoint + "campaign-user/update-insight/" + this.check.campaign_user_id;

            this.$http
                .post(baseURI, formData, { timeout: 200000 })
                .then((response) => {                   
                    
                    if (response.data.status === 200) {
                        // this.loadData(this.paramsid);
                        window.location.href = "/user/campaign-detail/" + this.paramsid+ "?status=200&msg=Successfully";
                        // this.isLoading = false
                        
                        // this.errors = [];
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },
        handleFileChangeKontenMultiple(event) {
            const file = event.target.files;
            this.fileMultiple = file;
        },

        handleFileChangeKonten(event) {
            const file = event.target.files[0];
            this.fileDraft = file;
        },
        handleFileChange(id, event) {
            const indexToDelete = this.detailForm.findIndex(item => item.campaign_question_id === id);

            if (indexToDelete !== -1) {
                this.detailForm.splice(indexToDelete, 1);
            }

            const file = event.target.files[0];

            this.detailForm.push({
                campaign_question_id: id,
                answer: file,
            });
        },

        updateDataForm(id, newValue) {
            // console.log(id);
            // console.log(newValue);
            // this.detailForm.id = newValue;
            const indexToDelete = this.detailForm.findIndex(item => item.campaign_question_id === id);

            if (indexToDelete !== -1) {
                this.detailForm.splice(indexToDelete, 1);
            }

            this.detailForm.push({
                campaign_question_id: id,
                answer: newValue,
            });
        },

        showDetail() {
            this.formShow = false;
            this.detailShow = true;
        },
        showForm() {
            this.formShow = true;
            this.detailShow = false;
        },
        loadData(id) {
            console.log(this.detailUser)
            if (this.detailUser.jenis_kelamin === null || this.detailUser.jenis_kelamin === undefined) {
                //  Route::get('/check-reg/{user_id}', 'UserController@checkReg');
                const baseURI = this.$settings.endPoint + "pengguna/check-reg/" + this.detailUser.user_id;
                // this.isLoading = true;
                return this.$http
                    .get(
                        baseURI,
                        { timeout: 100000 }
                    )
                    .then((response) => {
                        this.isLoading = false;
                        let cek = response.data.datas;
                        if (cek != 'ok') {
                            this.$swal({
                                title: 'Warning',
                                text: 'Mohon lengkapi terlebih dahulu profile anda',
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                            }).then(() => {
                                window.location.href = "/user/profile/edit";
                            });

                        }

                    }).catch(error => {

                        if (error.code == "ECONNABORTED") {
                            this.eror(this.$t("check_internet_connection_txt"));

                        } else {

                            if (error.response) {
                                if (error.response.status === 422) {
                                    this.errors = error.response.data.errors;
                                    this.resultError(error.response.data.errors);
                                } else if (error.response.status === 500) {
                                    this.$router.push('/server-error');
                                } else if (error.response.status === 401) {
                                    this.$router.push("/authorized-error");
                                } else {
                                    this.$router.push('/page-not-found');
                                }
                            }

                        }
                    });

            }

            this.isLoading = true
            const baseURI = this.$settings.endPoint + "campaign/detail-user/" + id;
            return this.$http.get(baseURI, { timeout: 100000 }).then((response) => {
                this.isLoading = false
                this.forms = response.data.datas;
                this.detailAll = response.data.datas.question;

                if (response.data.datas.user[0]) {
                    this.check = response.data.datas.user[0];
                }

                if (response.data.datas.answer.length > 0) {
                    this.detailAll.forEach((item1) => {
                        let answerObj = response.data.datas.answer.find((item2) => item2.campaign_question_id === item1.campaign_question_id);
                        item1.value = answerObj ? answerObj.answer : item1.answer;
                    });
                    response.data.datas.answer.forEach((item) => {
                        this.detailForm.push({
                            campaign_question_id: item.campaign_question_id,
                            answer: item.answer,
                        });
                    });
                    
                }

                this.selectedCategories = response.data.datas.category.split(',');
                this.selectedProject = response.data.datas.tipe_project.split(',');
                this.selectedPlatform = response.data.datas.platform.split(',');


            }).catch(error => {
                this.isLoading = false;
                if (error.code == "ECONNABORTED") {
                    this.error("Please Check Your Internet Connection");
                } else {

                    if (error.response) {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            this.resultError(error.response.data.errors);
                        } else if (error.response.status === 500) {
                            this.error(error.response.data.errors.message);
                        } else {
                            this.$router.push("/page-not-found");
                        }
                    }

                }


            });
        },

        submitData() {
            this.isLoading = true
            let formData = new FormData();

            formData.append("campaign_id", this.forms.campaign_id);
            this.detailForm.forEach((item, index) => {
                formData.append(`answer[${index}][campaign_question_id]`, item.campaign_question_id);
                formData.append(`answer[${index}][answer]`, item.answer);
            });
            this.isLoading = true
            const baseURI = this.$settings.endPoint + "campaign-user/create";

            this.$http
                .post(baseURI, formData, { timeout: 100000 })
                .then((response) => {
                    this.loadData(this.paramsid);
                    this.isLoading = false
                    if (response.data.status === 200) {
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },

        previousPage() {
            this.$router.go(-1);
            //window.history.back();
        },
        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
        },
        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },
        refreshCaptchaCode() {
            this.$refs.captcha.refreshCaptcha();
        },
        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.loading();

        // hidden
        // var params = this.$onBehind(this.$route.params.id);
        // this.paramsid = params[0]
        this.paramsid = this.$route.params.id;
        this.fetchIt();

        // this.cek_message(this.$route.query.status, this.$route.query.msg);
        this.loadData(this.paramsid);
        

    },
};
</script>
<style scoped>
@import '../_partials/partial.css';

.detail-info {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    color: #295985;
}

.detail-poin {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #092641;
}

.detail-available {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #0c862b;
}

.section-campaign {
    padding: 16px 24px;
}

.section-campaign .title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #295985;
    margin: 0px;
    padding: 0px 22px 0px 22px;
}

.section-campaign .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.section-campaign .header img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
    margin: 10px;

}

.product {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 5px;
    text-align: left;
    width: 45%;
    /* Adjust the width as needed */
    font-size: 13pt;
}

.product p {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #295985;
    margin: 5px;
    padding: 0px 22px 0px 22px;
}

.product img {
    max-width: 100%;
    height: 150px;
}

.product-name {
    font-size: 15px;
    color: #333;
    margin: 5px;
}

.product-stock {
    color: #2d2a43;
    margin: 5px 2px;
    font-size: 12px;
    line-height: 10;
}

.product-description {
    color: #666;
    line-height: 1.4;
}

.divider-box {
    margin-top: 5px;
    margin-bottom: 10px;
}
</style>