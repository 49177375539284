<template>
  <div>
    <li v-for="(item, index) in items" :key="index">
      
    </li>
    <div class='articles' v-for="(item, index) in rows" :key="index">
      <a :href="item.link" key="" target='_blank' rel='noreferrer'>
        <div class='item'>
          <img :src="item.image" alt="Artikel" />
          <div class='content'>
            <div class='see-full'>
              <div>Selengkapnya</div>
              <img src="/assets/images/arrow_purple.png" alt="Arrow" />
            </div>
            <div class='article-title'>
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
      </a>
    </div>

  </div>
</template>


<script>

export default {
  name: 'articleUser',
  props: {
  },
  components: {
  },
  data() {
    return {
      rows: {}
    }
  },
  watch: {

  },
  methods: {
    error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    loadItems() {
      const baseURI = this.$settings.endPoint + "get-article";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=10&page=1&sort_field=&sort_type=&title=&link=&priority=`,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas;
        }).catch(error => {

        
    console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },


  },
  events: {

  },
  created: function () {

  },
  mounted() {
    this.loadItems();

  }

}
</script>
<style scoped>@import 'partial.css';</style>