import Community from "@/components/master/community/Community.vue";
import CommunityAdd from "@/components/master/community/CommunityAdd.vue";
import CommunityDetail from "@/components/master/community/CommunityDetail.vue";
import CommunityEdit from "@/components/master/community/CommunityEdit.vue";
import CommunityUpload from "@/components/master/community/CommunityUpload.vue";

let routesCommunity= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Community",
        component: Community
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "CommunityAdd",
        component: CommunityAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "CommunityEdit",
        component: CommunityEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "CommunityDetail",
        component: CommunityDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "CommunityUpload",
        component: CommunityUpload
      },
    
]

export default routesCommunity
  