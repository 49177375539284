import EventType from "@/components/master/event-type/EventType.vue";
import EventTypeAdd from "@/components/master/event-type/EventTypeAdd.vue";
import EventTypeDetail from "@/components/master/event-type/EventTypeDetail.vue";
import EventTypeEdit from "@/components/master/event-type/EventTypeEdit.vue";
import EventTypeUpload from "@/components/master/event-type/EventTypeUpload.vue";

let routesEventType= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "EventType",
        component: EventType
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "EventTypeAdd",
        component: EventTypeAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "EventTypeEdit",
        component: EventTypeEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "EventTypeDetail",
        component: EventTypeDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "EventTypeUpload",
        component: EventTypeUpload
      },
    
]

export default routesEventType
  