<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="my-component">

        <!-- </div> class="main-wrapper"> -->
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <div class='parent-register'>
          <div class='section-help'>
            <div class="header">
              <a target="_blank" 
                href="https://wa.me/6287831751586?text=Mohon%20bantuan%20atas%20kendala%20yang%20saya%20alami%20yaitu:"><img
                  src="/assets/images/operator.png" style="width:40px;height:auto;"><br/>
                CS Support</a>
            </div>
          </div>
          <div class='section-register'>
            <img src="/assets/images/logo.png" alt="Logo" />

            <form @submit.prevent="submitData" method="POST" autocomplete="off">
              <input type="text" id='name' name="name" placeholder="Nama Lengkap" v-model="forms.name" />
              <input type="email" id='email' name="email" placeholder="Email" v-model="forms.email" />
              <input type="tel" id='phone' name="phone" placeholder="No. Handphone" v-model="forms.phone" />
              <select id="selectOption" v-model="selectedOption" placeholder="Community">
                <!-- Gunakan v-for untuk membuat opsi dropdown dari array 'opsi' -->
                <option disabled value="" selected> Pilih Community </option>
                <option v-for="option in options" :key="option.id" :value="option.id">
                  {{ option.community }}
                </option>
              </select>
              <input type="password" id='password' name="password" placeholder="Kata Sandi" v-model="forms.password" />
              <input type="password" id='password_conf' name="password_conf" placeholder="Ulangi Kata Sandi"
                v-model="forms.password_konf" />

              <!-- { errorMessage && (<div class="error">{errorMessage}</div>) } -->
              <input type="submit" value="Daftar" />
            </form>

            <div class='navigation'>              
              Sudah Punya Akun?
              <a href="/login"><b> Masuk disini</b></a>
            </div>
          </div>
        </div>
        <div class='footer-helper'>
          <information-component classMenu="InformationIndex"></information-component>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
// import { setSessionChainToken, setAuthLang, setSessionCtToken,setSessionFpToken,setSessionGtToken} from "@/middleware/setBackendSessions";
// import { setAuthToken, getUserInfo } from "@/middleware/auth";
import informationComponent from "@/components/_partials/information";
// import VueCaptcha from "vue-captcha-code";

export default {
  name: "UserRegistrasi",
  components: {
    "information-component": informationComponent
    // VueCaptcha,
  },
  data() {
    return {
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      langs: ["id", "en"],
      forms: { name: "", password: "" },
      selectedOption: '',
      options: []
    };
  },
  watch: {},
  methods: {
    loadItems() {
      const baseURI = this.$settings.endPoint + "get-community";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.options = response.data.datas;
        }).catch(error => {


          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    handleChange(code) {
      this.code = code;
    },
    // refreshCaptchaCode() {
    //   this.$refs.captcha.refreshCaptcha();
    // },
    makeOffer() {

    },
    submitData() {

      if (this.forms.name.trim()) {
        let formData = new FormData();
        formData.append("name", this.forms.name.trim());
        formData.append("email", this.forms.email);
        formData.append("phone", this.forms.phone);
        formData.append("password", this.forms.password);
        formData.append("password_konf", this.forms.password_konf);
        formData.append("community_id", this.selectedOption);

        const baseURI = this.$settings.endPoint + "auth/register";

        this.$http
          .post(baseURI, formData)
          .then((response) => {
            this.loading();
            if (response.data.status === 200) {
              // setAuthToken(response.data.datas.access_token);

              // var userInfo = getUserInfo(response.data.datas.access_token);
              // localStorage.setItem("userModels", JSON.stringify(userInfo));
              // var userModel=JSON.parse(localStorage.getItem("userModels"));


              window.location.href = "/login";

            } else {
              // this.forms.captchaCode = "";
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
              // this.refreshCaptchaCode();
            }
          })
          .catch((error) => {
            this.loading();
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
            // this.forms.captchaCode = "";
            // this.refreshCaptchaCode();
          });
      } else {
        this.error("email required");
      }

    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  computed: {
    // Dapatkan label dari opsi yang dipilih
    selectedOptionLabel() {
      const selectedOption = this.options.find(option => option.id === this.selectedOption);
      return selectedOption ? selectedOption.community : '';
    },
    // Dapatkan value dari opsi yang dipilih
    selectedOptionValue() {
      return this.selectedOption;
    },
  },
  created: function () { },
  mounted() {
    this.loading();
    this.loadItems();
  },
};
</script>
<style scoped>@import '_partials/partial.css';</style>