// import Vue from "vue";
// import VueI18n from "vue-i18n";

// Vue.use(VueI18n);

const messages = {
    "en": {
        ontime:"Ontime",
        claim:"Claim",
        accident:"Accident",
        teguran:"Lessons",
        hasilTest:"Result Test",
        trafficViolation:"Traffic Violation",
        propertyDamage:"Property Damage",
        workingDays:"Working Days",
        txtPermissionRole:"Permission Master Role",
        txtPermission:"Permission Master",
        txtRoles:"Roles Master",
        txtMessages:"Message",
        txtSubject:"Subject",
        pageContactUs:"Contact Us Page",
        txtContactUs:"Contact Us",
        MainTxt:"Main",
        SupportTxt:"Support",
        CreateTxt:"Create",
        UpdateTxt:"Update",
        ReadTxt:"Read",
        DeleteTxt:"Delete",
        UploadTxt:"Upload",
        YesTxt:"Yes",
        NoTxt:"No",
        OnTxt:"ON",
        OffTxt:"OFF",
        DashboardMonitoringTxt:"Dashboard Monitoring",
        TripAlertTxt:"Trip Alert",
        GateAlertTxt:"Gate Alert",
        DocAlertTxt:"Doc Alert",
        ServiceAlertTxt:"Service Alert",
        serviceTxt:"Service",
        OntimeAnalysisTxt:"Ontime Analysis",
        AdministrationTxt:"Administration",
        PermissionTxt:"Permission",
        PermissionAdd:"Add Permission",
        PermissionEdit:"Edit Permission",
        PermissionAddRole:"Add Permission Role",
        PermissionEditRole:"Edit Permission Role",
        loadingTxt:"Loading",
        submitFormTxt:"Submit Form",
        submitOrderTxt:"Save Order",
        resetFormTxt:"Reset Form",
        ChangePasswordTxt:"Change Password Page",
        changePassword:"Change Password",
        profileTxt:"User Profile Page",
        dashboardTxt : "Welcome to Chain",
        dashboardMenu : "Dashboard",
        autorizedErrorTxt : "Oopps!! The page not autorized for you",
        pageErrorTxt : "Oopps!! The page you were looking for doesn't exist",
        serverErrorTxt : "Oopps!! There wan an error. Please try agin later",
        
        regionTxt : "Region", 
        usernameTxt : "Username",
        emailAddress : "Email Address",
        descriptionTxt : "Description",
        roleNameTxt : "Role Name",
        commodityCodeTxt : "Commodity Code",
        transactionData:"Transaction",
        permissionNameTxt : "Permission Name",
        txtMyProfile:"Profile",
        month:"Month",
        year:"Year",
        txtUserSetting:"User Setting",
        txtUsers:"Users",
        txtLanguage:"Language",
        txtSignOut:"Sign Out",
        txtNotification:"Notification",
        txtLogin:"Login",
        txtLogin2:"Please sign in to continue",
        pageForgotPassword:"Forgot Password Page",
        pageResetPassword:"Reset Password Page",
        nameTxt : "Name",
        roleTxt : "Role",
        regionAccessAdd:"Add Region",
        regionAccessEdit:"Edit Region",
        packageAccessAdd:"Add Package",
        packageAccessEdit:"Edit Package",

        postalCodeAccessAdd:"Add Postal Code",
        postalCodeAccessEdit:"Edit Postal Code",

        regionCode:"Region Code",
        packageCode:"Package Code",
        packageName:"Package Name",
        countryTxt:"Country",
        provinceTxt:"Province",
        cityTxt:"City",
        masterData:"Master Data",
        cityTypeTxt:"City Type",
        districtTxt:"District",
        subDistrictTxt:"Sub District",
        postalCodeTxt:"Postal Code",
        password:"Password",
        newPassword:"New Password",
        passwordConfirm:"Password Confirmation",
        domicileAddress:"Domicile Address",
        idCardAddress:"ID Card Address",
        forgotPassword:"Forgot Password",
        rememberPassword:"Remember Password",
        resetPassword:"Reset Password",
        captchaCode:"Captcha Code",
        dashboard:"Dashboard",
        areYouSure:"Are you sure ?",
        yourData: "Submit data.",
        yourDataDelete: "You want to delete data",
        yourDataSend:"You want to send selected data",
        rejectResubmit: "This order has been rejected, are you sure want to resubmit ?",
        yourDataCancel:"You want to cancel selected data",
        settings: "Settings",
        wrongCaptchaCode:"wrong Captcha Code",
        mainMenu:"Main Menu",
        page404Desc:"we couldn't find the page you are looking for",
        page500Desc:"Our server is experiencing problems, please try again in a few moments.",
        goHOmeDesc:"Go Home",
        userSettings:"User Settings",
        commodityTxt:"Commodity",
        locationTxt:"Location",
        locationCodeTxt:"Location Code",
        locationAdd:"Add Location",
        locationNameTxt:"Location Name",
        locationEdit:"Edit Location",
        locationContactName:"Contact Name",
        locationPhoneNumber:"Phone Number",
        systemCodeTxt:"System Code",
        systemCodeAdd:"Add System Code",
        systemCodeEdit:"Edit System Code",
        skuCodeTxt:"SKU Code",
        skuDescriptionTxt:"SKU Description",
        skuLongDescriptionTxt:"SKU Long Description",
        skuAdd:"Add SKU",
        skuEdit:"Edit SKU",
        name:"Name",

        stopLocationTxt : "Stop Location",
        stopLocationAccessAdd:"Add Stop Location",
        stopLocationAccessEdit:"Edit Stop Location",

        driverTxt : "Driver",
        driverAccessAdd:"Add Driver",
        driverAccessEdit:"Edit Driver",

        driver_id:"Driver ID",
        driver_name:"Driver Name",
        branch_id:"Branch ID",
        site_name:"Site Name",
        project_name:"Project Name",
        driver_position:"Driver Position",
        hp1:"HP 1",
        hp2:"HP 2",
        wa_hp1:"WA HP 1",
        sap_code:"SAP Code",
        driver_status:"Driver Status",
        status_remark:"Status Remark",
        join_date:"Join Date",
        nickname:"Nickname",
        brith_place:"Birth Place",
        brith_date:"Birth Date",
        sim_type:"SIM Type",
        sim_expire_date:"SIM Expire Date",
        ethnic:"Ethnic",


        truckTxt : "Truck",
        truckAccessAdd:"Add Truck",
        truckAccessEdit:"Edit Truck",
        
        typeDocumentTxt : "Type Document",
        typeDocumentAccessAdd:"Add Type Document",
        typeDocumentAccessEdit:"Edit Type Document",

        documentTxt : "Document",
        documentAccessAdd:"Add Document",
        documentAccessEdit:"Edit Document",
        sameAsId:"Same As ID Card",
        statusTxt : "Status",
        statusAccessAdd:"Add Status",
        statusAccessEdit:"Edit Status",
        relationShip:"Relationship",
        branchTxt : "Branch",
        branchAccessAdd:"Add Branch",
        branchAccessEdit:"Edit Branch",
        branchAccessDetail:"Detail Branch",
        branch_name:"Branch Name",
        branch_province:"Branch Provinsi",
        branch_city:"Branch City",
        branch_region:"Branch Region",
        branch_address:"Branch Address",
        branch_latitude:"Branch Latitude",

        ac_category: "AC Category",
        box_category: "Box Category",
        vehicle_no: "Vehicle No",
        carrier: "Carrier",
        device_id:"Device ID",
        engine_number: "Engine No",
        gps_vendor: "GPS Vendor",
        km_limit: "KM Limit",
        manufacture_year: "Manufacture Year",
        name_of_owner: "Name of Owner",
        ownership: "Ownership",
        project: "Project",
        purchased_year: "Purchase Year",
        remark: "Remark",
        truck_brand: "Truck Brand",
        truck_model: "Truck Model",
        truck_status: "Truck Status",
        type_truck: "Vehicle Type",
        unit_allocation: "Unit Alocation",
        last_service:"Last Service",
        last_km_service:"Last KM Service",
        vin: "Vin",

        division:"Division",
        divisionCode:"Division Code",
        divisionDesc:"Division Description",
        wrongPasswordConfirm:"Password confirmation does not match",
        userAccess:"User Access",
        userAccessList:"List User Access",
        userAccessDetail:"Detail User Access",
        userAccessEdit:"Edit User Access",
        packageTxt:"Package",
        backMess:"Back",
        editMess:"Edit",
        deleteMess:"Delete",
        submitMess:"Submit",
        createNew:"Create New Data",
        downloadData:"Download Data",
        uploadData:"Upload Data",
        userAccessAdd:"Add New User Access",
        roleAdd:"Add Role",
        roleEdit:"Edit Role",
        sequenceTxt : "Sequence",
        valueTxt : "Value",
        commodityAdd:"Add Commodity",
        commodityEdit:"Edit Commodity",

        FakturPajakTxt:"Tax Invoice",
        FakturPajakAdd: "Add Tax Invoice",
        FakturPajakDetail: "Detail Tax Invoice",
        FakturPajakEdit: "Edit Tax Invoice",

        images_txt:"Images",

        customerTxt:"Master Customer",
        customerAddTxt:"Create New Customer",
        location_reference_number_txt:"Location Reference Number",
        stateTxt:"State",
        customerDetailTxt:"Detail Customer",
        customerEditTxt:"Edit Customer",
        coulmn_issue_txt:"Column Issue",
        description_txt:"Description Issue",
        user_type_txt:"User Type",
        date_range_txt:"Date Range",
        search_txt:"Search",
        check_internet_connection_txt:"Please Check Your Internet Connection",
        day_order_history_txt:"Days Order History",
        year_order_history_txt:"12 Months Order History",
        day_truck_order_history_txt:"Days Truck Type  Order History",
        year_truck_order_history_txt:"12 Months Truck Type Order History",
        no_data_available_txt:"No Data Available",
        day_30_order_history_txt:"30 Days Order History",
        day_30_truck_order_history_txt:"30 Days Truck Type Order History",
        transport_orders_txt:"Transport Order",
        transport_txt:"Transport",
        orders_txt:"Orders",
        order_no_txt:"Order No",
        order_date_txt:"Order Date",
        order_status_txt:"Order Status",
        send_order_txt:"Submit Order",
        cancel_order_txt:"Cancel Order",
        cancel_order_costumer_txt:"Cancel From Costumer",
        cancel_reason_txt:"Cancel Reason",
        pick_name_txt:"Pick Name",
        dest_name_txt:"Dest Name",
        action_txt:"Action",
        submit_time_txt:"Submit Time",
        transport_order_template_description_txt:"Order Template Name",
        tonnage_txt:"Tonnage",
        cubic_txt:"Cubic",
        pickup_plan_txt:"Pickup Plan",
        new_pickup_plan_txt:"New Pickup Plan",
        dest_plan_txt:"Dest Plan",
        new_dest_plan_txt:"New Dest Plan",
        accept_order_txt:"Accept Order",
        reject_order_txt:"Reject Order",
        postpone_order_txt:"Postpone Order",
        postpone_time_txt:"Postpone Time",
        hide_map_txt:"Hide Map",
        show_map_txt:"Show Map",
        tracking_status_txt:"Tracking Status",
        audit_info_txt:"Audit Info",
        detail_transport_order_txt:"Detail Transport Order",
        edit_transport_order_txt:"Edit Transport Order",
        create_by_txt:"Created By",
        create_at_txt:"Created At",
        update_by_txt:"Updated By",
        update_at_txt:"Updated At",
        integration_status_txt:"Integration Status",
        integration_description_txt:"Integration Description",
        load_id_txt:"Load ID",
        tms_id_txt:"TMS ID",
        status_tms_txt:"TMS Status",
        template_order_txt:"Template Order",
        remarks_txt:"Remarks",
        shipwith_ref_txt:"Shipwith ref",
        pickup_txt:"Pickup",
        pickup_location_txt:"Pickup Location",
        latitude_txt:"Latitude",
        longitude_txt:"Longitude",
        destination_txt:"Destination",
        destination_location_txt:"Destination Location",
        destination_plan_txt:"Destination Plan",
        maps_txt:"Maps",
        detail_txt:"Detail",
        sku_txt:"SKU",
        sku_description_txt:"SKU Description",
        qty_txt:"QTY",
        package_type_txt:"Package Type",
        lading_type_txt:"Lading Type",
        lading_length_txt:"Lading Length",
        lading_width_txt:"Lading Width",
        lading_height_txt:"Lading Height",
        volume_txt:"Volume",
        weight_txt:"Weight",
        net_weight_txt:"Net Weight",
        lading_qty_txt:"Lading Qty",
        close_txt:"Close",
        ct_operation_performance_txt:"CT Operator Performance",
        reject_time_txt:"Reject Time",
        total_hours_txt:"Total Hours",
        gender:"Gender",
        edit_order_txt:"Edit Order",
        reason_txt:"Reason",
        reason_category_txt:"Reason",
        save_change_txt:"Save Change",
        reject_category_txt:"Reject Category",
        reject_description_txt:"Reject Description",
        type_reject_description_txt:"Type Description",
        pools_count_txt:"Pool Total",
        branch_address_txt:"Branch Address",
        branch_region_txt:"Branch Region",
        branch_city_txt:"Branch City",
        branch_province_txt:"Branch Province",
        branch_name_txt:"Branch Name",
        branch_longitude:"Branch Longitude",
        txtpilihSatu:"Please select one !",
        branch_poscode:"Branch Postalcode",
        pool_name:"Pool Name",
        pool_province:"Pool Province",
        pool_city:"Pool City",
        pool_postalcode:"Pool Postal Code",
        pool_latitude:"Pool Latitude",
        pool_longitude:"Pool Longitude",
        pool_address:"Pool Address",
        pool_txt:"Pool",
        txtUserMapping:"User Mapping",
        txtUserMappingCreate:"Create User Mapping",
        txtUserMappingDetail:"Detail User Mapping",
        user_choose_txt:"Choose User",
        pool_choose_txt:"Choose Pool",
        audit_info:"Audit  Info",
        id_pemotong:"ID Pemotong",
        name_pemotong:"Nama Pemotong",
        id_dipotong:"ID Dipotong",
        nama_dipotong:"Nama Dipotong",
        pasal:"Pasal",
        kode_objek_pajak:"Kode Objek Pajak",
        no_bukti_potong:"No Bukti Potong",
        tgl_bukti_potong:"Tgl Bukti Potong",
        pph_dipotong:"PPH Dipotong",
        jumlah_bruto:"Jumlah Bruto",
        keterangan:"Keterangan",
        file:"File",
        download_file:"Download File",
        branch_phone:"Branch Phone",
        branch_phone2:"Branch Phone 2",
        parking:"Parking",
        kir:"KIR",
        corrective_maintenance:"Corrective Maintenance",
        preventive_maintenance:"Preventive Maintenance",
        truck_type:"Truck Type",
        date:"Date",
        time:"Time",
        permit_status:"Permit Status",
        date_time:"Date Time",
        truck_record_by_permit_status:"Truck Record By Permit Status",
        truck_record_by_permit:"Truck Record By Permit",
        more_record:"More Records",
        truck_record_by_type:"Truck Record By Type",
        period_from:"Period From",
        period_to:"Period To",
        period:"Period",
        sampai:"To",
        result_txt:"Result",
        in_out_txt:"In / Out",
        from:"From",
        all:"All",
        pool_location:"Pool Location",
        yes_txt:"Yes",
        no_txt:"No",
        transaction_date:"Transaction Date",
        needs:"Needs",
        km:"KM",
        muatan:"Cargo",
        stnk:"STNK",
        ktp:"KTP",
        clear:"Clear",
        load_id:"Load ID",
        load_group:"Load Group",
        last_order:"Last Order",
        handphone:"Handphone",
        sim:"SIM",
        surveyor:"Surveyor",
        km_gps:"KM Gps",
        km_real:"KM Real",
        km_service:"KM Service",
        status_remarks:"Status Remarks",
        in_to_pool:"In To Pool",
        exit_from_pool:"Exit From Pool",
        enter_the_pool: "Enter The Pool",
        nomor:"No",
        shipment_number:"Shipment Number",
        customer:"Customer",
        route:"Route",
        equipment:"Equipment",
        dongkrak:"Dongkrak",
        spion_kanan:"Spion Kanan",
        spion_kiri:"Spion Kiri",
        ban_stip:"Ban Stip",
        kotak_p3k:"Kotak P3K",
        ban_serep:"Ban Serep",
        radio_tape:"Radio Tape",
        speedometer:"Speedometer",
        pick_some_type_truck:"Pick Some Type Truck",
        truck_selected:"Truck Selected",
        kunci_roda:"Kunci Roda",
        pick_some_pool_name:"Pick Some Pool Name",
        branch_selected:"Branch Selected",
        data_selected:"Data selected",
        news_portal_txt:"News Portal",
        news_portal_create_txt:"Create News Portal",
        news_from_date:"Date News From",
        news_to_date:"Date News To",
        title:"Title",
        brief:"Brief",
        active_txt:"Active",
        deactive_txt:"Deactive",
        news_detail:"News Detail",
        drop_and_drop_file:"Drag and drop or click to replace",
        something_wrong:"something wrong appended",
        remove_txt:"Remove",

        no_select_data: "There is no selected row ",
        news_portal_detail_txt:"Detail News Portal",
        news_portal_edit_txt:"Edit News Portal",

        driver_attendance_txt: "Driver Attendance",

        basic:"Basic",
        requirements:"Requirements",
        historical_delivery:"Historical Delivery",
        training:"Training",
        bak:"BAK",
        driver_history_status:"Driver History Status",
        deposit:"Deposit",
        sibiling_info:"Sibling Info",
        recruitment_info:"Recruitment Info",
        name_of_id_card:"Name Of ID Card",
        position:"Position",
        site_pool:"Site / Pool",
        assigned_vehicle_no:"Assigned Vehicle No",
        marital_status:"Marital status",

        number_of_childer:"Number Of Children",
        id_card_number:"ID Card Number",
        education:"Education",
        religion:"Religion",
        place_dob:"Place & DOB",
        age:"Age",
        joined_date:"Joined Date",
        working_time:"Working Time",
        end_of_contract:"End Of Contract",
        stop_date:"Stop Date", 
        stop_reason:"Stop Reason",
        stop_remark:"Stop Remark",
        vendor:"Vendor",
        vendorAdd:"Create Vendor",
        vendorDetail:"Detail Vendor",
        vendorEdit:"Edit Vendor",
        vendorUpload:"Upload Vendor",
        noted:"Noted",
        changeRoleName:"Change Role Name to ",
        create_proforma_invoice:"Create Proforma Invoice",
        validateOrderDate:"Order Date is Smaller Than Current Time",
        validatePickDate:"Pick Plan is Smaller Than Current Time",
        validateDestDate:"Dest Plan is Smaller Than Current Time",
        cancelConfirmation:"You Have selected {total} Shipment to be cancelled Are you sure ?, once cancelled it cannot be undone",
        cancel_desc_txt:"Cancel Description",
        new_order_number_txt:"New Order Number",
        old_order_number_txt:"Old Order Number",
        pick_date_txt:"Pickup Date",

        route_master_code:"Route Master Code",
        route_name:"Route Name",
        route_start:"Initial Route",
        coordinate_start:"Initial Coordinate",
        route_end:"Final Route",
        coordinate_end:"Final Coordinate",
        distance:"Distance",
        route_add:"Add Route",

        dateUpdate:"Update Date",
        sendToBlujay:"Send To Blujay",
        sendToSF:"Send To SF",

        billableMethodDateRate:"Which date should be used when determining the rate? ",
        billableMethodSkuRating:"Should shipment line items with the same SKUs be combined for rating?",
        billableMethodSkuRate:"Should an overall shipment rate be calculated in addition to the SKU rates?",
    },
    "id": {
        changeRoleName:"Change Role Name to ",
        noted:"Noted",
        vendorUpload:"Upload Vendor",
        vendorEdit:"Edit Vendor",
        vendorDetail:"Detail Vendor",
        vendorAdd:"Create Vendor",
        vendor:"Vendor",
        ontime:"Ontime",
        claim:"Mengeklaim",
        accident:"Kecelakaan",
        trafficViolation:"Pelanggaran Lalin",
        propertyDamage:"Kerusakan Property",
        workingDays:"Hari Bekerja",
        domicileAddress:"Alamat Domisili",
        idCardAddress:"Alamat KTP",
        news_portal_edit_txt:"Rubah Portal Berita",
        news_portal_detail_txt:"Detail Portal Berita",
        remove_txt:"Hapus",
        something_wrong:"ada sesuatu yang salah",
        drop_and_drop_file:"Seret dan lepas atau klik untuk mencari file",
        news_detail:"Detail Berita",
        deactive_txt:"Tidak Aktif",
        active_txt:"Aktif",
        brief:"Ringkasan",
        title:"Judul",
        relationShip:"Hubungan",
        sameAsId:"Sama dengan KTP",
        news_to_date:"Tanggal Berita Selesai",
        news_from_date:"Tanggal Berita Mulai",
        news_portal_txt:"Portal Berita",
        news_portal_create_txt:"Buat Portal Berita",
        data_selected:"Data dipilih",
        branch_selected:"Branch Selected",
        pick_some_pool_name:"Pilih Beberapa Nama Pool",
        kunci_roda:"Kunci Roda",
        truck_selected:"Truck Selected",
        pick_some_type_truck:"Pilih Beberapa Jenis Truk",
        speedometer:"Speedometer",
        ban_serep:"Ban Serep",
        radio_tape:"Radio Tape",
        ban_stip:"Ban Stip",
        kotak_p3k:"Kotak P3K",
        spion_kiri:"Spion Kiri",
        spion_kanan:"Spion Kanan",
        dongkrak:"Dongkrak",
        equipment:"Peralatan",
        route:"Rute",
        customer:"Pelanggan",
        shipment_number:"Shipment Number",
        nomor:"No",
        in_to_pool:"In To Pool",
        exit_from_pool:"Exit From Pool",
        enter_the_pool: "Enter The Pool",
        status_remarks:"Status Remarks",
        km_service:"KM Service",
        km_real:"KM Real",
        km_gps:"KM Gps",
        surveyor:"Surveyor",
        sim:"SIM",
        handphone:"Handphone",
        last_order:"Last Order",
        load_group:"Load Group",
        load_id:"Load ID",
        clear:"Hapus",
        ktp:"KTP",
        stnk:"STNK",
        muatan:"Muatan",
        km:"KM",
        needs:"Keperluan",
        transaction_date:"Tanggal Transaksi",
        no_txt:"Tidak",
        yes_txt:"Ya",
        pool_location:"Lokasi Pool",
        all:"Semua",
        from:"Dari",
        in_out_txt:"Masuk / Keluar",
        result_txt:"Hasil",
        sampai:"Sampai",
        period:"Periode",
        period_from:"Periode Dari",
        period_to:"Periode Sampai",
        truck_record_by_type:"Truck Record By Type",
        more_record:"More Records",
        truck_record_by_permit_status:"Truck Record By Permit Status",
        truck_record_by_permit:"Truck Record By Permit",
        permit_status:"Permit Status",
        time:"Waktu",
        date:"Tanggal",
        truck_type:"Tipe Truk",
        preventive_maintenance:"Preventive Maintenance",
        corrective_maintenance:"Corrective Maintenance",
        kir:"KIR",
        serviceTxt:"Perbaikan",
        date_time:"Date Time",
        parking:"Parkir",
        branch_phone:"Telepon Cabang",
        branch_phone2:"Teelpon Ke 2 Cabang",
        branch_poscode:"Kodepos Cabang",
        download_file:"Download Berkas",
        file:"Berkas",
        keterangan:"Keterangan",
        jumlah_bruto:"Jumlah Bruto",
        pph_dipotong:"PPH Dipotong",
        tgl_bukti_potong:"Tgl Bukti Potong",
        no_bukti_potong:"No Bukti Potong",
        kode_objek_pajak:"Kode Objek Pajak",
        pasal:"Pasal",
        nama_dipotong:"Nama Dipotong",
        id_dipotong:"ID Dipotong",
        name_pemotong:"Nama Pemotong",
        id_pemotong:"ID Pemotong",
        audit_info:"Informasi Audit",
        pool_choose_txt:"Pilih Pool",
        user_choose_txt:"Pilih Pengguna",
        txtUserMappingCreate:"Buat Mapping Pengguna",
        txtUserMappingDetail:"Detail Mapping Pengguna",
        txtUserMapping:"Mapping Pengguna",
        pool_txt:"Pool",
        pool_address:"Alamat Pool",
        pool_latitude:"Latitude Pool",
        pool_longitude:"Longitude Pool",
        pool_postalcode:"Kodepos Pool",
        pool_city:"Kota Pool",
        pool_province:"Provinsi Pool",
        pool_name:"Nama Pool",
        branch_longitude:"Longitude Cabang",
        branch_latitude:"Latitude Cabang",
        branch_name_txt:"Nama Cabang",
        branch_province_txt:"Provinsi Cabang",
        branch_city_txt:"Kota Cabang",
        branch_region_txt:"Wilayah Cabang",
        branch_address_txt:"Alamat Cabang",
        pools_count_txt:"Jumlah Pool",
        OnTxt:"ON",
        OffTxt:"OFF",
        reject_description_txt:"Alasan Ditolak",
        type_reject_description_txt:"Ketik Alasan Ditolak",
        DashboardMonitoringTxt:"Dashboard Monitoring",
        TripAlertTxt:"Trip Alert",
        GateAlertTxt:"Gate Alert",
        DocAlertTxt:"Doc Alert",
        ServiceAlertTxt:"Service Alert",
        OntimeAnalysisTxt:"Ontime Analysis",
        reject_category_txt:"Kategory Penolakan",
        save_change_txt:"Simpan Perubahan",
        reason_category_txt:"Reason",
        reason_txt:"Penjelasan",
        edit_order_txt:"Rubah Pesanan",
        total_hours_txt:"Total Jam",
        reject_time_txt:"Waktu Penolakan",
        ct_operation_performance_txt:"Kinerja Operator CT",
        close_txt:"Tutup",
        lading_qty_txt:"Lading Qty",
        net_weight_txt:"Berat Bersih",
        weight_txt:"Berat",
        volume_txt:"Volume",
        lading_height_txt:"Lading Height",
        lading_width_txt:"Lading Width",
        lading_length_txt:"Lading Length",
        lading_type_txt:"Lading Type",
        package_type_txt:"Package Type",
        qty_txt:"QTY",
        sku_description_txt:"Keterangan SKU",
        sku_txt:"SKU",
        detail_txt:"Detail",
        maps_txt:"Peta",
        destination_plan_txt:"Rencana Tujuan",
        destination_location_txt:"Lokasi Tujuan",
        destination_txt:"Tujuan",
        latitude_txt:"Latitude",
        longitude_txt:"Longitude",
        pickup_location_txt:"Lokasi Pengambilan",
        pickup_txt:"Pengambilan",
        shipwith_ref_txt:"Shipwith ref",
        remarks_txt:"Catatan",
        template_order_txt:"Template Order",
        status_tms_txt:"Status TMS",
        tms_id_txt:"TMS ID",
        load_id_txt:"Load ID",
        integration_description_txt:"Deskripsi Integrasi",
        integration_status_txt:"Status Integrasi",
        update_at_txt:"Perubahan Pada",
        update_by_txt:"Dirubah Oleh",
        create_at_txt:"Dibuat Pada",
        create_by_txt:"Dibuat Oleh",
        detail_transport_order_txt:"Detail Pesanan Transportasi",
        edit_transport_order_txt:"Rubah Pesanan Transportasi",
        audit_info_txt:"Informasi Audit",
        tracking_status_txt:"Riwayat Status",
        show_map_txt:"Tampilkan Peta",
        hide_map_txt:"Sembuyikan Peta",
        postpone_time_txt:"Waktu Tunda",
        postpone_order_txt:"Tunda Pesanan",
        reject_order_txt:"Tolak Pesanan",
        accept_order_txt:"Terima Pesanan",
        dest_plan_txt:"Rencana Tujuan",
        pickup_plan_txt:"Rencana Penjemputan",
        new_pickup_plan_txt:"Rencana Penjemputan Baru",
        new_dest_plan_txt:"Rencana Tujuan Baru",
        cubic_txt:"Kubikasi",
        tonnage_txt:"Tonase",
        transport_order_template_description_txt:"Order Template Name",
        submit_time_txt:'Waktu Di Kirimkan',
        action_txt:"Tindakan",
        dest_name_txt:"Nama Tujuan Pengiriman",
        pick_name_txt:"Nama Penjemputan",
        cancel_order_txt:"Batalkan Pesanan",
        cancel_order_costumer_txt:"Batalkan Pesanan dari Pelanggan",
        cancel_reason_txt:"Alasan Pembatalan",
        send_order_txt:"Kirimkan Pesanan",
        order_status_txt:"Status Pesanan",
        order_date_txt:"Tanggal Pesanan",
        order_no_txt:"No Pesanan",
        orders_txt:"Pesanan",
        transport_txt:"Transportasi",
        transport_orders_txt:"Pesanan Transportasi",
        day_30_truck_order_history_txt:"30 Hari Riwayat Pemesanan Tipe Truk",
        day_30_order_history_txt:"30 Hari Riwayat Pemesanan",
        no_data_available_txt:"Tidak Ada Data Yang Tersedia",
        year_truck_order_history_txt:"Riwayat Pesanan Tipe Truk 12 Bulan",
        day_truck_order_history_txt:"Riwayat Pemesanan Tipe Truk Berdasarkan Hari",
        year_order_history_txt:"Riwayat Pesanan 12 Bulan",
        day_order_history_txt:"Riwayat Pemesanan Berdasarkan Hari",
        check_internet_connection_txt:"Silahkan Periksa Internet KOneksi Anda",
        search_txt:"Cari",
        date_range_txt:"Rentang Tanggal",
        user_type_txt:"Tipe User",
        description_txt:"Penjelasan",
        coulmn_issue_txt:"Kolom Yang Bermasalah",
        customerEditTxt:"Ubah Customer",
        customerDetailTxt:"Detail Pelanggan",
        stateTxt:"Wilayah",
        customerAddTxt:"Tambah Data Pelanggan",
        location_reference_number_txt:"Nomor Referensi Lokasi",
        customerTxt:"Data Master Pelanggan",

        images_txt:"Gambar",

        typeDocumentTxt : "Tipe Dokumen",
        typeDocumentAccessAdd:"Tambah Tipe Dokumen",
        typeDocumentAccessEdit:"Ubah Tipe Dokumen",

        statusTxt : "Status",
        statusAccessAdd:"Tambah Status",
        statusAccessEdit:"Edit Status",
        
        txtPermissionRole:"Data Master Izin Role",
        txtPermission:"Data Master Izin",
        txtRoles:"Data Master Peran",
        txtMessages:"Pesan",
        txtSubject:"Subjek",
        pageContactUs:"Halaman Hubungi Kami",
        txtContactUs:"Hubungi Kami",
        MainTxt:"Utama",
        SupportTxt:"Bantuan",
        transactionData:"Transaksi",
        CreateTxt:"Tambah",
        ReadTxt:"Baca",
        UpdateTxt:"Ubah",
        DeleteTxt:"Hapus",
        UploadTxt:"Unggah",
        YesTxt:"Ya",
        NoTxt:"Tidak",
        skuAdd:"Tambah SKU",
        skuEdit:"ubah SKU",
        AdministrationTxt:"Administrasi",
        PermissionTxt:"Izin Akses",
        PermissionAdd:"Tambah Izin Akses",
        PermissionEdit:"Ubah Izin Akses",
        PermissionAddRole:"Tambah Izin Hak Akses",
        PermissionEditRole:"Ubah Izin Hak Akses",
        postalCodeAccessAdd:"Tambah Kode Pos",
        postalCodeAccessEdit:"Ubah Kode Pos",
        masterData:"Data Master",
        packageTxt:"Kemasan",
        packageCode:"Kode Kemasan",
        packageName:"Nama Kemasan",
        loadingTxt:"Memuat",
        submitFormTxt:"Kirimkan Data",
        submitOrderTxt:"Kirimkan Data Order",
        resetFormTxt:"Hapus Form",
        descriptionTxt : "Deskripsi",
        roleNameTxt : "Nama Hak Akses",
        permissionNameTxt : "Nama Izin Akses",
        ChangePasswordTxt:"Halaman Rubah Kata Sandi",
        changePassword:"Rubah Kata Sandi",
        profileTxt:"Halaman Profil Pengguna",
        dashboardTxt : "Selamat datang di Chain",
        dashboardMenu : "Beranda",
        autorizedErrorTxt : "Ups!! Halaman yang Anda minta tidak mendapatkan ijin",
        pageErrorTxt : "Ups!! Halaman yang Anda cari tidak ada",
        serverErrorTxt : "Ups!! Ada kesalahan. Silakan coba lagi nanti",
        
        regionAccessAdd:"Tambah Wilayah",
        regionAccessEdit:"Ubah Wilayah",
        packageAccessAdd:"Tambah Kemasan",
        packageAccessEdit:"Ubah Kemasan",
        address:"Alamat",
        regionTxt : "Wilayah",

        driverTxt : "Pengemudi",
        driverAccessAdd:"Tambah Pengemudi",
        driverAccessEdit:"Ubah Pengemudi",
        
        truckTxt : "Truck",
        truckAccessAdd:"Tambah Truck",
        truckAccessEdit:"Ubah Truck",

        documentTxt : "Dokumen",
        documentAccessAdd:"Tambah Dokumen",
        documentAccessEdit:"Ubah Dokumen",
        
        stopLocationTxt : "Lokasi Pemberhentian",
        stopLocationAccessAdd:"Tambah Lokasi Pemberhentian",
        stopLocationAccessEdit:"Ubah Lokasi Pemberhentian",
        month:"Bulan",
        year:"Tahun",
        branchTxt : "Cabang",
        branchAccessAdd:"Tambah Cabang",
        branchAccessEdit:"Ubah Cabang",
        branchAccessDetail:"Detail Cabang",
        branch_name:" Nama Cabang",
        branch_province:" Province Cabang",
        branch_city:" Kota Cabang",
        branch_region:" Wilayah Cabang",
        branch_address:"Alamat Cabang",

        ac_category: "Kategory AC",
        box_category: "Kategori Box",
        vehicle_no: "Nomor Kendaraan",
        carrier: "Carrier",
        device_id:"ID Perangkat",
        engine_number: "Nomor Mesin",
        gps_vendor: "GPS Vendor",
        km_limit: "Limit KM",
        manufacture_year: "Tahun Produksi",
        name_of_owner: "Nama Owner",
        ownership: " Ownership",
        project: "Project",
        purchased_year: "Tahun Pembelian",
        remark: "Catatan",
        truck_brand: "Truck Brand",
        truck_model: "Truck Model",
        truck_status: "Truck Status",
        type_truck: "Tipe Truk",
        unit_allocation: "Alokasi Unit",
        last_service:"Service Terakhir",
        last_km_service:"KM Service Terakhir",
        vin: "Vin",

        driver_id:"ID Pengemudi",
        driver_name:"Nama Pengemudi",
        branch_id:"Branch",
        site_name:" Nama Site",
        project_name:"Nama Project",
        driver_position:"Posisi Pengemudi",
        hp1:"HP 1",
        hp2:"HP 2",
        wa_hp1:"WA HP 1",
        sap_code:"SAP Code",
        driver_status:"Status Pengemudi",
        status_remark:"Status Remark",
        join_date:"Tgl Bergabung",
        nickname:"nama panggilan",
        brith_place:"Tempat lahir",
        brith_date:"Tanggal lahir",
        sim_type:"Type SIM",
        sim_expire_date:"Tanggal kadaluarsa SIM",
        ethnic:"Suku ",
        
        countryTxt:"Negara",
        provinceTxt:"Provinsi",
        cityTxt:"Kota",
        cityTypeTxt:"Tipe Kota",
        districtTxt:"Desa",
        subDistrictTxt:"Tipe Desa",
        postalCodeTxt:"Kode Pos",
        commodityTxt:"Komoditas",
        locationTxt:"Lokasi",
        locationCodeTxt:"Kode Lokasi",
        locationNameTxt:"Nama Lokasi",
        locationAdd:"Tambah Lokasi",
        locationEdit:"Ubah Lokasi",
        locationContactName:"Nama Kontak",
        locationPhoneNumber:"No Telpon",
        systemCodeTxt:"Kode Sistem",
        skuCodeTxt:"Kode SKU",
        skuDescriptionTxt:"SKU Deskripsi",
        skuLongDescriptionTxt:"SKU Deskripsi Panjang",
        usernameTxt : "Nama Pengguna",
        nameTxt : "Nama Lengkap",
        roleTxt : "Hak Akses",
        emailAddress : "Alamat Surel",
        txtMyProfile:"Profil Pengguna",
        txtUserSetting:"Pengaturan Pengguna",
        txtUsers:"Pengguna",
        txtLanguage:"Bahasa",
        txtSignOut:"Keluar",
        txtNotification:"Pemberitahuan",
        txtLogin:"Masuk",
        systemCodeAdd:"Tambah Kode Sistem",
        systemCodeEdit:"Ubah Kode Sistem",
        txtLogin2:"Silahkan masuk untuk melanjutkan",
        pageForgotPassword:"Halaman Lupa Kata Sandi",
        pageResetPassword:"Halaman Ulangi Sandi",
        txtpilihSatu:"Mohon di pilih salah satu data !",
        regionCode:"Kode Wilayah",
        password:"Kata Sandi",
        newPassword:"Kata Sandi Baru",
        passwordConfirm:"Konfirmasi Kata Sandi",
        forgotPassword:"Lupa Kata Sandi",
        rememberPassword:"Ingat Kata Sandi",
        resetPassword:"Ulangi Password",
        captchaCode:"Kode Captcha",
        dashboard:"Beranda",
        areYouSure:"Apakah anda sudah yakin?",
        yourData: "Data yang dikirim.",
        yourDataDelete: "Hapus data ini ",
        yourDataSend: "Apakah anda yakin mengirim data ini?",
        yourDataCancel: "Apakah anda yakin cancel data ini?",
        rejectResubmit: "Order ini telah ditolak, anda ingin melakukan submit ulang?",
        settings: "Pengaturan",
        wrongCaptchaCode:"Kode Captcha Salah",
        mainMenu:"Menu Utama",
        page404Desc:"kami tidak dapat menemukan halaman yang anda cari",
        page500Desc:"server kami sedang mengalami masalah, coba lah beberapa saat lagi.",
        goHOmeDesc:"Kehalaman Depan",
        userSettings:"Pengaturan Pengguna",
        name:"Nama",
        division:"Divisi",
        divisionCode:"Kode Divisi",
        divisionDesc:"Deskripsi Divisi",
        wrongPasswordConfirm:"Kata sandi konfirmasi tidak sama",
        userAccess:"Akses Pengguna",
        userAccessList:"Daftar Akses Pengguna",
        userAccessDetail:"Detail Akses Pengguna",
        userAccessEdit:"Rubah Akses Pengguna",
        backMess:"Kembali",
        editMess:"Ubah",
        deleteMess:"Hapus",
        submitMess:"Kirimkan",
        createNew:"Buat Data Baru",
        downloadData:"Unduh Data",
        uploadData:"Unggah Data",
        userAccessAdd:"Tambah Akses Pengguna Baru",
        roleAdd:"Tambah Hak Akses",
        roleEdit:"Ubah Hak Akses",
        commodityCodeTxt : "Kode Komoditas",
        sequenceTxt : "Urutan",
        valueTxt : "Nilai",
        commodityAdd:"Tambah Komoditas",
        commodityEdit:"Edit Komoditas",

        FakturPajakTxt:"Faktur Pajak",
        FakturPajakAdd: "Tambah Faktur Pajak",
        FakturPajakDetail: "Detail Faktur Pajak",
        FakturPajakEdit: "Edit Faktur Pajak",

        no_select_data: "Tidak ada baris terpilih  ",

        driver_attendance_txt: "Absensi Driver",

        basic:"Basic",
        requirements:"Persyaratan",
        historical_delivery:"Histori Pengiriman",
        training:"Latihan",
        bak:"BAK",
        gender:"Jenis Kelamin",
        driver_history_status:"Histori Status Driver",
        deposit:"Setoran",
        sibiling_info:"Sibling Info",
        recruitment_info:"Info Rekrutmen",
        name_of_id_card:"Nama ID Card",
        position:"Posisi",
        site_pool:"Site / Pool",
        assigned_vehicle_no:"No Kendaraan yang Ditugaskan",
        marital_status:"Status Pernikahan",
        number_of_childer:"Jumlah Anak",
        id_card_number:"ID Card",
        education:"Pendidikan",
        religion:"Agama",
        place_dob:"Tempat & DOB",
        age:"Umur",
        joined_date:"Tanggal Masuk",
        working_time:"Waktu Bekerja",
        end_of_contract:"Kontrak Terakhir",
        stop_date:"Waktu Berhenti", 
        stop_reason:"Alasan Berhenti",
        stop_remark:"Catatan Berhenti",
        teguran:"Teguran",
        hasilTest:"Hasil Test",
        create_proforma_invoice:"Buat Proforma Invoice",
        validateOrderDate:"Order Date Lebih Kecil Dari Tanggal Saat Ini",
        validatePickDate:"Pick Plan Lebih Kecil Dari Tanggal Saat Ini",
        validateDestDate:"Dest Plan Lebih Kecil Dari Tanggal Saat Ini",
        cancelConfirmation:"Anda Telah Memilih {total} Shipment untuk dibatalkan Apakah Anda Yakin ?, Karena setelah dibatalkan tidak bisa dikembalikan",
        cancel_desc_txt:"Deskripsi Cancel",
        new_order_number_txt:"Order Number Baru",
        old_order_number_txt:"Order Number Lama",

        route_master_code:"Kode Master Rute",
        route_name:"Nama Rute",
        route_start:"Rute Awal",
        coordinate_start:"Koordinat Awal",
        route_end:"Rute Akhir",
        coordinate_end:"Koordinat Akhir",
        distance:"Jarak",
        route_add:"Tambah Rute",

        dateUpdate:"Ubah Tanggal",
        sendToBlujay:"Kirim ke Blujay",
        sendToSF:"Kirim ke SF",
        billableMethodDateRate:"Tanggal mana yang harus digunakan saat menentukan tarif?",
        billableMethodSkuRating:"Haruskah item baris pengiriman dengan SKU yang sama digabungkan untuk peringkat?",
        billableMethodSkuRate:"Haruskah tarif pengiriman keseluruhan dihitung selain tarif SKU?",
    }
};
 

export default messages;