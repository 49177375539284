var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.isLoading)?_c('div',{staticClass:"loader"}):_vm._e(),(this.isLoading)?_c('div',{staticClass:"loadertext"},[_vm._v(" "+_vm._s(_vm.$t('loadingTxt'))+"...")]):_vm._e(),_c('div',{staticClass:"main-wrapper",class:{ 'blur-content': this.isLoading }},[_c('sidebar-component',{attrs:{"classMenu":"RedeemProdukIndex"}}),_c('div',{staticClass:"page-wrapper"},[_c('navbar-component'),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap grid-margin"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 col-xl-12 stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"float-start",staticStyle:{"margin-right":"5px"}},[_vm._v("Redeem Produk")]),(_vm.permision_role.method_read)?_c('button',{staticClass:"btn btn-success float-end btn-xs",staticStyle:{"margin-right":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.downloadData()}}},[_c('i',{staticClass:"link-icon",attrs:{"data-feather":"download"}}),_vm._v(" "+_vm._s(_vm.$t("downloadData"))+" .xlsx ")]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"title":"list-user","mode":"remote","select-options":{
                  enabled: true,
                  selectOnCheckboxOnly: true,
                  selectionText: _vm.$t('data_selected'),
                  clearSelectionText: _vm.$t('clear'),
                },"totalRows":_vm.totalRecords,"line-numbers":true,"isLoading":_vm.isLoading,"pagination-options":{
                    enabled: true,
                  },"rows":_vm.rows,"columns":_vm.columns},on:{"on-selected-rows-change":_vm.selectionChanged,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(_vm.permision_role.method_update)?_c('a',{attrs:{"href":"#","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.showModalData(props.row)}}},[_c('i',{staticClass:"mdi mdi-square-edit-outline",staticStyle:{"font-size":"16px"}})]):_vm._e()]):(props.column.field == 'link_upload')?_c('span',[_vm._v(" "+_vm._s(_vm.slicedString(props.row.link_upload))+"... ")]):(props.column.field == 'image')?_c('span',[_c('img',{staticClass:"img-fluid img-thumbnail",staticStyle:{"max-height":"100px"},attrs:{"src":props.row.image}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-success btn-xs",staticStyle:{"margin-right":"20px"},on:{"click":_vm.validate}},[_c('i',{staticClass:"mdi mdi-send-outline"}),_vm._v("Validate")]),_c('button',{staticClass:"btn btn-danger btn-xs",on:{"click":_vm.reject}},[_c('i',{staticClass:"mdi mdi-close"}),_vm._v(" Reject")])])])],1)])])])]),_c('modal-validation',{attrs:{"showModal":_vm.showModal,"data":_vm.detailRedeemProduk},on:{"closeModal":_vm.closeMyModal}}),_c('footer-component')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mb-12 mb-md-0"})])
}]

export { render, staticRenderFns }