<template>    
  <div id="app">       
    <router-view />
  </div>  
</template>



<script>
export default {
    name: 'App',
    metaInfo () {
      return {
        title: "BERT",
        meta: [
          
          { 'http-equiv': 'Permissions-Policy', content: "width=device-width, initial-scale=1.0, autoplay=(self), camera=(), encrypted-media=(self), fullscreen=(), geolocation=(self), gyroscope=(self), magnetometer=(), microphone=(), midi=(), payment=(), sync-xhr=(self), usb=()"},
        ]
      }
    },
    mounted() {

    var routeName = this.$route.name
    if(routeName != "UserLogin"){
      this.$setupTimers();
    }
      
    }
  }
</script>





<style> 
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ab0635;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loadertext {
  position: absolute;
  left: 47%;
  top: 58%;
  z-index: 1;
  font-weight: bold;
}

.navbar {
  margin-top: -16px;
}

</style>


   

