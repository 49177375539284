<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <!-- <Carousel>
          
              <CarouselItem key={banner.id}>
                <a href={banner.link} target='_blank' rel='noreferrer'>
                  <img src="" alt="Banner"/>
                </a>
              </CarouselItem>
           
        </Carousel> -->
        <banner-component classMenu="BannerIndex"></banner-component>

        <div class="section-why-ibu2canggih">
          <p>Bert's<br />Community</p>
          <div class='overlay-container'>
            <div class='content top'>
              Bert's Community memiliki 3 Komunitas utama, yaitu Ibu2Canggih, Geng Glowing & IkanGalau.

            </div>
            <div class='content bot'>
              Setiap bulannya, komunitas kita memiliki Program Reward <br />
              untuk mengapresiasi keaktifan setiap anggota dalam<br />
              kegiatan masing-masing Komunitas.
            </div>
            <!--img class='image' src="/assets/images/why_ibu2canggih_illustration.png" style="width:100%;opacity: 0.5;" alt="Kenapa Ibu2Canggih"/-->
          </div>
        </div>

        <div class='section-partner'>
          <p>Our Previous Sponsor: </p>
          
          <img class='image' src="/assets/images/partner.png" alt="Maspion" />
          <!-- <img class='image' src="/assets/images/skin1004.png" alt="Skin1004" />
          <img class='image' src="/assets/images/kistchy.png" alt="Kitschy" />
          <img class='image' src="/assets/images/pero.png" alt="Pero" />
          <img class='image' src="/assets/images/whitelab.png" alt="Whitelab" />
          <img class='image' src="/assets/images/mac-bear.png" alt="Mac Bear" /> -->
         


          <p>and many more..</p>

        </div>

        <!-- <div class='section-ibu2canggih-events overlay-container'>
          <img src="/assets/images/event_illustration.png" style="border-radius: 50%;" alt="Regular Events"/>
          <div class='bg-purple'></div>
          <div class='content'>
            <div class='title-white'>Ibu2Canggih<br/>Regular Event</div>
            
            <ul class='content-white'>
              <li>Youtube live bersama expert</li>
              <li>Kelas online</li>
              <li>Workshop</li>
              <li>Webinar</li>
            </ul>
          </div>
        </div> -->

        <div class='section-ibu2canggih-benefit'>
          <p>Manfaat tergabung dalam<br />Bert's Community</p>
          <div class='items'>
            <div class='item'>
              <img src="/assets/images/benefit_sharing.png" style="border-radius: 50%;" alt="Benefit Sharing" />
              <div>Sharing dengan para member komunitas</div>
            </div>
            <div class='item'>
              <img src="/assets/images/benefit_survey.png" style="border-radius: 50%;" alt="Benefit Survey" />
              <div>Mengikuti berbagai event Brand & Komunitas</div>
            </div>
            <div class='item'>
              <img src="/assets/images/benefit_product.png" style="border-radius: 50%;" alt="Benefit Product" />
              <div>Mendapatkan produk gratis berbagai brand</div>
            </div>
            <div class='item'>
              <img src="/assets/images/benefit_momfluencer.png" style="border-radius: 50%;" alt="Benefit Momfluencer" />
              <div>Berkesempatan menjadi Bert's Influencers</div>
            </div>
            <div class='item'>
              <img src="/assets/images/benefit_reward.png" style="border-radius: 50%;" alt="Benefit Reward" />
              <div>Mengikuti Program Reward berhadiah ekslusif</div>
            </div>
          </div>
        </div>

        <div class='section-auth'>
          <p>Ayo dapatkan berbagai manfaat & keuntungan dengan bergabung di Bert's Community!</p>
          <div class='buttons'>
            <div>
              <div class='info'>Sudah punya akun?</div>
              <!-- <Link to='/login'> -->
              <a href="/login"><button class='login'>Masuk</button></a>
              <!-- </Link> -->
            </div>
            <div>
              <div class='info'>Belum punya akun?</div>
              <!-- <Link to='/register'> -->
              <a href="/registrasi"><button class='register'>Registrasi</button></a>
              <!-- </Link> -->
            </div>
          </div>
        </div>

        <div class='section-article'>
          <div class='box-title'>
            <p>Artikel</p>
            <div class='line' />
          </div>
          <article-component classMenu="ArticleIndex"></article-component>

        </div>

        <information-component classMenu="InformationIndex"></information-component>

      </div>

      <!-- <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div
            class="
              page-content
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <div class="row w-100 mx-0 auth-page">
              <div class="col-md-4 col-xl-4 mx-auto">
                <div class="card">
                  <div class="row">
                    <div class="col-md-12 ps-md-0">
                      <div class="auth-form-wrapper px-6 py-5">
                        

                        <a
                          href="#"
                          class="noble-ui-logo d-block mb-2"
                          style="text-align: center"
                          ><span>LANDING PAGE</span></a
                        >
                        <h5
                          class="text-muted fw-normal mb-4"
                          style="text-align: center"
                        >
                          {{ $t("txtLogin2") }}
                        </h5>
                        <form
                          class="forms-sample"
                          @submit.prevent="submitData"
                          method="POST"
                          autocomplete="off"
                        >
                          <div class="mb-3">
                            <label for="txtLanguage" class="form-label">{{
                              $t("txtLanguage")
                            }}</label>
                            <select
                              v-model="locale"
                              @change="langChanged($i18n.locale)"
                              class="form-select"
                            >
                              <option
                                v-for="(lang, i) in langs"
                                :key="`Lang${i}`"
                                :value="lang"
                              >
                                <font style="color: #212529">{{ lang }}</font>
                              </option>
                            </select>
                          </div>
                          <div class="mb-3">
                            <label for="username" class="form-label">{{
                              $t("usernameTxt")
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="username"
                              :placeholder="$t('usernameTxt')"
                              v-model="forms.username"
                              autofocus
                              required
                            />
                          </div>
                          <div class="mb-3">
                            <label for="userPassword" class="form-label">{{
                              $t("password")
                            }}</label>
                            <input
                              type="password"
                              class="form-control"
                              id="userPassword"                              
                              :placeholder="$t('password')"
                              v-model="forms.password"
                              required
                            />
                          </div>
                          
                          <div class="mb-3">
                            <div class="position-relative">
                              <vue-captcha
                                ref="captcha"
                                :captcha.sync="code"
                                @on-change="handleChange"
                              ></vue-captcha>
                            </div>
                            <div class="buttons">
                              <button
                                type="button"
                                @click="refreshCaptchaCode"
                                class="btn icon icon-left btn-info"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="fa fa-refresh"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="16" x2="12" y2="12"></line>
                                  <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                </svg>
                                Refresh
                              </button>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="captchaCode" class="form-label">{{
                              $t("captchaCode")
                            }}</label>
                            <input
                              type="text"
                              class="form-control"
                              id="captchaCode"
                              :placeholder="$t('captchaCode')"
                              v-model="forms.captchaCode"
                              required
                            />
                          </div>

                          <div>
                            <button
                              class="
                                btn btn-primary
                                me-2
                                mb-2 mb-md-0
                                text-white
                              "
                            >
                              {{ $t("txtLogin") }}
                            </button>
                          </div>
                          <div style="text-align: right">
                            <a
                              :href="
                                this.$settings.endPointApp + 'forgot-password'
                              "
                              class="auth-link text-black"
                              >{{ $t("forgotPassword") }}?</a
                            >
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->


    </div>
  </div>
</template>


<script>
// import { setSessionChainToken, setSessionCtToken,setSessionFpToken,setSessionGtToken} from "@/middleware/setBackendSessions";
// import { setAuthToken, setAuthLang, getUserInfo } from "@/middleware/auth";
// import VueCaptcha from "vue-captcha-code";
import articleComponent from "@/components/_partials/article";
import bannerComponent from "@/components/_partials/banner";
import informationComponent from "@/components/_partials/information";

export default {
  name: "LandingPage",
  components: {
    // VueCaptcha,
    "banner-component": bannerComponent,
    "article-component": articleComponent,
    "information-component": informationComponent
  },
  data() {
    return {
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
    };
  },
  watch: {},
  methods: {
    handleChange(code) {
      this.code = code;
    },
    refreshCaptchaCode() {
      this.$refs.captcha.refreshCaptcha();
    },
    makeOffer() {

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    active() {
      const userDatas = this.$getUserInfo();
      var check = userDatas.sub?userDatas.sub:null;
      if(check){
        if(check.role_id === 1){
          this.$router.push('/dashboard');
        }else{
          this.$router.push('/user/home');
        }
      }else{
        if (this.$route.path !== '/') {
        this.$router.push('/');
      }

      }

      
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loading();
    this.active();
    
  },
};
</script>
<style scoped>
@import '_partials/partial.css';
</style>