import Banner from "@/components/master/banner/Banner.vue";
import BannerAdd from "@/components/master/banner/BannerAdd.vue";
import BannerDetail from "@/components/master/banner/BannerDetail.vue";
import BannerEdit from "@/components/master/banner/BannerEdit.vue";
import BannerUpload from "@/components/master/banner/BannerUpload.vue";

let routesBanner= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Banner",
        component: Banner
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "BannerAdd",
        component: BannerAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "BannerEdit",
        component: BannerEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "BannerDetail",
        component: BannerDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "BannerUpload",
        component: BannerUpload
      },
    
]

export default routesBanner
  