<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="RedeemProdukIndex"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div>
              <h4 class="mb-12 mb-md-0"></h4>
            </div>

            <div class="col-lg-12 col-xl-12 stretch-card">
              <div class="card">
                <div class="card-header">
                  <h4 style="margin-right: 5px" class="float-start">Redeem Produk</h4>
                  <button v-if="permision_role.method_read" class="btn btn-success float-end btn-xs"
                    style="margin-right: 5px" @click.prevent="downloadData()">
                    <i class="link-icon" data-feather="download"></i>
                    {{ $t("downloadData") }} .xlsx
                  </button>
                </div>
                <div class="card-body">
                  <vue-good-table title="list-user" mode="remote" :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                    selectionText: $t('data_selected'),
                    clearSelectionText: $t('clear'),
                  }" @on-selected-rows-change="selectionChanged" @on-page-change="onPageChange"
                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :line-numbers="true"
                    :isLoading.sync="isLoading" :pagination-options="{
                      enabled: true,
                    }" :rows="rows" :columns="columns">
                    <div slot="selected-row-actions">
                      <button class="btn btn-success btn-xs" style="margin-right:20px;" @click="validate"> <i
                          class="mdi mdi-send-outline"></i>Validate</button>

                      <button class="btn btn-danger btn-xs" @click="reject"><i class="mdi mdi-close"></i> Reject</button>
                    </div>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'actions'">
                        <!-- <a
                              href="#"
                              v-if="permision_role.method_read"
                              title="Detail"
                              @click.prevent="
                                detailData(props.index, props.row)
                              "
                            >
                              <i class="mdi mdi-eye" style="font-size:16px"></i>
                              
                            </a> -->
                        <a href="#" v-if="permision_role.method_update" title="Edit"
                          @click.prevent="showModalData(props.row)">
                          <i class="mdi mdi-square-edit-outline" style="font-size:16px"></i>

                        </a>
                      </span>
                      <span v-else-if="props.column.field == 'link_upload'">
                        {{ slicedString(props.row.link_upload) }}...
                      </span>
                      <span v-else-if="props.column.field == 'image'">
                        <img :src="props.row.image" class="img-fluid img-thumbnail" style="max-height:100px;">
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <modal-validation :showModal="showModal" :data="detailRedeemProduk" @closeModal="closeMyModal"></modal-validation>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import modalValidation from "@/components/modal/modalRedeemProduk.vue";

export default {
  name: "RedeemProdukIndex",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    "modal-validation": modalValidation,
  },
  data() {
    return {
      showModal: false,
      detailRedeemProduk: [],
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      errors: [],
      permision_role: [],
      langs: ["id", "en"],
      detailUser: [],
      totalRecords: 0,
      // endPoint: '',
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
      columns: [

        // {
        //   label: "Username",//this.$t("link_txt"),
        //   field: "username",
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     placeholder: "Filter By User",//+this.$t("link_txt"), // placeholder for filter input
        //     filterValue: "", // initial populated value for this filter
        //     filterDropdownItems: [], // dropdown (with selected values) instead of text input
        //     trigger: "enter", //only trigger on enter not on keyup
        //   },
        // },

        {
          label: "Produk",//this.$t("priority_txt"),
          field: "produk",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Produk", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Poin",//this.$t("stok_txt"),
          field: "point",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Point", //+this.$t("stok_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [],  // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Tanggal",//this.$t("priority_txt"),
          field: "tanggal",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Tanggal", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Nama Lengkap",//this.$t("priority_txt"),
          field: "nama_lengkap",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Nama Lengkap", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "WA",//this.$t("priority_txt"),
          field: "wa",
          width: '50px',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By WA", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Alamat Lengkap",//this.$t("priority_txt"),
          field: "alamat_lengkap",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Alamat", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Provinsi",//this.$t("priority_txt"),
          field: "provinsi",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Alamat", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kota",//this.$t("priority_txt"),
          field: "kota",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Alamat", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kecamatan",//this.$t("priority_txt"),
          field: "kecamatan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Alamat", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kelurahan",//this.$t("priority_txt"),
          field: "kelurahan",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Alamat", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Kode Pos",//this.$t("priority_txt"),
          field: "kode_pos",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Filter By Alamat", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Status",//this.$t("priority_txt"),
          field: "status",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Status", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["pending", "valid", "invalid"], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Community",//this.$t("priority_txt"),
          field: "community",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Filter By Community", //+this.$t("priority_txt"), // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            trigger: "enter", //only trigger on enter not on keyup
          },
        },

        {
          label: "Action",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
      ids: [],
      rowsSelection: [],
    };
  },
  watch: {},
  methods: {
    validate() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            ids: this.ids
          }
          const baseURI =
            this.$settings.endPoint + "redeem-produk/validate";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },
    reject() {

      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataSend"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          // this.loadingSubmiting();
          this.fade(true);
          let data = {
            ids: this.ids
          }
          const baseURI =
            this.$settings.endPoint + "redeem-produk/reject";
          this.$http
            .post(baseURI, data, { timeout: 30000 })
            .then((response) => {
              // this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
                this.isloading = false;
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            }).catch(error => {
              this.isLoading = false;
              if (error.code == "ECONNABORTED") {
                this.error(this.$t("check_internet_connection_txt"));
              } else {

                if (error.response) {
                  if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    this.resultError(error.response.data.errors);
                  } else if (error.response.status === 500) {
                    this.$router.push('/server-error');
                  } else if (error.response.status === 401) {
                    this.$router.push("/authorized-error");
                  } else {
                    this.$router.push('/page-not-found');
                  }
                }

              }

            });
        }
      });

    },
    selectionChanged(ev) {
      this.rowsSelection = [];
      this.ids = [];
      this.rowsSelection = ev.selectedRows;

      this.btnClearSelection = false;
      this.rowsSelection.forEach((item) => {
        this.ids.push(item.id);
      });

    },
    showModalData(data) {
      this.showModal = true;
      this.detailRedeemProduk = data;
    },
    closeMyModal() {
      this.showModal = false;
      this.loadItems();
    },
    loadCommunity() {
      const baseURI = this.$settings.endPoint + "community";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI, { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          var arrayCommunity = response.data.datas.data;
          this.columns[7].filterOptions.filterDropdownItems = arrayCommunity.map(item => item.community);


        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    slicedString(str) {
      return str.substring(0, 15);
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'RedeemProdukController') {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }
      this.permision_role = rolePermision;

    },

    downloadData() {
      const baseURI = this.$settings.endPoint + "redeem-produk";
      var CurrentDate = this.$moment().format("DD_MM_YYYY_HH_mm_ss");
      var file_name = "download_" + CurrentDate + ".xlsx";

      //var columnFilters = this.serverParams.columnFilters;


      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&produk=${this.serverParams.columnFilters.produk}&point=${this.serverParams.columnFilters.point}&tanggal=${this.serverParams.columnFilters.tanggal}&nama_lengkap=${this.serverParams.columnFilters.nama_lengkap}&wa=${this.serverParams.columnFilters.wa}&alamat_lengkap=${this.serverParams.columnFilters.alamat_lengkap}&status=${this.serverParams.columnFilters.status}&community=${this.serverParams.columnFilters.community}&download=download`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {

          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.fade(false);
        });
    },

    imageRedeemProduk(row) {
      return this.$settings.endPoint + 'redeem-produk/' + row.image;
    },

    editData(index, row) {
      var params = this.$onRandom(row.id);
      window.location.href = "/redeem-produk/edit/" + params;
    },

    detailData(index, row) {
      var params = this.$onRandom(row.id);
      window.location.href = "/redeem-produk/detail/" + params;
    },

    deleteData(index, row, status) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI =
            this.$settings.endPoint + "redeem-produk/delete/" + row.id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                this.loadItems();
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.$router.push("/server-error");
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    // load items is what brings back the rows from server
    loadItems() {
      const baseURI = this.$settings.endPoint + "redeem-produk";

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=${this.serverParams.per_page}&page=${this.serverParams.page}&sort_field=${this.serverParams.sort.field}&sort_type=${this.serverParams.sort.type}&produk=${this.serverParams.columnFilters.produk}&point=${this.serverParams.columnFilters.point}&tanggal=${this.serverParams.columnFilters.tanggal}&nama_lengkap=${this.serverParams.columnFilters.nama_lengkap}&wa=${this.serverParams.columnFilters.wa}&alamat_lengkap=${this.serverParams.columnFilters.alamat_lengkap}&status=${this.serverParams.columnFilters.status}&community=${this.serverParams.columnFilters.community}`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;
          this.totalRecords = response.data.datas.total;
        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000); // hide the message after 3 seconds
    },


    logout() {
      this.$logoutUser();
      this.$router.push("/");
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loading();
    this.loadCommunity();

  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>