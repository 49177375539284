import Campaign from "@/components/transaction/campaign/Campaign.vue";
import CampaignAll from "@/components/transaction/campaign/CampaignAll.vue";
import CampaignUserAll from "@/components/transaction/campaign/CampaignUserAll.vue";
import CampaignAdd from "@/components/transaction/campaign/CampaignAdd.vue";
import CampaignDetail from "@/components/transaction/campaign/CampaignDetail.vue";
import CampaignEdit from "@/components/transaction/campaign/CampaignEdit.vue";
import CampaignUpload from "@/components/transaction/campaign/CampaignUpload.vue";

let routesCampaign= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Campaign",
        component: Campaign
      },
      {
        path: 'all',
        meta: {
          title: "BERT",
        },
        name: "CampaignAll",
        component: CampaignAll
      },
      {
        path: 'user/all',
        meta: {
          title: "BERT",
        },
        name: "CampaignUserAll",
        component: CampaignUserAll
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "CampaignAdd",
        component: CampaignAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "CampaignEdit",
        component: CampaignEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "CampaignDetail",
        component: CampaignDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "CampaignUpload",
        component: CampaignUpload
      },
    
]

export default routesCampaign
  