<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Additional Campaign Form</h5>


          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Submit Draft</label>
              </div>
              <div class="col-lg-4">
                <Datepicker v-model="forms.submit_draft" :bootstrap-styling=true
                  format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
              </div>

              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Preview Draft</label>
              </div>
              <div class="col-lg-4">
                <Datepicker v-model="forms.preview_draft" :bootstrap-styling=true
                  format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Upload Konten</label>
              </div>
              <div class="col-lg-4">
                <Datepicker v-model="forms.upload_konten" :bootstrap-styling=true
                  format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
              </div>

              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Bukti Tayang</label>
              </div>
              <div class="col-lg-4">
                <Datepicker v-model="forms.bukti_tayang" :bootstrap-styling=true
                  format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Bukti Insight</label>
              </div>
              <div class="col-lg-4">
                <Datepicker  v-model="forms.bukti_insight" :bootstrap-styling=true
                  format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>
              </div>

              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Product Knowledge</label>
              </div>
              <div class="col-lg-4">
                <div v-if="images_product_knowledge" class="dropify-wrapper has-preview">
                  <div class="dropify-message">
                    <span class="file-icon" v-if="!images_product_knowledge">
                      <p>Drag and drop a file here or click</p>
                    </span>
                    <p class="dropify-error">Ooops, something wrong appended.</p>
                  </div>
                  <div class="dropify-loader" style="display: none;"></div>
                  <div class="dropify-errors-container">
                    <ul></ul>
                  </div>
                  <input type="file" ref="images" accept="image/*" class="form-control"
                    @change="handleFileUpload($event)">
                  <button type="button" class="dropify-clear" @click="removeImages">Remove</button>
                  <div class="dropify-preview" style="display: block;">
                    <img :src="images_product_knowledge" width="90%" height="80%" />
                    <span class="dropify-render"></span>
                    <div class="dropify-infos">
                      <div class="dropify-infos-inner">
                        <p class="dropify-filename">
                        </p>
                        <p class="dropify-infos-message">Drag and drop or click to replace</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div v-else class="dropify-wrapper">
                  <div class="dropify-message">
                    <span class="file-icon">
                      <p>Drag and drop a file here or click</p>
                    </span>
                  </div>
                  <div class="dropify-loader" style="display: none;"></div>
                  <div class="dropify-errors-container">
                    <ul></ul>
                  </div>
                  <input type="file" ref="images" accept=".pdf, .doc, .docx, image/*" class="form-control"
                    @change="handleFileUpload($event)">
                  <button type="button" class="dropify-clear">Remove</button>
                  <div class="dropify-preview" style="display: none;">
                    <img :src="images_product_knowledge" width="90%" height="80%" />
                    <span class="dropify-render"></span>
                    <div class="dropify-infos">
                      <div class="dropify-infos-inner">
                        <p class="dropify-filename">
                        </p>
                        <p class="dropify-infos-message">Drag and drop or click to replace</p>
                      </div>
                    </div>
                  </div>

                </div>


                <div v-if="errors.product_knowledge">
                  <div v-for="error in errors.product_knowledge" :key="error" class="alert alert-primary" role="alert">
                    <i data-feather="alert-circle"></i>
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              {{ $t("close_txt") }}
            </button>
            <button class="btn btn-success" type="submit" @click.prevent="validate()">Submit Data</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from 'moment'

export default {
  components: {
    Datepicker
  },
  name: "ModalEventForm",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
    status: String,
  },
  watch: {
    showModal: {

      handler(newVal) {
        this.active = newVal;
        const body = document.querySelector("body");
        this.forms.submit_draft = this.data.submit_draft;
        this.forms.preview_draft = this.data.preview_draft;
        this.forms.upload_konten = this.data.upload_konten;
        this.forms.bukti_tayang = this.data.bukti_tayang;
        this.forms.bukti_insight = this.data.bukti_insight;
        this.product_knowledge = this.data.product_knowledge
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      active: this.showModal,
      product_knowledge: '',
      images_product_knowledge: null,
      forms: {},
      errors: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    removeImages() {
      this.$refs.product_knowledge.value = "";
      this.images_product_knowledge = null;
    },


    handleFileUpload(event) {
      this.product_knowledge = event.target.files[0];
      this.images_product_knowledge = URL.createObjectURL(this.product_knowledge);
    },
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {
      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>CANCELLING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    submitData() {
      var baseURI = "";
      baseURI = this.$settings.endPoint + "peminjaman/pengembalian/";
      this.$http
        .post(baseURI, null, { timeout: 30000 })
        .then((response) => {
          this.isLoading = false;
          this.errors = [];
          this.$emit("closeModal");
          this.success2(response.data.datas.messages);
        })
        .catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });



    },

    validate() {
      var baseURI = "";
      baseURI = this.$settings.endPoint + "campaign/update-add/" + this.data.campaign_id;
      let formData = new FormData();
      formData.append("submit_draft", moment(String(this.forms.submit_draft)).format('YYYY-MM-DD'));
      formData.append("preview_draft", moment(String(this.forms.preview_draft)).format('YYYY-MM-DD'));
      formData.append("upload_konten", moment(String(this.forms.upload_konten)).format('YYYY-MM-DD'));
      formData.append("bukti_tayang", moment(String(this.forms.bukti_tayang)).format('YYYY-MM-DD'));
      formData.append("bukti_insight", moment(String(this.forms.bukti_insight)).format('YYYY-MM-DD'));
      formData.append("product_knowledge", this.product_knowledge);
      this.$http
        .post(baseURI, formData, { timeout: 30000 })
        .then((response) => {
          this.isLoading = false;
          this.errors = [];
          this.$emit("closeModal");
          this.success2(response.data.datas.messages);
        })
        .catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });



    },

  },
  mounted() {

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>