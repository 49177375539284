import Article from "@/components/master/article/Article.vue";
import ArticleAdd from "@/components/master/article/ArticleAdd.vue";
import ArticleDetail from "@/components/master/article/ArticleDetail.vue";
import ArticleEdit from "@/components/master/article/ArticleEdit.vue";
import ArticleUpload from "@/components/master/article/ArticleUpload.vue";

let routesArticle= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Article",
        component: Article
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "ArticleAdd",
        component: ArticleAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "ArticleEdit",
        component: ArticleEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "ArticleDetail",
        component: ArticleDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "ArticleUpload",
        component: ArticleUpload
      },
    
]

export default routesArticle
  