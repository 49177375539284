import { render, staticRenderFns } from "./BannerEdit.vue?vue&type=template&id=e5f4aaba&scoped=true"
import script from "./BannerEdit.vue?vue&type=script&lang=js"
export * from "./BannerEdit.vue?vue&type=script&lang=js"
import style0 from "./BannerEdit.vue?vue&type=style&index=0&id=e5f4aaba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5f4aaba",
  null
  
)

export default component.exports