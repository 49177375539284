import RedeemProduk from "@/components/transaction/redeem-produk/RedeemProduk.vue";
import RedeemProdukAdd from "@/components/transaction/redeem-produk/RedeemProdukAdd.vue";
import RedeemProdukDetail from "@/components/transaction/redeem-produk/RedeemProdukDetail.vue";
import RedeemProdukEdit from "@/components/transaction/redeem-produk/RedeemProdukEdit.vue";
import RedeemProdukUpload from "@/components/transaction/redeem-produk/RedeemProdukUpload.vue";

let routesRedeemProduk= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "RedeemProduk",
        component: RedeemProduk
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "RedeemProdukAdd",
        component: RedeemProdukAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "RedeemProdukEdit",
        component: RedeemProdukEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "RedeemProdukDetail",
        component: RedeemProdukDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "RedeemProdukUpload",
        component: RedeemProdukUpload
      },
    
]

export default routesRedeemProduk
  