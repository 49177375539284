<template>
    <div>
        <div v-if="isLoading">
            <!--begin::Loading Content-->
            <div class="main-wrapper">
                <div class="page-wrapper full-page">
                    <div class="container">
                        <div class="row">
                            <!--begin::Card-->
                            <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                                <div class="overlay-layer card-rounded bg-opacity-5">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Card-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Loading Content-->
        </div>
        <div v-else>
            <div class="App">
                <div class="navbars-body section-home">
                    <!-- <div class="header">
                        <img @click="previousPage" src="/assets/images/icon_back.png" alt="Back Icon" />
                        Poin Anda : {{ points.point }}
                    </div> -->
                    <div class="campaign-container">
                        <div class="input-group rounded">
                            <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search"
                                v-model="search" @change="refreshData()" aria-describedby="search-addon" />
                            <span class="input-group-text border-0" id="search-addon">
                                <i class="mdi mdi-magnify"></i>
                            </span>
                        </div>
                    </div>
                    <div class="tag-container">
                        <a class="btn btn-warning" @click.prevent="riwayat" style="width:100%;"> Campaign Saya</a>
                    </div>

                    <div class="tag-container">
                        <a class="btn btn-info btn-xs custom-button">Life Style</a>
                        <a class="btn btn-info btn-xs custom-button">Mom & Baby</a>
                        <a class="btn btn-info btn-xs custom-button">Beauty</a>
                        <a class="btn btn-info btn-xs custom-button">Foods</a>
                        <a class="btn btn-info btn-xs custom-button">Entertainment</a>
                        <a class="btn btn-info btn-xs custom-button">Music</a>
                        <a class="btn btn-info btn-xs custom-button">Fashion</a>
                        <a class="btn btn-info btn-xs custom-button">Profession</a>
                        <a class="btn btn-info btn-xs custom-button">Education</a>
                        <a class="btn btn-info btn-xs custom-button">Comedy</a>
                        <a class="btn btn-info btn-xs custom-button">Sport</a>
                        <a class="btn btn-info btn-xs custom-button">Health</a>
                    </div>


                    <div class="campaign-container">
                        <div class="campaign" v-for="item in campaign" :key="item.campaign_id">
                            <img :src="item.logo_brand" :alt="item.nama">
                            <!-- <h5 class="campaign-name">{{ item.tipe_project.split(',') }}</h5> -->
                            <br />
                            <a href="#" v-for="(tag, index) in item.tipe_project.split(',')" :key="index"
                                class="btn btn-info btn-xs tipe-button">
                                {{ tag }}
                            </a>
                            <p class="campaign-description title">{{ item.name }}</p>
                            <p class="campaign-description information"><i class="mdi mdi-clock-time-three"></i>
                                Registrasi
                                dibuka hingga: <span class="bold">{{ item.end_reg }}</span></p>
                            <p class="campaign-description information"><i class="mdi mdi-file-document"></i> Dibutuhkan
                                <span class="bold">{{ item.kuota }} influencer </span>
                            </p>

                            <div class="tag-container">
                                <a class="btn btn-success" @click.prevent="detailcampaign(item.campaign_id)"
                                    style="width:100%;">
                                    <div v-if="item.statusDaftar == 1">Cek status anda</div>
                                    <div v-else>Register</div>
                                </a>

                            </div>
                            <div class="tag-container">
                                <a class="btn btn-info" style="width:100%;"
                                    @click.prevent="shareToWhatsApp(item.campaign_id)">Bagikan ke WhatsApp</a>
                            </div>
                        </div>
                    </div>

                </div>
                <navbar-component page="home" />

            </div>


        </div>
    </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";
// import Slick from 'vue-slick';
// import 'slick-carousel/slick/slick.css';

export default {
    name: "RedeemUser",
    components: {
        "navbar-component": navbarComponent,
        // Slick
    },
    data() {
        return {
            search: '',
            points: [],
            todayName: '',
            setting: [],
            code: "",
            locale: "",
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            isLoading: false,
            detailUser: [],
            errors: [],
            campaign: [],
            show: false,
            // slickOptions: {
            //     arrows: false,
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     autoplay: true,
            //     autoplaySpeed: 3000,
            //     // prevArrow: '<button class="slick-prev" aria-label="Previous" title="Previous">Previous</button>',
            //     // nextArrow: '<button class="slick-next" aria-label="Next" title="Next">Next</button>',
            // },
            forms: [],
        };
    },
    watch: {},
    methods: {
        async shareToWhatsApp(id) {
             try {
            //     let params = this.$onRandom(id);
            //     // URL yang ingin di-shorten
            //     const longUrl = "https://bertscommunity.com/user/campaign-detail/" + params;

            //     const response = await fetch(`https://is.gd/create.php?format=json&url=${encodeURIComponent(longUrl)}`,{ mode: 'no-cors' });
            //     if (!response.ok) {
            //         throw new Error('Gagal mendapatkan data');
            //     }
            //     console.log(response)
            //     const jsonData = await response.json();
            //     const shortUrl = jsonData.shorturl;

            //     const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(shortUrl)}`;

            //     // Buka WhatsApp dengan URL yang dibuat
            //     window.location.href = whatsappUrl;

            // //     const shortUrl = data.shorturl;
            // } catch (error) {
            //     console.error('Error:', error);
            // }

            // let params = this.$onRandom(id);
            // URL yang ingin di-shorten
            const longUrl = "https://bertpoints.com/user/campaign-detail/" + id;

            const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(longUrl)}`;

            // Buka WhatsApp dengan URL yang dibuat
            window.location.href = whatsappUrl;
            // const url = 'https://urlbae.com/api/url/add';
            // const apiKey = 'a3bd22f31dbb39f29cbeed820948e2ce2e28d959';
            // const payload = {
            //     url: longUrl
            // };

            // try {

            //     return this.$http
            //         .post(
            //             url, payload,
            //             {
            //                 timeout: 10000,
            //                 'headers': {
            //                     'Authorization': `Bearer ${apiKey}`,
            //                     'Content-Type': 'application/json',
            //                 }
            //             }
            //         )
            //         .then((response) => {
            //             console.log(response)
            //         }).catch(error => {

            //             console.log('Error:', error);

            //             if (error.code == "ECONNABORTED") {
            //                 this.error(this.$t("check_internet_connection_txt"));

            //             } else {

            //                 if (error.response) {
            //                     if (error.response.status === 422) {
            //                         this.errors = error.response.data.errors;
            //                         this.resultError(error.response.data.errors);
            //                     } else if (error.response.status === 500) {
            //                         this.$router.push('/server-error');
            //                     } else if (error.response.status === 401) {
            //                         this.$router.push("/authorized-error");
            //                     } else {
            //                         this.$router.push('/page-not-found');
            //                     }
            //                 }

            //             }
            //         });

                
            } catch (error) {
                console.error('Error:', error);
            }
        },
        detailcampaign(id) {
            if (this.detailUser.jenis_kelamin === null || this.detailUser.jenis_kelamin === undefined) {
                //  Route::get('/check-reg/{user_id}', 'UserController@checkReg');
                const baseURI = this.$settings.endPoint + "pengguna/check-reg/" + this.detailUser.user_id;
                // this.isLoading = true;
                return this.$http
                    .get(
                        baseURI,
                        { timeout: 10000 }
                    )
                    .then((response) => {
                        this.isLoading = false;
                        let cek = response.data.datas;
                        if (cek == 'ok') {
                            // let params = this.$onRandom(id);
                            // window.location.href = "/user/campaign-detail/" + params;
                            window.location.href = "/user/campaign-detail/" + id;
                        } else {
                            this.$swal({
                                title: 'Warning',
                                text: 'Mohon lengkapi terlebih dahulu profile anda',
                                showCancelButton: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                            }).then(() => {
                                window.location.href = "/user/profile/edit";

                            });

                        }

                    }).catch(error => {

                        if (error.code == "ECONNABORTED") {
                            this.eror(this.$t("check_internet_connection_txt"));

                        } else {

                            if (error.response) {
                                if (error.response.status === 422) {
                                    this.errors = error.response.data.errors;
                                    this.resultError(error.response.data.errors);
                                } else if (error.response.status === 500) {
                                    this.$router.push('/server-error');
                                } else if (error.response.status === 401) {
                                    this.$router.push("/authorized-error");
                                } else {
                                    this.$router.push('/page-not-found');
                                }
                            }

                        }
                    });

            } else {
                // let params = this.$onRandom(id);
                // window.location.href = "/user/campaign-detail/" + params;

                window.location.href = "/user/campaign-detail/" + id;

            }

        },
        refreshData() {
            this.loadCampaign();
        },
        riwayat() {
            this.$router.push("/user/campaign-history");
        },
        // getPoint() {
        //     const userDatas = this.$getUserInfo();
        //     var user_id = userDatas.sub.user_id;
        //     const baseURI = this.$settings.endPoint + "pengguna/detail/" + user_id;
        //     this.isLoading = true;
        //     return this.$http
        //         .get(
        //             baseURI, { timeout: 10000 }
        //         )
        //         .then((response) => {
        //             this.isLoading = false;
        //             this.points = response.data.datas;
        //         }).catch(error => {

        //             if (error.code == "ECONNABORTED") {
        //                 this.eror(this.$t("check_internet_connection_txt"));

        //             } else {

        //                 if (error.response) {
        //                     if (error.response.status === 422) {
        //                         this.errors = error.response.data.errors;
        //                         this.resultError(error.response.data.errors);
        //                     } else if (error.response.status === 500) {
        //                         this.$router.push('/server-error');
        //                     } else if (error.response.status === 401) {
        //                         this.$router.push("/authorized-error");
        //                     } else {
        //                         this.$router.push('/page-not-found');
        //                     }
        //                 }

        //             }
        //         });


        // },
        resetForm() {
            this.forms.produk = "";
            this.forms.produk_id = "";
            this.forms.point = "";
            this.forms.nama_lengkap = "";
            this.forms.wa = "";
            this.forms.alamat_lengkap = "";
        },
        // submitData() {
        //     this.isLoading = true
        //     let formData = new FormData();

        //     formData.append("produk", this.forms.produk);
        //     formData.append("produk_id", this.forms.produk_id);
        //     formData.append("point", this.forms.point);
        //     formData.append("nama_lengkap", this.forms.nama_lengkap);
        //     formData.append("wa", this.forms.wa);
        //     formData.append("alamat_lengkap", this.forms.alamat_lengkap);

        //     const baseURI = this.$settings.endPoint + "redeem-produk/create";
        //     this.$http
        //         .post(baseURI, formData, { timeout: 10000 })
        //         .then((response) => {
        //             this.isLoading = false
        //             if (response.data.status === 200) {
        //                 this.resetForm();
        //                 this.changeShow()
        //                 this.errors = [];
        //                 //console.log(response.data.datas.user_id);
        //                 // var params = this.$onRandom(response.data.datas.id);
        //                 // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
        //                 this.success('Berhasil');
        //             } else {
        //                 this.errors = response.data.errors;
        //                 this.resultError(response.data.errors);
        //             }
        //         }).catch(error => {
        //             this.isLoading = false;
        //             if (error.code == "ECONNABORTED") {
        //                 this.eror("Please Check Your Internet Connection");
        //             } else {

        //                 if (error.response) {
        //                     if (error.response.status === 422) {
        //                         this.errors = error.response.data.errors;
        //                         this.resultError(error.response.data.errors);
        //                     } else if (error.response.status === 500) {
        //                         this.eror(error.response.data.errors.message);
        //                     } else {
        //                         this.$router.push("/page-not-found");
        //                     }
        //                 }

        //             }


        //         });
        // },
        // changeShow() {
        //     if (this.show === false) {
        //         this.show = true
        //     } else {
        //         this.show = false
        //     }
        //     // this.slickOptions.autoplay = false
        // },
        // previousPage() {
        //     this.$router.go(-1);
        //     //window.history.back();
        // },
        // exchangePoints(productId, productNama, productPoint) {
        //     this.changeShow()
        //     this.forms.produk_id = productId
        //     this.forms.produk = productNama
        //     this.forms.point = productPoint
        // },
        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
        },
        loadCampaign() {
            const baseURI = this.$settings.endPoint + "campaign/select?search=" + this.search;
            // this.isLoading = true;
            return this.$http
                .get(
                    baseURI,
                    { timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.campaign = response.data.datas;
                    let checkUser = 0
                    this.campaign.forEach((item) => {
                        if (item.user.length > 0) {
                            item.user.forEach((row) => {
                                if (row.user_id == this.detailUser.user_id) {
                                    checkUser = 1;
                                }
                            });
                        }
                        item.statusDaftar = checkUser > 0 ? 1 : 0;
                        checkUser = 0;
                    });

                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.eror(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.eror(objectData[y]);
                }
            }
        },
        handleChange(code) {
            this.code = code;
        },
        refreshCaptchaCode() {
            this.$refs.captcha.refreshCaptcha();
        },
        makeOffer() {

        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        eror(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.loading();
        this.fetchIt();
        this.loadCampaign();
    },
};
</script>
<style scoped>
@import '../_partials/partial.css';

.detail-info {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    color: #295985;
}

.detail-poin {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #092641;
}

.detail-available {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #0c862b;
}

.section-home {
    padding: 16px 24px;
}

/*.section-home p {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;

    color: #295985;
    margin: 0px;
    padding: 16px 22px 0px 22px;
}*/

.section-home .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.section-home .header img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.campaign-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
    margin: 10px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    max-width: 100%;
    margin: 10px;
}

.campaign {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 5px;
    text-align: left;
    width: 100%;
    /* Adjust the width as needed */
    font-size: 13pt;
}

.campaign p {
    font-style: normal;
    /*font-weight: 900;*/
    font-size: 12px;
    line-height: 15px;
    margin: 5px;
    /* padding: 16px 22px 0px 22px; */
}

.title {
    font-weight: 300;
    color: #000000 !important;
}

.information {
    color: #a1a0a0 !important;
}

.bold {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #000000 !important;
}

.campaign img {
    max-width: 100%;
    height: 150px;
}

.campaign-name {
    font-size: 15px;
    color: #333;
    margin: 5px;
}

.campaign-stock {
    color: #2d2a43;
    margin: 5px 2px;
    font-size: 12px;
    line-height: 10;
}

.campaign-description {
    color: #666;
    line-height: 1.4;
}

.custom-button {
    margin: 2px;
    background-color: darkorchid;
    padding: 3px !important;
    color: aliceblue;
}

.tipe-button {
    margin: 2px;
    font-size: 9px !important;
    padding: 2px !important;
    background-color: rgb(39, 162, 45);
    color: aliceblue;
}
</style>