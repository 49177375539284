import Produk from "@/components/master/produk/Produk.vue";
import ProdukAdd from "@/components/master/produk/ProdukAdd.vue";
import ProdukDetail from "@/components/master/produk/ProdukDetail.vue";
import ProdukEdit from "@/components/master/produk/ProdukEdit.vue";
import ProdukUpload from "@/components/master/produk/ProdukUpload.vue";

let routesProduk= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "Produk",
        component: Produk
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "ProdukAdd",
        component: ProdukAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "ProdukEdit",
        component: ProdukEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "ProdukDetail",
        component: ProdukDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "ProdukUpload",
        component: ProdukUpload
      },
    
]

export default routesProduk
  