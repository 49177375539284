<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="EventAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Tambah Event
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>
                      
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">
                        Name</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="name" placeholder="name"
                          v-model="forms.name" required />
                        <div v-if="errors.name">
                          <div v-for="error in errors.name" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Poin</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="point" placeholder="poin"
                          v-model="forms.point" />


                        <div v-if="errors.point">
                          <div v-for="error in errors.point" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Type</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="number" class="form-control" id="id_type" placeholder="id_type" v-model="forms.id_type" />
                        <div v-if="errors.id_type">
                          <div v-for="error in errors.id_type" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Type</label
                        > 
                      </div>
                      <div class="col-lg-8">
                        
                          <v-select
                            :options="eventType"
                            v-model="modelEventType"
                            label="type"
                            return-object
                          ></v-select>

                        <div v-if="errors.id_type">
                          <div
                            v-for="error in errors.id_type"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Start Date</label>
                      </div>
                      <div class="col-lg-8">                          
                          <Datepicker v-model="forms.start_date" :bootstrap-styling=true format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                        <div v-if="errors.start_date">
                          <div v-for="error in errors.start_date" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">End Date</label>
                      </div>
                      <div class="col-lg-8">                          
                          <Datepicker v-model="forms.end_date" :bootstrap-styling=true format="dd-MM-yyyy" placeholder="Please Select Date..."></Datepicker>

                        <div v-if="errors.end_date">
                          <div v-for="error in errors.end_date" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Deskripsi</label>
                      </div>
                      <div class="col-lg-8">                          
                        <vue2-tinymce-editor v-model="forms.deskripsi"></vue2-tinymce-editor>

                        <div v-if="errors.deskripsi">
                          <div v-for="error in errors.deskripsi" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import myMixinAddress from '@/middleware/mixinAddress.js';
import Datepicker from "vuejs-datepicker";
import moment from 'moment'


export default {
  name: "EventAdd",
  props: {},
  mixins: [myMixinAddress],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      userData: "",
      forms: {},
      community:[],
      modelCommunity:[],
      eventType:[],
      modelEventType:[]
    };
  },
  watch: {},
  methods: {
    loadCommunity() {
      const baseURI = this.$settings.endPoint + "community";

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(
           baseURI,{ timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.eventType = response.data.datas.data;
          // this.totalRecords = response.data.datas.total;
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
            
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    loadEventType() {
      const baseURI = this.$settings.endPoint + "event-type";

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(
           baseURI,{ timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.eventType = response.data.datas.data;
          // this.totalRecords = response.data.datas.total;
        }).catch(error => {
           
          if(error.code == "ECONNABORTED"){
            this.error(this.$t("check_internet_connection_txt"));
            
          }else{

            if (error.response) {
              if(error.response.status === 422) {
                      this.errors = error.response.data.errors;
                      this.resultError(error.response.data.errors);
              }else if (error.response.status === 500) {
                  this.$router.push('/server-error');
              }else if (error.response.status === 401) {
                  this.$router.push("/authorized-error");
              }else{
                  this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    resetForm() {
      this.forms.name = "";
      this.forms.point = "";
      this.forms.id_type = "";
      this.forms.community_id = "";
      this.forms.start_date = "";
      this.forms.end_date = "";
    },

    backForm() {
      // window.location.href = "/event";
      this.$router.push("/event");
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    removeImages() {
      this.$refs.image = "";
      this.image_temporary = null;
    },

    handleFileUpload(event) {
      this.image = event.target.files[0];
      this.image_temporary = URL.createObjectURL(this.image);
    },
    submitData() {
      this.isLoading = true
      let formData = new FormData();

      Object.keys(this.forms).forEach((key) => {
        formData.append(key, this.forms[key]);
      });

      formData.append("community_id", this.modelEventType.community_id);
      formData.append("id_type", this.modelEventType.id);
      formData.append("start_date", moment(String(this.forms.start_date)).format('YYYY-MM-DD'));   
      formData.append("end_date", moment(String(this.forms.end_date)).format('YYYY-MM-DD'));   
      
      const baseURI = this.$settings.endPoint + "master-event/create";
      this.$http
        .post(baseURI, formData, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false
          if (response.data.status === 200) {
            this.errors = [];
            var params = this.$onRandom(response.data.datas.id);
            // window.location.href = "/event/detail/" + params + "?status=200&msg=Successfully";
            this.$router.push("/event/detail/" + params + "?status=200&msg=Successfully");
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        }).catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }

          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "EventController") {
          rolePermision = permision;
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadCommunity();
    this.loadEventType();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;

}
</style>