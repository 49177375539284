<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>   
    <div class="loadertext" v-if="this.isLoading"> {{$t('loadingTxt')}}...</div>   
    <div class="main-wrapper" :class="{'blur-content': this.isLoading}">
      <!--begin::sidebar-->
      <sidebar-component classMenu="ArticleEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Tambah Campaign
                  </h6>
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Logo Brand</label>
                      </div>
                      <div class="col-lg-10">
                        <div v-if="images_temporary" class="dropify-wrapper has-preview">
                          <div class="dropify-message">
                            <span class="file-icon" v-if="!images_temporary">
                              <p>Drag and drop a file here or click</p>
                            </span>
                            <p class="dropify-error">Ooops, something wrong appended.</p>
                          </div>
                          <div class="dropify-loader" style="display: none;"></div>
                          <div class="dropify-errors-container">
                            <ul></ul>
                          </div>
                          <input type="file" ref="images" accept="image/*" class="form-control"
                            @change="handleFileUpload($event)">
                          <button type="button" class="dropify-clear" @click="removeImages">Remove</button>
                          <div class="dropify-preview" style="display: block;">
                            <img :src="images_temporary" width="90%" height="80%" />
                            <span class="dropify-render"></span>
                            <div class="dropify-infos">
                              <div class="dropify-infos-inner">
                                <p class="dropify-filename">
                                </p>
                                <p class="dropify-infos-message">Drag and drop or click to replace</p>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div v-else class="dropify-wrapper">
                          <div class="dropify-message">
                            <span class="file-icon">
                              <p>Drag and drop a file here or click</p>
                            </span>
                          </div>
                          <div class="dropify-loader" style="display: none;"></div>
                          <div class="dropify-errors-container">
                            <ul></ul>
                          </div>
                          <input type="file" ref="images" accept="image/*" class="form-control"
                            @change="handleFileUpload($event)">
                          <button type="button" class="dropify-clear">Remove</button>
                          <div class="dropify-preview" style="display: none;">
                            <img :src="images_temporary" width="90%" height="80%" />
                            <span class="dropify-render"></span>
                            <div class="dropify-infos">
                              <div class="dropify-infos-inner">
                                <p class="dropify-filename">
                                </p>
                                <p class="dropify-infos-message">Drag and drop or click to replace</p>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div v-if="errors.images">
                          <div v-for="error in errors.images" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Name</label>
                      </div>

                      <div class="col-lg-10">
                        <input type="text" class="form-control" id="name" placeholder="name" v-model="forms.name"
                          required />
                        <div v-if="errors.name">
                          <div v-for="error in errors.name" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Category</label>
                      </div>
                      <div class="col-lg-10">
                        <!-- <input type="text" class="form-control" id="category" placeholder="category"
                          v-model="forms.category" /> -->
                        <v-select v-model="selectedCategories" :options="categories" label="Select Categories" multiple
                          taggable @input="handleInput"></v-select>


                        <div v-if="errors.category">
                          <div v-for="error in errors.category" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Tipe Project</label>
                      </div>
                      <div class="col-lg-10">
                        <v-select v-model="selectedProject" :options="projects" label="Select Projects" multiple
                          taggable @input="handleInput"></v-select>
                        <div v-if="errors.tipe_project">
                          <div v-for="error in errors.tipe_project" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Platform</label>
                      </div>
                      <div class="col-lg-10">
                        <v-select v-model="selectedPlatform" :options="platforms" label="Select Platforms" multiple
                          taggable @input="handleInput"></v-select>
                        <div v-if="errors.tipe_project">
                          <div v-for="error in errors.tipe_project" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Whatsapp
                          Group</label>
                      </div>

                      <div class="col-lg-10">
                        <input type="text" class="form-control" id="whatsapp_group" placeholder="whatsapp_group"
                          v-model="forms.whatsapp_group" />
                        <div v-if="errors.whatsapp_group">
                          <div v-for="error in errors.whatsapp_group" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Kuota</label>
                      </div>

                      <div class="col-lg-4">
                        <input type="number" class="form-control" id="kuota" placeholder="kuota" v-model="forms.kuota"
                          required />
                        <div v-if="errors.kuota">
                          <div v-for="error in errors.kuota" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Storyline?</label>
                      </div>
                      <div class="col-lg-4">
                        <select class="form-control" name="is_storyline" id="is_storyline" v-model="forms.is_storyline">
                          <option value=1>Ya</option>
                          <option value=0>Tidak</option>
                        </select>
                        <div v-if="errors.is_storyline">
                          <div v-for="error in errors.is_storyline" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Start
                          Registrasi</label>
                      </div>
                      <div class="col-lg-4">
                        <Datepicker v-model="forms.start_reg" :bootstrap-styling=true format="dd-MM-yyyy"
                          placeholder="Please Select Date..."></Datepicker>

                        <div v-if="errors.start_reg">
                          <div v-for="error in errors.start_reg" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">End
                          Registrasi</label>
                      </div>
                      <div class="col-lg-4">
                        <Datepicker v-model="forms.end_reg" :bootstrap-styling=true format="dd-MM-yyyy"
                          placeholder="Please Select Date..."></Datepicker>

                        <div v-if="errors.end_reg">
                          <div v-for="error in errors.end_reg" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>




                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Benefit Point</label>
                      </div>
                      <div class="col-lg-4">
                        <input type="number" class="form-control" id="benefit_point" placeholder="benefit_point"
                          v-model="forms.benefit_point" required />

                        <div v-if="errors.benefit_point">
                          <div v-for="error in errors.benefit_point" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Benefit Fee</label>
                      </div>
                      <div class="col-lg-4">
                        <input type="number" class="form-control" id="benefit_fee" placeholder="benefit_fee"
                          v-model="forms.benefit_fee" required />

                        <div v-if="errors.benefit_fee">
                          <div v-for="error in errors.benefit_fee" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Deskripsi</label>
                      </div>
                      <div class="col-lg-10">
                        <!-- <vue2-tinymce-editor v-model="forms.description"></vue2-tinymce-editor> -->
                        <textarea class="form-control" id="description" rows="3" v-model="forms.description"></textarea>

                        <div v-if="errors.description">
                          <div v-for="error in errors.description" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Kriteria</label>
                      </div>
                      <div class="col-lg-10">
                        <!-- <vue2-tinymce-editor v-model="forms.kriteria"></vue2-tinymce-editor> -->
                        <textarea class="form-control" id="kriteria" rows="3" v-model="forms.kriteria"></textarea>
                        <div v-if="errors.kriteria">
                          <div v-for="error in errors.kriteria" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">SOW Campaign</label>
                      </div>
                      <div class="col-lg-10">
                        <!-- <vue2-tinymce-editor v-model="forms.sow_campaign"></vue2-tinymce-editor> -->
                        <textarea class="form-control" id="sow_campaign" rows="3" v-model="forms.sow_campaign"></textarea>

                        <div v-if="errors.sow_campaign">
                          <div v-for="error in errors.sow_campaign" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Product
                          Knowledge</label>
                      </div>
                      <div class="col-lg-10">
                        
                        <textarea class="form-control" id="product_knowledge" rows="3"
                          v-model="forms.product_knowledge"></textarea>

                        <div v-if="errors.product_knowledge">
                          <div v-for="error in errors.product_knowledge" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="row card">
                      <div class="card-header">
                        <h5 class="float-start">
                          Pertanyaan <span class="wajib">*</span>
                        </h5>

                        <button class="
                            btn btn-success btn-icon
                            text-white
                            fw-bolder
                            btn-icon-text
                            float-end
                          " @click="addField()" type="button">
                          <i class="link-icon" data-feather="plus"></i>
                        </button>
                      </div>

                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table" style="height: 100px">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th style="width:60%">Pertanyaan</th>
                                <th style="width:10%">Tipe</th>
                                <th style="width:30%">Jawaban</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr v-for="(input, index) in this.detailAll" :key="`phoneInput-${index}`">
                                <th>{{ index + 1 }}</th>
                                <td>
                                  <input type="text" class="form-control" id="question" placeholder="question"
                                    v-model="input.question" required />
                                </td>

                                <td>
                                  <select class="form-control" v-model="input.tipe">
                                    <option value="date">DATE</option>
                                    <option value="file">FILE</option>
                                    <option value="select">SELECT</option>
                                    <option value="text">TEXT</option>                                    
                                  </select>
                                </td>

                                <td>
                                  <input type="text" class="form-control" id="answer" placeholder="answer"
                                    v-model="input.answer" />
                                </td>

                                <td>
                                  <!--          Remove Svg Icon-->
                                  <svg @click="removeField(index, detailAll)" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24" width="24" height="24" class="ml-2 cursor-pointer">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="red"
                                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                  </svg>
                                </td>
                              </tr>
                            </tbody>

                            <tfoot></tfoot>
                          </table>
                        </div>
                      </div>
                    </div>







                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import Datepicker from "vuejs-datepicker";
import moment from 'moment'

export default {
  name: "campaignEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker
  },
  data() {
    return {
      selectedCategories: [],
      categories: ['Entertainment', 'Music', 'Profession','Education', 'Comedy', 'Sport', 
      'Health', 'Finance' , 'Cooking','Foodies' , 'Travel', 'Home & Decor', 'Fashion', 'Beauty', 'Family', 'Parenting', 'Mom & Kid'],
      selectedProject: [],
      projects: ['Product Review', 'RTP', 'Offline Visit', 'Partisipan Webinar/Live Event', 'Partisipan Kuliah Whatsapp (Kulwap)','Buzzer Sosial Media' ],
      selectedPlatform: [],
      platforms: ['Facebook', 'Instagram', 'Tik Tok', 'Shopee','Tokopedia','Youtube','Website/Artikel','Whatsapp','Twitter/X'],
      tipes:["Date","Text","Select"],
      permision_role:[],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      forms: [],
      detailAll: [],
      images: '',
      images_temporary: null,
    };
  },
  watch: {},
  methods: {
    removeImages() {
      this.$refs.image.value = "";
      this.images_temporary = null;
    },

    handleFileUpload(event) {
      this.image = event.target.files[0];
      this.images_temporary = URL.createObjectURL(this.image);
    },

    resetForm() {
      this.forms.title = "";
      this.forms.link = "";
      this.forms.priority = "";
      this.removeImages();
    },

    backForm() {
      window.location.href = "/campaign";
    },

    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPoint + "campaign/detail/" + id;
      return this.$http.get(baseURI,{ timeout: 10000 }).then((response) => {
          this.isLoading = false        
          this.forms    = response.data.datas;
          this.detailAll = response.data.datas.question;
          this.images_temporary = response.data.datas.logo_brand;

          this.selectedCategories = response.data.datas.category.split(',');
          this.selectedProject = response.data.datas.tipe_project.split(',');
          this.selectedPlatform = response.data.datas.platform.split(',');


      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },

    fetchIt() {
      const userDatas     = this.$getUserInfo();
      this.detailUser     = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function(permision){
        if(permision.permission.controller == 'CampaignController'){
          rolePermision = permision;
        }
      });

      if(!rolePermision.method_update){
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    submitData() {
      this.isLoading = true
      let formData = new FormData();
      formData.append("image", this.images);
      formData.append("name", this.forms.name);
      formData.append("category", this.selectedCategories.join(','));
      formData.append("tipe_project", this.selectedProject.join(','));
      formData.append("platform", this.selectedPlatform.join(','));
      formData.append("kuota", this.forms.kuota);
      formData.append("start_reg", moment(String(this.forms.start_reg)).format('YYYY-MM-DD'));
      formData.append("end_reg", moment(String(this.forms.end_reg)).format('YYYY-MM-DD'));
      formData.append("description", this.forms.description);
      formData.append("kriteria", this.forms.kriteria);
      formData.append("benefit_point", this.forms.benefit_point);
      formData.append("benefit_fee", this.forms.benefit_fee);
      formData.append("sow_campaign", this.forms.sow_campaign);
      formData.append("whatsapp_group", this.forms.whatsapp_group);
      // formData.append("product_knowledge", this.forms.product_knowledge);
      formData.append("is_storyline", this.forms.is_storyline); 
      this.detailAll.forEach((question, index) => {
        formData.append(`questions[${index}][question]`, question.question);
        formData.append(`questions[${index}][tipe]`, question.tipe);
        formData.append(`questions[${index}][answer]`, question.answer);
      });
      const baseURI = this.$settings.endPoint + "campaign/update/" + this.forms.campaign_id;
      this.$http.post(baseURI, formData,{ timeout: 10000 }).then((response) => {
        this.isLoading = false
        this.errors = [];
        this.success(response.data.datas.messages);

        //window.history.back();
        var params = this.$onRandom(this.forms.campaign_id);
        window.location.href = "/campaign/detail/" + params + "?status=200&msg=Successfully";
      }).catch(error => {
          this.isLoading = false;
          if(error.code == "ECONNABORTED"){
            this.error("Please Check Your Internet Connection");
          }else{

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
            
          }


        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content{
  filter: blur(5px); 
  pointer-events: none;
}
</style>