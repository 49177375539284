import EventForm from "@/components/transaction/event-form/EventForm.vue";
import EventFormAdd from "@/components/transaction/event-form/EventFormAdd.vue";
import EventFormDetail from "@/components/transaction/event-form/EventFormDetail.vue";
import EventFormEdit from "@/components/transaction/event-form/EventFormEdit.vue";
import EventFormUpload from "@/components/transaction/event-form/EventFormUpload.vue";

let routesEventForm= [
    {
        path: '',
        meta: {
          title: "BERT",
        },
        name: "EventForm",
        component: EventForm
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "EventFormAdd",
        component: EventFormAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "EventFormEdit",
        component: EventFormEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "EventFormDetail",
        component: EventFormDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "EventFormUpload",
        component: EventFormUpload
      },
    
]

export default routesEventForm
  