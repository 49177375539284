<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Feedback Revisi Draft</h5>


          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <form class="forms-sample" method="POST" @submit.prevent="submitData">
          <div class="modal-body">

            <div class="row mb-3">
              <div class="col-lg-2">
                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Feedback</label>
              </div>
              <div class="col-lg-10">
                <input type="text" class="form-control" v-model="feedback" />
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$emit('closeModal')">
              {{ $t("close_txt") }}
            </button>
            <button class="btn btn-danger" type="submit" @click.prevent="reject()">Submit Revisi</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  name: "ModalEventForm",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
    status: String,
  },
  watch: {
    showModal: {

      handler(newVal) {
        this.active = newVal;
        const body = document.querySelector("body");
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      feedback: '',
      isLoading: false,
      active: this.showModal,
      forms: [],
      errors: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {
      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>CANCELLING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },

    submitData() {
      var baseURI = "";
      baseURI = this.$settings.endPoint + "peminjaman/pengembalian/";
      this.$http
        .post(baseURI, null, { timeout: 30000 })
        .then((response) => {
          this.isLoading = false;
          this.errors = [];
          this.$emit("closeModal");
          this.success2(response.data.datas.messages);
        })
        .catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });



    },

    reject() {
      var baseURI = "";
      baseURI = this.$settings.endPoint + "campaign-user/update-status";      
      let data = {
            campaign_user_ids: this.data,
            status: 'DRAFT PERLU REVISI',
            feedback_draft: this.feedback
          }
      this.$http
        .post(baseURI, data, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;
          this.errors = [];
          this.$emit("closeModal");
          this.success2(response.data.datas.messages);
        })
        .catch(error => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });



    },

  },
  mounted() {

  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>