<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading"> {{ $t('loadingTxt') }}...</div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="EventTypeDetail"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    Detail Event Type
                  </h6>

                  <button class="btn btn-light float-end" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <i class="link-icon" data-feather="info"></i>
                    Audit Info
                  </button>

                  <div class="row mb-3 collapse" id="collapseExample" style="padding-left: 20px">
                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created By:</label>
                      <p class="text-muted">{{ this.forms.created_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Created At:</label>
                      <p class="text-muted">{{ this.forms.created_at }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated By:</label>
                      <p class="text-muted">{{ this.forms.updated_by }}</p>
                    </div>

                    <div class="col-lg-3">
                      <label class="tx-11 fw-bolder mb-0">Updated At:</label>
                      <p class="text-muted">{{ this.forms.updated_at }}</p>
                    </div>
                  </div>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->
                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Type</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="type" placeholder="type" v-model="forms.type" readonly
                          required />
                        <div v-if="errors.type">
                          <div v-for="error in errors.type" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Community</label>
                      </div>
                      <div class="col-lg-8">

                        <v-select disabled :options="community" v-model="modelCommunity" label="community"
                          return-object></v-select>

                        <div v-if="errors.community_id">
                          <div v-for="error in errors.community_id" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      <a v-if="permision_role.method_delete" class="
                          btn btn-danger
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="deleteData()">
                        <i class="link-icon" data-feather="trash"></i>
                        {{ $t("deleteMess") }}
                      </a>

                      <a v-if="permision_role.method_update" class="
                          btn btn-primary
                          me-2
                          text-white
                          fw-bolder
                          btn-icon-text
                          float-end
                        " @click="editData()">
                        <i class="link-icon" data-feather="edit"></i>
                        {{ $t("editMess") }}
                      </a>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";

export default {
  name: "EventDetail",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
  },
  data() {
    return {
      permision_role: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      userData: "",
      forms: [],
      community: [],
      modelCommunity: []
    };
  },
  watch: {},
  methods: {
    loadCommunity() {
      const baseURI = this.$settings.endPoint + "community";

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(
          baseURI, { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.community = response.data.datas.data;
          // this.totalRecords = response.data.datas.total;
        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    isNumberLatLong(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    cek_message(status, msg) {
      if (status == "200") {
        this.success(msg);
      }
    },

    editData() {
      var params = this.$route.params.id;
      window.location.href = "/event-type/edit/" + params;
    },

    deleteData() {
      var id = this.$onBehind(this.$route.params.id);
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("yourDataDelete"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          var formData = {
            status: status,
          };
          const baseURI = this.$settings.endPoint + "event-type/delete/" + id;
          this.$http
            .delete(baseURI, formData)
            .then((response) => {
              this.loading();
              if (response.data.status === 200) {
                this.success(response.data.datas.messages);
                window.location.href = "/event-type";
              } else {
                this.errors = response.data.errors;
                this.resultError(response.data.errors);
              }
            })
            .catch((error) => {
              this.loading();
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            });
        }
      });
    },

    backForm() {
      window.location.href = "/event-type";
    },

    loadData(id) {
      this.isLoading = true
      const baseURI = this.$settings.endPoint + "event-type/detail/" + id;
      return this.$http.get(baseURI, { timeout: 10000 }).then((response) => {
        this.isLoading = false
        this.forms = response.data.datas;
        this.modelCommunity = response.data.datas.community;
      }).catch(error => {
        this.isLoading = false;
        if (error.code == "ECONNABORTED") {
          this.error("Please Check Your Internet Connection");
        } else {

          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }

        }


      });
    },


    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == 'EventTypeController') {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_read) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;

    },


    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.cek_message(this.$route.query.status, this.$route.query.msg);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadCommunity();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}
</style>