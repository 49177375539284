<template>
    <div>
        <div v-if="isLoading">
            <!--begin::Loading Content-->
            <div class="main-wrapper">
                <div class="page-wrapper full-page">
                    <div class="container">
                        <div class="row">
                            <!--begin::Card-->
                            <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                                <div class="overlay-layer card-rounded bg-opacity-5">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Card-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Loading Content-->
        </div>
        <div v-else>
            <div class="App">
                <div class="navbars-body overlay-container">

                    <!-- 
                import Header from '../../../assets/header_reward.png';
                import Arrow from '../../../assets/arrow_white.png';
                import IconPoint from '../../../assets/point_icon.png';
                import DefaultPicture from '../../../assets/maternity.png';
                import YoutubeIcon from '../../../assets/youtube_icon.png';
                import WhatsappIcon from '../../../assets/whatsapp_icon.png';
                import InternetIcon from '../../../assets/internet_icon.png';
                import DoneIcon from '../../../assets/done_icon.png';
                import SearchIcon from '../../../assets/search_icon.png';
                import CloseIcon from '../../../assets/close_icon.png'; -->

                    <img class='header-reward' src='/assets/images/header_reward.png' alt='Header' />
                    <div class='section-reward'>
                        <div class='toolbar'>
                            <img src='/assets/images/arrow_white.png' alt='Arrow' @click='previousPage' />
                            <p>Detail Poin</p>
                        </div>

                        <div class='profile'>
                            <div>
                                <!-- <img src='/assets/images/maternity.png' alt='Profile' /> -->
                                <img v-if="!detailUser.image" src="/assets/images/maternity.png" alt="Profile" />
                                <img v-else :src="detailUser.image" alt="Profile" />
                                <p>
                                    {{ detailUser.community.community }}<br />
                                    <b>{{ detailUser.name }}</b>
                                </p>
                            </div>

                            <div>
                                <img src='/assets/images/point_icon.png' alt='Profile' />
                                <p>
                                    Total Poin<br />
                                    <b v-if="!forms">0</b>

                                    <b v-else>{{ typeof forms[0].user.point !== 'undefined' ?
            forms[0].user.point : detailUser.point }}</b>
                                </p>
                            </div>
                        </div>


                        <div v-if="!forms" class='item'>
                            No Data
                        </div>

                        <div v-for="(form, index) in forms" :key="form.id" class='item'>
                            <img src='/assets/images/internet_icon.png' alt='Event Icon' />
                            <div class='detail'>
                                <div class='content'>
                                    <div>
                                        <div class='text'>
                                            <div class='title'>{{ form.event.type.type }}</div>
                                            <div><b>{{ form.event.name }}</b></div>
                                        </div>
                                        <div class="status"
                                            :class="form.status == 'valid' ? 'status-valid' : (form.status == 'invalid' ? 'status-invalid' : 'status-pending')">

                                            <!-- {`status status-${form.status}`}> -->
                                            <!-- <img :src="form.status == 'valid' ? '/assets/images/done_icon.png' : (form.status == 'invalid' ? '/assets/images/close_icon.png':'/assets/images/pending.png')"
                                                alt='Status Icon' /> -->
                                            <img :src="form.status == 'valid' ? '/assets/images/done_icon.png' :
            form.status == 'invalid' ? '/assets/images/close_icon.png' :
                form.status == 'expired' ? '/assets/images/expired_icon.png' :
                    '/assets/images/pending.png'" alt='Status Icon' />
                                            <div>{{ form.status }}</div>

                                        </div>
                                        <span style="color:darkgrey">Expiry On </span><span>{{ getExpiryDate(form.date)
                                            }}</span>
                                    </div>

                                    <!-- <div v-if="form.status !== 'invalid'" class='point'
                                        :class="form.status == 'valid' ? 'point-valid' : 'point-pending'"> -->
                                    <div v-if="form.status !== 'invalid'" class='point' :class="{
            'point-valid': form.status == 'valid',
            'point-pending': form.status == 'pending',
            'point-expired': form.status == 'expired'
        }"> {{ form.point }}</div>


                                </div>
                                <div v-if="index < (forms.length - 1)" class='divider' />
                            </div>
                        </div>

                    </div>

                </div>

                <navbar-component page="home" />

            </div>


        </div>
    </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";

export default {
    name: "HomeUser",
    components: {
        "navbar-component": navbarComponent,
    },
    data() {
        return {
            code: "",
            locale: "",
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            isLoading: false,
            detailUser: [],
            errors: [],
            forms: []
        };
    },
    watch: {},
    methods: {
        // getExpiryDate(date) {
        //     if (!date) return '';

        //     const inputDate = new Date(date);

        //     // Check if form.date is less than or equal to 30 September 2024
        //     const cutoffDate = new Date('2024-08-31');
        //     if (inputDate <= cutoffDate) {
        //         return '01 November 2024';
        //     }

        //     // Add 2 months to the date
        //     inputDate.setMonth(inputDate.getMonth() + 2);

        //     // Format as 'DD Month YYYY'
        //     const options = { day: '2-digit', month: 'long', year: 'numeric' };
        //     return inputDate.toLocaleDateString('id-ID', options); // Outputs as 'DD Month YYYY'
        // },
        getExpiryDate(date) {
            if (!date) return '';

            if (this.detailUser.community_id == 12 || this.detailUser.community_id ==13){
                const givenDate = new Date(date);
                const nextYear = givenDate.getFullYear() + 1;
                
                // Mengatur tanggal menjadi 1 Januari di tahun berikutnya
                return new Date(nextYear, 0, 1);
            }

            const inputDate = new Date(date);

            // Check if form.date is less than or equal to 30 September 2024
            const cutoffDate = new Date('2024-09-31');
            if (inputDate <= cutoffDate) {
                return '01 November 2024';
            }

            // Tambahkan 2 bulan ke tanggal input
            inputDate.setMonth(inputDate.getMonth() + 2);

            // Set hari ke tanggal 1 di bulan tersebut
            inputDate.setDate(1);

            // Format hasil sebagai 'DD Month YYYY'
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            return inputDate.toLocaleDateString('id-ID', options); // Output dalam format 'DD Month YYYY'
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },
        loadEventForm() {
            const baseURI = this.$settings.endPoint + "event-form/select";
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI, { timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.forms = response.data.datas;
                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        previousPage() {
            this.$router.go(-1);
            //window.history.back();
        },
        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            // let rolePermision = [];
            // userDatas.sub.permision_role.forEach(function(permision){
            //   if(permision.permission.controller == 'EventController'){
            //     rolePermision = permision;
            //   }
            // });

            // if(!rolePermision.method_read){
            //   this.$router.push("/authorized-error");
            // }
            // this.permision_role = rolePermision;


        },
        handleChange(code) {
            this.code = code;
        },
        refreshCaptchaCode() {
            this.$refs.captcha.refreshCaptcha();
        },
        makeOffer() {

        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.loading();
        this.fetchIt();
        this.loadEventForm();
    },
};
</script>
<style scoped>
@import '../_partials/partial.css';
</style>