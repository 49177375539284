<template>
  <div>
    <carousel :perPage="1" :autoplay="true" :loop="true" :adjustableHeight="true" :navigationEnabled="false">
      <slide v-for="(item, index) in rows" :key="index">
        <!-- Your carousel content here -->
        <a :href="item.link">
        <img :src="item.image" alt="Carousel Item" style="width:100%;height:200px">
        </a>
        <!--div>{{ item.title }}</div-->
      </slide>
    </carousel>
    <!-- 
    <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div v-for="(item, index) in rows" :key="index" :class="index === 0 ? 'carousel-item active' : 'carousel-item'">
            <img class="d-block w-100" :src="item.image" :alt="item.image" />
        </div>
      </div>
      <a class="carousel-control-prev" data-bs-target="#carouselExampleControls" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next" data-bs-target="#carouselExampleControls" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>     -->

  </div>
</template>


<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: 'articleUser',
  props: {
  },
  components: {
    Carousel, Slide
  },
  data() {
    return {
      rows: {}
    }
  },
  watch: {

  },
  methods: {
    error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },
    // startAutoplay() {
    //   setInterval(() => {
    //     const carousel = this.$refs.myCarousel;
    //     const currentPage = carousel.currentPage;

    //     // Check if it's the last slide
    //     if (currentPage === this.rows.length - 1) {
    //       // Go back to the first slide
    //       carousel.goToPage(0);
    //     } else {
    //       // Go to the next slide
    //       carousel.next();
    //     }
    //   }, 5000); // Set the interval as needed (in milliseconds)
    // },
    loadItems() {
      const baseURI = this.$settings.endPoint + "get-banner";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=10&page=1&sort_field=&sort_type=&title=&link=&priority=`,
          {
            timeout: 10000,
            'headers': {
              'AppToken': this.$settings.jwtSecret,
            }
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas;
        }).catch(error => {


          console.log('Error:', error);

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },


  },
  events: {

  },
  created: function () {

  },
  mounted() {
    this.loadItems();
    // this.startAutoplay();

  }

}
</script>
<style scoped></style>