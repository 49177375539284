import CampaignUser from "@/components/transaction/campaign-user/CampaignUser.vue";
import CampaignUserAdd from "@/components/transaction/campaign-user/CampaignUserAdd.vue";
import CampaignUserDetail from "@/components/transaction/campaign-user/CampaignUserDetail.vue";
import CampaignUserEdit from "@/components/transaction/campaign-user/CampaignUserEdit.vue";
import CampaignUserUpload from "@/components/transaction/campaign-user/CampaignUserUpload.vue";

let routesCampaignUser= [
    {
        path: ':id',
        meta: {
          title: "BERT",
        },
        name: "CampaignUser",
        component: CampaignUser
      },
      {
        path: 'add',
        meta: {
          title: "BERT",
        },
        name: "CampaignUserAdd",
        component: CampaignUserAdd
      },
    
      {
        path: 'edit/:id',
        meta: {
          title: "BERT",
        },
        name: "CampaignUserEdit",
        component: CampaignUserEdit
      },
    
      {
        path: 'detail/:id',
        meta: {
          title: "BERT",
        },
        name: "CampaignUserDetail",
        component: CampaignUserDetail
      },
    
      {
        path: 'upload',
        meta: {
          title: "BERT",
        },
        name: "CampaignUserUpload",
        component: CampaignUserUpload
      },
    
]

export default routesCampaignUser
  