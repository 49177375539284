<template>
    <div>
        <div class="footer-user overlay-container">
            <img class='background' style="border-radius: 50%;"  src="/assets/images/footer_bg.png" alt='Footer Background'/>
            <div class='content'>
              <!-- <p>FOR BUSINESS INQUIRIES</p> -->
        
              <!--div class='contact'>
                <!- <Link to='/' class='box-logo'> ->
                    <img class='logo' src="/assets/images/logo.png" alt="Logo"/>
                <!- </Link> ->
                <div class='contact-info'>
                  <a href="" target='_blank' rel="noreferrer">
                    <b>Email</b><br/>busdev@bertsolution.com
                  </a>
                </div>
                <div class='divider' />

                <div class='contact-info'>
                  <a href="https://wa.me/6285804880519?text=Halo%2C%20saya%20ingin%20bekerjasama%20dengan%20Ibu2Canggih" target='_blank' rel="noreferrer">
                    <b>Whatsapp</b><br/>(+62) 813-2366-3240
                  </a>
                </div>
              </div-->
        
              <div class='socmed'>
                <a href="https://www.linkedin.com/company/berts-solution/" target='_blank' rel="noreferrer">
                  <img src="/assets/images/Linkedin.png" alt="Linkedin"/>
                </a>
                <a href="https://wa.me/6281323663240?text=Halo%2C%20saya%20ingin%20bekerjasama%20dengan%20Berts%20Community" target='_blank' rel="noreferrer">
                  <img src="/assets/images/Whatsapp.png" alt="Whatsapp"/>
                </a>
                <a href="https://instagram.com/berts_id" target='_blank' rel="noreferrer">
                  <img src="/assets/images/Instagram.png" alt="Instagram"/>
                </a>
                <a href="mailto:busdev@bertsolution.com" target='_blank' rel="noreferrer">
                  <img src="/assets/images/Email.png" alt="Email"/>
                </a>
              </div>
              
              <div class='copyright'>©2023 Bert's</div>
            </div>
          </div>

    </div>
</template>


<script>

export default {
    name: 'articleUser',
    props: {
    },
    components: {
    },
    data() {
        return {

        }
    },
    watch: {

    },
    methods: {



    },
    events: {

    },
    created: function () {

    },
    mounted() {


    }

}
</script>
<style scoped>
@import 'partial.css';
</style>