export default {
  methods: {
    postalCodeChange(ev) {

      this.country = ev.country;
      this.province = ev.province;
      this.city = ev.city;
      this.district = ev.district;
      this.sub_district = ev.sub_district;
      this.city_type = ev.city_type;
      this.postal_code=ev.postal_code;
      let district = this.district;
      const baseURI = this.$settings.endPoint;
      this.$http.get(baseURI+"postal-code/select-sub-district/"+district).then((response) => {
        this.featchSubDistrictCode = response.data.datas;
      });

      let city = this.city;
      this.$http.get(baseURI+ "postal-code/select-district/"+city).then((response) => {
        this.featchDistrictCode = response.data.datas;
      });

      let province = this.province;
      this.$http.get(baseURI+ "postal-code/select-city/"+province).then((response) => {
        this.featchCityCode = response.data.datas;
      });
      
      let country = this.country;
      return this.$http.get(baseURI + "postal-code/select-province/"+country).then((response) => {
        this.featchProvinceCode = response.data.datas;
      });


    },


    contryCodeChange() {
      let country = this.country.country;
      const baseURI = this.$settings.endPoint + "postal-code/select-province/"+country;
      return this.$http.get(baseURI).then((response) => {
        this.featchProvinceCode = response.data.datas;
        //this.countryCode=[];
        this.province=[];
        this.city=[];
        this.district=[];
        this.sub_district=[];
        this.postal_code=[];
        this.city_type="";

      });
      
    },

    provinceCodeChange() {
      let province = this.province.province;
      const baseURI = this.$settings.endPoint + "postal-code/select-city/"+province;
      return this.$http.get(baseURI).then((response) => {
      
        this.featchCityCode = response.data.datas;
          //this.countryCode=[];
        //this.provinceCode=[];
        this.city=[];
        this.city_type="";
        this.district=[];
        this.sub_district=[];
        this.postal_code=[];
        

      });
      
    },


    cityCodeChange() {
      let city = this.city.city;
      const baseURI = this.$settings.endPoint + "postal-code/select-district/"+city;
      return this.$http.get(baseURI).then((response) => {
      
        this.featchDistrictCode = response.data.datas;
             //this.countryCode=[];
        //this.provinceCode=[];
        //this.cityCode=[];
        this.city_type="";
        this.district=[];
        this.sub_district=[];
        this.postal_code=[];

      });
      
    },

    districtCodeChange() {
      let district = this.district.district;
      console.log(district);
      const baseURI = this.$settings.endPoint + "postal-code/select-sub-district/"+district;
      return this.$http.get(baseURI).then((response) => {
      
        this.featchSubDistrictCode = response.data.datas;
             //this.countryCode=[];
        //this.provinceCode=[];
        //this.cityCode=[];
        //this.districtCode=[];
        this.sub_district=[];
        this.postal_code=[];
      });
      
    },


    subDistrictCodeChange() {
      let sub_district = this.sub_district.sub_district;
      const baseURI = this.$settings.endPoint + "postal-code/select-postal-code/"+sub_district;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas.postal_code);
        this.fetchPostalCode=response.data.datas;
             //this.countryCode=[];
        //this.provinceCode=[];
        //this.cityCode=[];
        //this.districtCode=[];
        //this.subDistrictCode=[];
        this.postal_code=[];
      });
      
    },

    loadPostalCode() {
      const baseURI = this.$settings.endPoint + "postal-code";
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
      });
    },

    loadCountryCode() {
      const baseURI = this.$settings.endPoint + "postal-code/select-country";
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.fetchCountryCode=response.data.datas;
      });
    },

    loadProvinceCode(country) {
      const baseURI = this.$settings.endPoint + "postal-code/select-province/"+country;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.featchProvinceCode=response.data.datas;
      });
    },


    loadCityCode(province) {
      const baseURI = this.$settings.endPoint + "postal-code/select-city/"+province;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.featchCityCode=response.data.datas;
      });
    },


    loadDistrictCode(city) {
      const baseURI = this.$settings.endPoint + "postal-code/select-district/"+city;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.featchDistrictCode=response.data.datas;
      });
    },


    loadSubDistrictCode(district) {
      const baseURI = this.$settings.endPoint + "postal-code/select-sub-district/"+district;
      return this.$http.get(baseURI).then((response) => {
        //console.log(response.data.datas);
        this.featchSubDistrictCode=response.data.datas;
      });
    },

    loadPostalCodeOne(sub_district) {
      const baseURI = this.$settings.endPoint + "postal-code/select-postal-code/"+sub_district;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas;
      });
    },


    asyncGeneratePostalCode(ev){
      console.log(ev);
      const baseURI = this.$settings.endPoint + "postal-code?postal_code="+ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchPostalCode = response.data.datas.data;
      });
    },

  },
};
