<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">

        <div class="navbars-body">
          <div v-if="!show" class="section-calendar">
            <p>All Events</p>
            <div v-for="(item, index) in rows" :key="index">
              <div class="community"><b>{{ item.community }}</b></div>
              <div class="divider" />
              <div class="item">
                <div class="date">
                  <b>{{ formattedDate(item.start_date) }}</b><br />
                  {{ formattedMonth(item.start_date) }}
                </div>
                <div class="event">
                  <div><b>{{ item.type.type }}</b></div>
                  {{ item.name }}
                  <div class='see-full'>
                    <a href="#" @click="changeShow(index)">
                      <div>Selengkapnya</div>
                    </a>
                    <img src="/assets/images/arrow_purple.png" alt="Arrow" />
                  </div>
                </div>
              </div>
              <!--div v-if="index < (rows.length - 1)" class="divider" /-->
            </div>
          </div>

          <div v-if="show" class="article-container">  
            <h1 class="article-title">{{ eventDetail.name }}</h1>
            <div class="article-details">                      
              <p class="article-content" v-html="eventDetail.deskripsi"></p>
              <p class="article-dates">Mulai: {{ eventDetail.start_date }} | Berakhir: {{ eventDetail.end_date }}</p>
              <p class="article-points"><button class="btn btn-sm btn-outline-warning"> Nilai Point: {{ eventDetail.point }}</button></p>
              <br/><br/>
              <button class="btn btn-sm btn-primary" @click="hide">Kembali</button>
              
            </div>
          </div>
        </div>

        <navbar-component page="event" />

      </div>

    </div>


  </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";

export default {
  name: "EventUser",
  components: {
    "navbar-component": navbarComponent,
  },
  data() {
    return {
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      detailUser: [],
      rows: [],
      eventDetail: [],
      show: false
    };
  },
  watch: {},
  methods: {
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    hide() {
      this.show = false
    },
    changeShow(index) {
      this.eventDetail = this.rows[index];
      this.show = true
    },
    formattedDate(inputDate) {
      const dateObj = new Date(inputDate);
      const day = dateObj.getDate();
      const formattedDay = day < 10 ? `0${day}` : day.toString();
      return formattedDay;
    },
    formattedMonth(inputDate) {
      const dateObj = new Date(inputDate);
      // const month = dateObj.getMonth() + 1; 
      const monthAbbreviation = new Intl.DateTimeFormat('en', { month: 'short' }).format(dateObj);
      return monthAbbreviation;
    },
    loadItems() {
      const baseURI = this.$settings.endPoint + "master-event";

      //var columnFilters = this.serverParams.columnFilters;
      this.isLoading = true;
      return this.$http
        .get(
          baseURI +
          `?limit=10&page=1&sort_field=&sort_type=&source=user`,
          { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.rows = response.data.datas.data;

        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // let rolePermision = [];
      // userDatas.sub.permision_role.forEach(function(permision){
      //   if(permision.permission.controller == 'EventController'){
      //     rolePermision = permision;
      //   }
      // });

      // if(!rolePermision.method_read){
      //   this.$router.push("/authorized-error");
      // }
      // this.permision_role = rolePermision;


    },
    handleChange(code) {
      this.code = code;
    },
    refreshCaptchaCode() {
      this.$refs.captcha.refreshCaptcha();
    },
    makeOffer() {

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loading();
    this.fetchIt();
    this.loadItems();
  },
};
</script>
<style scoped>
@import '../_partials/partial.css';
.article-container{
  margin: 20px;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.article-details {
  max-width: 100%;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.article-title {  
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  color: #295985;
}

.article-content {
  color: #000;
  font-size: 12px;
  line-height: 19px;
}

.article-meta {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.article-dates,
.article-points {  
  color: #4c5e6b;
  margin-top:10px;
  font-size: 12px;
}

.back-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  margin:10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2980b9;
}
</style>