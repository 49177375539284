<template>
  <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">List Campaign</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" v-on:click="$emit('closeModal')"
            aria-label="btn-close"></button>
        </div>

        <div class="modal-body">
          <div class="table-responsive pt-3">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name Campaign</th>
                  <th>Registrasi</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.campaign.name }}</td>
                  <td>{{ item.reg }}</td>
                  <td>{{ item.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    
  },
  name: "ModalListCampaign",
  emits: ["closeModal"],
  props: {
    showModal: Boolean,
    data: {},
    status: String,
  },
  watch: {
    showModal: {

      handler(newVal) {
        this.active = newVal;
        const body = document.querySelector("body");
        this.forms.submit_draft = this.data.submit_draft;
        this.forms.preview_draft = this.data.preview_draft;
        this.forms.upload_konten = this.data.upload_konten;
        this.forms.bukti_tayang = this.data.bukti_tayang;
        this.forms.bukti_insight = this.data.bukti_insight;
        this.product_knowledge = this.data.product_knowledge
        this.showModal
          ? body.classList.add("modal-open")
          : body.classList.remove("modal-open");

      },
      
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      list:[],
      isLoading: false,
      active: this.showModal,
      product_knowledge: '',
      images_product_knowledge: null,
      forms: {},
      errors: [],
      rules: {
        select: [(v) => !!v || "Item is required"]
      }

    };
  },
  methods: {
    loadCampaign() {
      const baseURI = this.$settings.endPoint + "campaign-user/select";
      this.isLoading = true;
      return this.$http
        .get(
          baseURI , { timeout: 10000 }
        )
        .then((response) => {
          this.list = response.data.datas;
        }).catch(error => {
          this.isLoading = false;

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }

        });
    },
    resultError(data) {
      var html = "";
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          html += objectData[y] + "<br>";
        }
      }

      this.loadError(html);
    },

    loadError(pesan) {
      this.$swal({
        backdrop: true,
        title: "Warning",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    loadSuccess(pesan) {
      this.$swal({
        backdrop: true,
        title: "Success",
        html: pesan,
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },

    success2(msg) {

      this.$swal({
        icon: 'info',
        backdrop: true,
        title: "Information",
        html: "<p>* " + msg + "</p>",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      })


    },


    loadingSubmiting() {
      this.$swal({
        icon: 'warning',
        backdrop: true,
        title: "Please wait",
        html: '<h2>CANCELLING ORDER...</h2>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    },


  },
  mounted() {
    this.loadCampaign();
  },
};
</script>

<style scoped>
.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: #fff;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: 0px !important;
}
</style>